import PageWrapper from "../components/PageWrapper";
import {useLocation, useNavigate} from "react-router-dom";
import { AccountSettings } from '@aws-amplify/ui-react';
import {Button} from "@mui/material";

const PasswordChangePage = () => {
    const {state} = useLocation()
    const navigate = useNavigate()
    const redirectsTo = state?.redirectsTo

    const handleSuccess = () => {
        navigate(redirectsTo)
    }

    return <PageWrapper>
        <AccountSettings.ChangePassword onSuccess={handleSuccess}/>
        <Button onClick={() => navigate(redirectsTo)}>Go back</Button>
    </PageWrapper>
}

export default PasswordChangePage