import {CHAIN_SETTING, Postcard, REPLY_BACK_SETTING} from "../../../utils/postcard";
import CartPostcardRecapTemplate from "./CartPostcardRecapTemplate";

type CartPostcardRecapProps = {
    postcard: Postcard,
    onRemovePostcard?: () => void
    price?: number,
}

const CartPostcardRecap = ({ postcard, price, onRemovePostcard }: CartPostcardRecapProps) => {
    return <CartPostcardRecapTemplate
        { ...postcard }
        location={{ location: postcard.location, latitude: postcard.latitude, longitude: postcard.longitude }}
        price={price}
        isDelete={!!onRemovePostcard}
        onRemovePostcard={onRemovePostcard}
        isReplyBack={postcard.settings.replyBackSetting !== REPLY_BACK_SETTING.NONE}
        isChain={postcard.settings.chainSetting !== CHAIN_SETTING.NONE}
    />
}

export default CartPostcardRecap
