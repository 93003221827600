import PageWrapper from "../components/PageWrapper";
import React, {useState} from "react";
import {getBackendEnvironment, invoke} from "../utils/utils";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress, Link,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
// @ts-ignore
import { Helmet } from 'react-helmet'

enum EmailState {
    INITIAL, SENDING, SENT
}

const Contact = () => {
    const [fullName, setFullName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [isLoadingEmail, setIsLoadingEmail] = useState<EmailState>(EmailState.INITIAL)

    const theme = useTheme()

    const handleSubmit = async () => {
        setIsLoadingEmail(EmailState.SENDING)
        try {
            const environment = getBackendEnvironment()
            await invoke(`stampyemailsender-${environment}`, {
                message: `
Name: ${fullName}
Email: ${email}
Message: ${message}
                `,
                subject: '[AUTO] Contact request',
                recipient: 'admin@memento-card.it'
            })
        } catch (e) {
            console.warn('Error while trying to send internal email')
        }
        setIsLoadingEmail(EmailState.SENT)
        setEmail('')
        setFullName('')
        setMessage('')
    }

    return <PageWrapper>
        <Helmet>
            <meta name="title" content="Contact Us - Memento Card | Contattaci - Memento Card" />
            <meta name="description" content="Do you have questions or need
assistance? Contact the Memento Card team. We are here to help with all your
needs and requests regarding your postcards. | Hai domande o bisogno di
assistenza? Contatta il team di Memento Card. Siamo qui per aiutarti con tutte
le tue esigenze e richieste riguardanti le tue cartoline." />
            <meta name="keywords" content="Contact Memento Card, Memento Card
support, customer support, Memento Card questions, contact us, Memento
Card requests, assistenza Memento Card, supporto clienti, domande Memento
Card, contattaci, richieste Memento Card" />
        </Helmet>
        <Box sx={{ display: 'flex', width: 'calc(100% - 48px)', flexDirection: 'column', m: 3, my: 6,  alignItems: 'center' }}>
            <Typography variant={'h4'} sx={{ mb: 1, textAlign: 'center', fontSize: { xs: 30, md: 40 } }}>
                Contact Us
            </Typography>
            <Typography variant={'h6'} sx={{ color: '#666', fontFamily: 'Roboto', textAlign: 'center', fontSize: { xs: 18, md: 24 } }}>
                Any question or remarks? Just drop us a message!
            </Typography>
            <Card sx={{ width: '100%', maxWidth: 800, p: { xs: 2, md: 4 }, mt: 4, borderRadius: 3, boxShadow: 'none', border: '1px solid rgba(14, 14, 44, 0.05)', filter: 'drop-shadow(0px 0px 4.2963px rgba(0, 0, 0, 0.25))' }}>
                <CardContent>
                    <TextField
                        label={'Full Name'}
                        style={{ width: '100%', paddingBottom: 24 }}
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                    />
                    <TextField
                        label={'Your email'}
                        style={{ width: '100%', paddingBottom: 24 }}
                        value={email}
                        type={'email'}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        multiline
                        label={'Write here your message'}
                        sx={{
                            width: '100%',
                            height: 124,
                            '& .MuiInputBase-root': {
                                height: '100% !important',
                                alignItems: 'start',
                                borderRadius: 2
                            }
                        }}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                    />
                </CardContent>
                <CardActions>
                    <Button variant={'contained'} onClick={handleSubmit} disabled={isLoadingEmail === EmailState.SENDING}>
                        {isLoadingEmail === EmailState.SENT ? <DoneIcon sx={{ color: 'white', '& path': { fill: 'white' } }} /> :
                            (isLoadingEmail === EmailState.SENDING ? <CircularProgress sx={{ width: '28px !important' }} />
                                : 'Send a message')
                        }
                    </Button>
                </CardActions>
            </Card>
            <Typography variant={'h6'} sx={{ color: '#666', fontFamily: 'Roboto', mt: 2, textAlign: 'center', fontSize: { xs: 18, md: 24 } }}>
               Or visit our <Link href={'/faq'} sx={{ color: theme.palette.primary.main, textDecoration: 'underline' }}>FAQ</Link> page
            </Typography>
        </Box>
    </PageWrapper>
}

export default Contact