import {Box, Typography} from "@mui/material";
import {useContext} from "react";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import {Font} from "../../../../../utils/postcard";

const MessageBox = ({ message, font, color }: { message: string, font?: Font, color?: string }) => {
    const postcardWidth = useContext(PostcardWidthContext).width

    return  <Box sx={{ pl: '5.64%', width: '47.88%', pr: '5.64%' }}>
        <Typography sx={{
            fontSize: postcardWidth * 0.0292,
            fontFamily: font?.name ?? 'auto',
            color: color ?? 'auto',
            pt: postcardWidth * 0.0282 / 8,
            whiteSpace: 'pre-wrap',
            textAlign: 'left',
            width: '100%',
            overflowY: 'hidden'
        }}>
            {message}
        </Typography>
    </Box>
}

export default MessageBox