import {TextField, TextFieldProps} from "@mui/material";
import React from "react";

const AddressTextField = (props: TextFieldProps & { small?: boolean }) => <TextField sx={{
    '& .MuiInputBase-root > fieldset': {
        border: 'none',
        borderBottom: 'black 1px solid',
        borderRadius: 0,
    },
    '& .MuiInputBase-root > input': {
        p: 0,
        fontFamily: 'Poste !important',
        textTransform: 'uppercase',
    },
    height: props.small ? '30px' : 'auto',
}} { ...props } />

export default AddressTextField