import {STEPS} from "../postcardAppUtils";
import {Box, Button} from "@mui/material";
import React, {useContext} from "react";
import IsGreetingsContext from "../imagesselection/isGreetingsContext";
import {RootState, useAppDispatch, useAppSelector} from "../../../../utils/store";
import useActions from "../utils/useActions";

const NextButton = () => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const stateSelector = (state: RootState) =>
        isGreetings ? state.greetings : state.app

    const {
        step, isLoadingSubmission
    } = useAppSelector(stateSelector)

    const dispatch = useAppDispatch()

    const {
        processNextStepRequest
    } = useActions(isGreetings)

    return <Box sx={{ display: step === STEPS.CART ? 'none' : 'block' }}>
        <Button onClick={() => dispatch(processNextStepRequest())} variant={'contained'} className={'no-focus'} id={STEPS[step]}>
            Next
        </Button>
    </Box>
}

export default NextButton