import {Box, Card, useMediaQuery, useTheme} from "@mui/material";

type MessageSettingsProps<T extends { id: string }> = {
    items: { value: T, component: JSX.Element }[]
    currentItem: T,
    setCurrentItem: ((newState: T) => void)
}

const MessageSettings = <T extends { id: string }, >({ items, currentItem, setCurrentItem }: MessageSettingsProps<T>) => {
    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{ mb: 4, width: belowMedium ? '50%' : '100%' }}>
        <Card sx={{ borderRadius: 3, mt: 2, p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {items.map(item => <Box onClick={() => setCurrentItem(item.value)} sx={{ cursor: 'pointer' }} key={item.value.id}>
                <>
                    {item.component}
                    <Box sx={{ height: '2px', width: '100%', backgroundColor: item.value.id === currentItem.id ? theme.palette.primary.main : 'transparent', mt: 1 }} />
                </>
            </Box>)}
        </Card>
    </Box>
}

export default MessageSettings