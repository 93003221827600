import {createTheme} from "@aws-amplify/ui-react";

const amplifyTheme = createTheme({
    name: 'default',
    tokens: {
        components: {
            tabs: {
                item: {
                    _active: {
                        color: '#fa7e48',
                        borderColor: '#fa7e48'
                    },
                    _hover: {
                        color: '#fa7e48',
                        borderColor: '#fa7e48',
                        backgroundColor: '#ffe7db !important'
                    }
                }
            },
            authenticator: {
                router: {
                    borderColor: 'transparent',
                    boxShadow: 'none'
                }
            },
            button: {
                borderRadius: '12px',
                primary: {
                    backgroundColor: '#FA7E48 !important',
                },
                link: {
                    color: '#fa7e48',
                    _hover: {
                        backgroundColor: '#ffe7db',
                        color: '#fa7e48',
                    }
                },
                _hover: {
                    backgroundColor: '#ffe7db',
                    borderColor: 'auto'
                }
            },
            fieldcontrol: {
                borderRadius: '12px'
            }
        }
    }
})

export default amplifyTheme