import {Amplify} from "aws-amplify";
import {store} from "../../utils/store";
import {defer} from "react-router-dom";
import {getUserOnServerAndSetGlobalState} from "../../utils/utils";

const fetchUser = () => {
    return defer({
        user: getUserOnServerAndSetGlobalState(Amplify, store)
    })
}

export default fetchUser