import {useContext, useState} from "react";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import EditMessageBox from "./EditMessageBox";
import {Box, TextField} from "@mui/material";
import {EditMessageProps} from "./message";

const EditMessageComponentLarge = ({
    message, font, color, setMessage
}: EditMessageProps) => {
    const [editMessage, setEditMessage] = useState<boolean>(false)
    const onClickEditMessageBox = () => setEditMessage(true)

    const postcardWidth = useContext(PostcardWidthContext).width

    return <Box sx={{ pl: '5.64%', width: '47.88%' }}>
        {!editMessage && !message ? <EditMessageBox
            onClick={onClickEditMessageBox}
            isSmall={false}
            postcardWidth={postcardWidth}
        /> : <TextField multiline sx={{
            width: 'calc(100% - 40px)',
            height: '100%',
            '& .MuiInputBase-root': {
                height: '100% !important',
                alignItems: 'start',
                borderRadius: 2
            },
            '& textarea': {
                fontFamily: font?.name ?? 'auto',
                color: color ?? 'auto',
            },
        }}
        value={message}
        onChange={
            e => setMessage(e.target.value)
        } label={'Message'}/>}
    </Box>
}

export default EditMessageComponentLarge