import {Authenticator} from "@aws-amplify/ui-react";
import {useEffect} from "react";
import {Hub} from "aws-amplify/utils";
import {useDispatch} from "react-redux";
import {setUserLoggedIn, setUserLoggedOut} from "./authSlice";

type AuthenticationProps = {
    children: JSX.Element
}

const AuthenticationWrapper = ({ children }: AuthenticationProps) => {
    const dispatch = useDispatch()

    useEffect(() => {
        const cancelSubscription = Hub.listen('auth', ({ payload }) => {
            // Keep auth slice in sync
            switch (payload.event) {
                case 'signedIn':
                    dispatch(setUserLoggedIn(payload.data))
                    break;
                case 'signedOut':
                    dispatch(setUserLoggedOut())
                    break;
                case 'tokenRefresh':
                    break;
                case 'tokenRefresh_failure':
                    break;
                case 'signInWithRedirect':
                    break;
                case 'signInWithRedirect_failure':
                    break;
            }
        })
        return () => cancelSubscription()
    }, []);

    return  <Authenticator.Provider>
        {children}
    </Authenticator.Provider>
}

export default AuthenticationWrapper