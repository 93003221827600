import {InvokeCommand, LambdaClient, LogType} from '@aws-sdk/client-lambda'
import awsmobile from "../aws-exports-copy";
import {fetchAuthSession, getCurrentUser} from "aws-amplify/auth";
import {Amplify, ResourcesConfig} from "aws-amplify";
import awsExports from "../aws-exports-copy";
import {AppStore, store} from "./store";
import {setUserLoggedIn, setUserLoggedOut} from "../components/auth/authSlice";
import {LegacyConfig} from "@aws-amplify/core/dist/esm/singleton/types";
import {clearState} from "../components/app/postcard-app/utils/postcardSlice";
import {Address} from "./postcard";


const getBackendEnvironment = (): string => {
    if (!awsmobile.aws_user_files_s3_bucket) return ''
    const splits = awsmobile.aws_user_files_s3_bucket.split('-')
    return splits[splits.length - 1]
}

const invoke = async (funcName: string, payload: object) => {
    const session = await fetchAuthSession()
    const client = new LambdaClient({
        region: awsmobile.aws_project_region,
        credentials: session.credentials
    })
    const command = new InvokeCommand({
        FunctionName: funcName,
        Payload: JSON.stringify(payload),
        LogType: LogType.Tail,
    })

    const { Payload } = await client.send(command)
    return JSON.parse(Payload?.transformToString('utf-8') as string)
};

const getWindowLocation = (window: Window): string => {
    return window.location.protocol + '//' + window.location.hostname +
        (window.location.hostname === 'localhost' ? (':' + window.location.port) : '')
}

const getCoordsLabel = (latitude: number, longitude: number): string => {
    const latitudePoint = latitude > 0 ? 'N' : 'S', longitudePoint = longitude > 0 ? 'E' : 'W';
    const absLatitude = latitude > 0 ? latitude : latitude * -1, absLongitude = longitude > 0 ? longitude : longitude * -1
    return `${Math.round(absLatitude * 1000) / 1000}° ${latitudePoint}, ${Math.round(absLongitude * 1000) / 1000}° ${longitudePoint}`
}

const isFulfilled = (type: string): boolean => {
    return type.endsWith('/fulfilled')
}

const getUserOnServerAndSetGlobalState = async (
    amplify: { configure: (resourceConfig: ResourcesConfig | LegacyConfig) => void },
    store: AppStore
) => {
    amplify.configure(awsExports)
    try {
        const user = await getCurrentUser()
        store.dispatch(setUserLoggedIn(user))
        return user
    } catch (e) {
        store.dispatch(setUserLoggedOut())
        // store.dispatch(clearState())
        return null
    }
}

const getTotalPrice = (prices: number[]) => {
    let totalPrice = 0
    for (let price of prices)
        totalPrice += price
    return totalPrice
}

const SEPARATOR = '§'

const stringToAddress = (_address: string): Address => {
    const strings = _address.split(SEPARATOR)
    return {
        id: '',
        fullName: strings[0] ?? '',
        streetAddress: strings[1] ?? '',
        city: strings[2] ?? '',
        zipCode: strings[3]
    }
}

const addressToString = (address: Address): string => {
    return (address.fullName ?? '') + SEPARATOR + (address.streetAddress ?? '') + SEPARATOR + (address.city ?? '') + SEPARATOR + (address.zipCode ?? '')
}

const isAddressEmpty = (a: Address | null): boolean => {
    return !a || (a.fullName === '' && a.city === '' && a.streetAddress === '' && a.zipCode === '')
}

export {
    getBackendEnvironment,
    invoke,
    getWindowLocation,
    getCoordsLabel,
    isFulfilled,
    getUserOnServerAndSetGlobalState,
    getTotalPrice,
    stringToAddress,
    addressToString,
    isAddressEmpty
}