import {Box, Button, Card, CardContent, CircularProgress, TextField, Typography, useTheme} from "@mui/material";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../utils/store";
import {selectCart, updatePromoCodeName, validatePromoCode} from "../../cart/cartSlice";
import PriceDisplay from "./PriceDisplay";

type CheckoutProps = {
    totalPrice: number
    quantity: number
    onSubmitOrder: () => void
    bankTransferOption?: boolean
    onSubmitBankTransfer?: () => void
    onAddAnotherPostcard?: () => void
}

const Checkout = ({
    totalPrice,
    quantity,
    onSubmitOrder,
    bankTransferOption,
    onSubmitBankTransfer,
    onAddAnotherPostcard
}: CheckoutProps) => {

    const {
        promotion, isLoadingPromoCodeValidation, promoCodeName, isLoadingCheckout
    } = useAppSelector(selectCart)

    const dispatch = useAppDispatch()

    const theme = useTheme()
    const textFieldDefaults = theme.components?.MuiTextField?.defaultProps?.sx ? theme.components.MuiTextField.defaultProps.sx : {}
    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    return <Box sx={{ width: '100%' }}>
        <Card sx={{ width: '100%', mb: 2 }} className={'cart-postcard-recap'}>
            <CardContent sx={{ p: 3 }}>
                <PriceDisplay totalPrice={totalPrice} promotion={promotion} quantity={quantity} />
                <Box
                    sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3}}>
                    <TextField
                        value={promoCodeName}
                        onChange={e => dispatch(updatePromoCodeName(e.target.value))}
                        placeholder={'Coupon code'}
                        sx={{width: '100%', mr: 2, ...textFieldDefaults}}
                    />
                    <Button onClick={() => dispatch(validatePromoCode())} variant={'contained'} sx={{flexShrink: 0, ...buttonDefaults}}>
                        {isLoadingPromoCodeValidation ? <CircularProgress sx={{ width: '28px !important' }} /> : 'Apply Coupon'}
                    </Button>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'stretch', mb: 2 }}>
                    <Button onClick={onSubmitOrder} variant={'contained'} sx={{ width: '100%', ...buttonDefaults }}>
                        {isLoadingCheckout ? <CircularProgress sx={{ width: '28px !important' }} /> :
                            `Checkout | ${Math.floor((totalPrice * promotion) * 100) / 100}€` }
                    </Button>
                </Box>
                {bankTransferOption && <Box sx={{width: '100%', display: 'flex', justifyContent: 'stretch', mb: 2}}>
                    <Button onClick={onSubmitBankTransfer} variant={'contained'} color={"secondary"} sx={{width: '100%', ...buttonDefaults}}>
                        Pay later with bank transfer
                    </Button>
                </Box>}
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2}}>
                    <Button onClick={onAddAnotherPostcard} variant={"contained"} color={'secondary'}
                            sx={{width: '100%', ...buttonDefaults}}>
                        <span>Add another postcard</span>
                    </Button>
                </Box>
            </CardContent>
        </Card>
    </Box>
}

export default Checkout