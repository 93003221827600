import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { AuthUser } from "aws-amplify/auth";
import {RootState} from "../../utils/store";

export interface AuthState {
    isLoggedIn: boolean,
    user: AuthUser | null
}

const initialState: AuthState = {
    isLoggedIn: false,
    user: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserLoggedIn: (state, action: PayloadAction<AuthUser>) => {
            state.isLoggedIn = true
            state.user = action.payload
        },
        setUserLoggedOut: (state) => {
            state.isLoggedIn = false
            state.user = null
        }
    }
})

export const {
    setUserLoggedIn,
    setUserLoggedOut
} = authSlice.actions
export default authSlice.reducer
export const selectAuth = (state: RootState) => state.auth