import {Box, LinearProgress, Typography, useMediaQuery, useTheme} from "@mui/material";
import React from "react";

export type MissionProps = {
    name: string,
    currentNumber: number,
    totalNumber: number
}

export const Mission = (props: MissionProps) =>{
    const {name, currentNumber, totalNumber} = props;

    const theme = useTheme()
    const belowMd = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <Box sx={{width: "100%", borderRadius: "10px", marginBottom: "10px"}}>
            <Box sx={{display: "flex", flexDirection: "column", width: "100%", backgroundColor: belowMd ? theme.palette.secondary.main : 'white',
                borderRadius: "5px", padding: "10px"}}>
                <Box sx = {{display:"flex", justifyContent: "space-between", marginBottom: "10px"}}>
                    <Typography sx={{color:"#000000", fontSize: "16px", fontWeight: 500}}>{name}</Typography>
                    <Typography sx={{color:"#666666", fontSize: "12px", fontWeight: 700}}>{currentNumber}/{totalNumber}</Typography>
                </Box>

                <LinearProgress
                    variant="determinate"
                    value={(currentNumber/totalNumber) * 100}
                    sx = {{height: "10px", borderRadius: "10px"}}
                />

            </Box>
        </Box>
    );
}

