import {useContext, useState} from "react";
import {EditLocationProps} from "./location";
import {NO_COORDINATE} from "../../../../app/common/postcardAppUtils";
import EditLocationBox from "./EditLocationBox";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import LocationBox from "./LocationBox";
import LocationAutocomplete from "./LocationAutocomplete";

const EditLocationComponentSmall = ({
    location, setLocation
}: EditLocationProps) => {
    const [editLocation, setEditLocation] = useState<boolean>(false)
    const onClickEditLocationBox = () => setEditLocation(true)

    const postcardWidth = useContext(PostcardWidthContext).width

    return !editLocation ? (
        (!location.location || location.longitude === NO_COORDINATE || location.longitude === NO_COORDINATE) ?
            <EditLocationBox
                onClick={onClickEditLocationBox}
                isSmall={true}
                postcardWidth={postcardWidth}
            /> : <LocationBox
                location={location}
                isClickable={true}
                onClick={onClickEditLocationBox}
                postcardWidth={postcardWidth}
            />
    ) : <LocationAutocomplete
        location={location}
        setLocation={setLocation}
        setEditLocation={setEditLocation}
        isSmall={true}
    />
}

export default EditLocationComponentSmall