import {CommonPostcard, Font, Image} from "../../../../utils/postcard";
import {
    getAlert,
    getImagesLengthForLayout,
    Location,
    STEPS
} from "../postcardAppUtils";
import {AlertMessage} from "../imagesselection/alertsContext";
import {PayloadAction} from "@reduxjs/toolkit";
import {LAYOUT} from "../../../../utils/layout";

export interface CommonState<T extends CommonPostcard>{
    postcard: T,
    step: STEPS
    alerts: AlertMessage[]
    errorDialog: {
        open: boolean
        message: string
    }
    isNextDisabled: boolean
    isLoadingSubmission: boolean
}

const commonUpdateImage = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<Image>) => {
    // Overwrite by looking at action.payload.index
    const image = action.payload

    // Check if valid index for current layout
    if (image.index <= getImagesLengthForLayout(state.postcard.layout)) {
        const indexToOverwrite = state.postcard.images.findIndex(_image => _image.index === image.index)

        if (indexToOverwrite !== -1)
            state.postcard.images[indexToOverwrite] = image
        else
            state.postcard.images.push(image)
    } else {
        state.alerts.push(getAlert('Unable to save image', 'error'))
    }
}

const commonUpdateLayout = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<LAYOUT>) => {
    state.postcard.layout = action.payload
}

const commonUpdateMessage = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<string>) => {
    state.postcard.message = action.payload
}

const commonUpdateFont = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<Font | undefined>) => {
    state.postcard.font = action.payload
}

const commonSetFontIfNotPresent = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<Font>) => {
    if (!state.postcard.font)
        state.postcard.font = action.payload
}

const commonUpdateLocation = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<Location | undefined>) => {
    if (action.payload) {
        state.postcard.location = action.payload.location
        state.postcard.latitude = action.payload.latitude
        state.postcard.longitude = action.payload.longitude
    }
}

const commonCloseErrorDialog = <T extends CommonPostcard>(state: CommonState<T>) => {
    state.errorDialog.open = false
    state.errorDialog.message = ''
}

const commonAddErrorAlert = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<string>) => {
    if (action.payload !== '')
        state.alerts.push(getAlert(action.payload, 'error'))
}

const commonAddInfoAlert = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<string>) => {
    if (action.payload !== '')
        state.alerts.push(getAlert(action.payload, 'info'))
}

const commonRemoveAlert = <T extends CommonPostcard>(state: CommonState<T>, action: PayloadAction<string>) => {
    const id = action.payload
    state.alerts = [...state.alerts.filter(alert => alert.id !== id)]
}

export {
    commonUpdateImage,
    commonUpdateLayout,
    commonUpdateMessage,
    commonUpdateLocation,
    commonUpdateFont,
    commonSetFontIfNotPresent,
    commonCloseErrorDialog,
    commonAddErrorAlert,
    commonAddInfoAlert,
    commonRemoveAlert
}