import PageWrapper from "../components/PageWrapper";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getUserReceivedPostcards, getUserSentPostcards} from "../api/api";
import useUserAttributes from "../utils/useUserAttributes";
import useIsAmbassador from "../utils/useIsAmbassador";
import {Box, Tab, Tabs, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Mission} from "../components/profile/Mission";
import {ProfileDetails} from "../components/profile/ProfileDetails";
import AmbassadorDashboard from "../components/profile/AmbassadorDashboard";
import MyPostcards from "../components/profile/MyPostcards";
import AddressWidget from "../components/profile/AddressWidget";
import {CHAIN_SETTING, Postcard, REPLY_BACK_SETTING} from "../utils/postcard";
import {useAppSelector} from "../utils/store";
import {selectAuth} from "../components/auth/authSlice";

const ProfilePage = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [sentPostcards, setSentPostcards] = useState<Postcard[]>([])
    const [receivedPostcards, setReceivedPostcards] = useState<Postcard[]>([])
    const [isLoadingSentPostcards, setIsLoadingSentPostcards] = useState<boolean>(true)
    const [isLoadingReceivedPostcards, setIsLoadingReceivedPostcards] = useState<boolean>(true)
    const [errorMessageSent, setErrorMessageSent] = useState<string>('')
    const [errorMessageReceived, setErrorMessageReceived] = useState<string>('')

    const theme = useTheme()
    const belowMd = useMediaQuery(theme.breakpoints.down('md'));

    const [currentTab, setCurrentTab] = useState(0)

    const {
        isLoggedIn, user
    } = useAppSelector(selectAuth)

    const { name } = useUserAttributes(user?.userId)
    const {isAmbassador} = useIsAmbassador(user?.userId)

    const goToPasswordChange = () => navigate('/password-change', { state: { redirectsTo: location.pathname } })

    const handleDeleteUserSuccess = () => {
        alert('user has been successfully deleted')
    }

    // TODO convert to router loader
    const getSentPostcards = async (userId: string): Promise<Postcard[]> => {
        return await getUserSentPostcards(userId)
    }

    const getReceivedPostcards = async (userId: string): Promise<Postcard[]> => {
        return await getUserReceivedPostcards(userId)
    }

    useEffect(() => {
        if (user?.userId) {
            const userId = user.userId
            getSentPostcards(userId)
                .then(postcards => {
                    setIsLoadingSentPostcards(false)
                    setSentPostcards(postcards)
                })
            getReceivedPostcards(userId)
                .then(postcards => {
                    setIsLoadingReceivedPostcards(false)
                    setReceivedPostcards(postcards)
                })
        }
    }, [user])

    const getSentPostcardsNumber = (): number => sentPostcards.length
    const getSentReplyBacksNumber = (): number => sentPostcards.filter(postcard => {
        return postcard.settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID
    }).length
    const getSentChainNumber = (): number => sentPostcards.filter(postcard => {
        return postcard.settings.chainSetting === CHAIN_SETTING.CHAIN || postcard.settings.chainSetting === CHAIN_SETTING.CHAIN_PAID
    }).length
    const getReceivedPostcardsNumber = (): number => receivedPostcards.length

    return(
        <PageWrapper>
        {isLoggedIn ?
            (!belowMd ? <Box sx={{display: "flex", justifyContent:"center", width: "100%", pt: 4, px: 1}}>

                <Box sx={{display: "flex", flexDirection: "column", flex: "1", marginRight: 4}}>

                    <ProfileDetails name={name} profileImgPath={'images/landingpage/reviews/image_1.png'} chainsNumber={getSentChainNumber()}
                                    postcardsNumber={getSentPostcardsNumber()} replyBacksNumber={getSentReplyBacksNumber()} isAmbassador={isAmbassador ?? false}/>

                    <AddressWidget />

                    <Typography sx={{color: "#666666", fontSize: { xs: 32, md: 40 }, fontWeight: 500, pt: 3}}>Missions</Typography>

                    {[
                        {
                            name: "Send your first postcard",
                            currentNumber: getSentPostcardsNumber() >= 1 ? 1 : 0,
                            totalNumber: 1
                        }, {
                            name: "Receive your first postcard",
                            currentNumber: getReceivedPostcardsNumber() >= 1 ? 1 : 0,
                            totalNumber: 1
                        }, {
                            name: "Start a friends-chain",
                            currentNumber: getSentChainNumber() >= 1 ? 1 : 0,
                            totalNumber: 1
                        }, {
                            name: "Send 10 postcards",
                            currentNumber: getSentPostcardsNumber() >= 10 ? 10 : getSentPostcardsNumber(),
                            totalNumber: 10
                        }, {
                            name: "Receive 10 postcards",
                            currentNumber: getReceivedPostcardsNumber() >= 10 ? 10 : getReceivedPostcardsNumber(),
                            totalNumber: 10
                        },
                    ].map((mission, key) =>
                        <Mission key={key} name={mission.name} currentNumber={mission.currentNumber} totalNumber={mission.totalNumber} />
                    )}
                </Box>

                <Box sx={{display: "flex", flexDirection: "column", flex: "2"}}>

                    {isAmbassador && <AmbassadorDashboard/>}

                    <MyPostcards
                        sentPostcards={sentPostcards}
                        receivedPostcards={receivedPostcards}
                        isLoadingSentPostcards={isLoadingSentPostcards}
                        isLoadingReceivedPostcards={isLoadingReceivedPostcards}
                        errorMessageSent={errorMessageSent}
                        errorMessageReceived={errorMessageReceived}
                    />
                </Box>

            </Box> : <Box sx={{ display: "flex", justifyContent: "center", width: "100%", pt: 4, flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant={'h4'}>Profile</Typography>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
                            <Tab label={'Personal info'} />
                            <Tab label={'My postcards'} />
                        </Tabs>
                    </Box>
                    <Box hidden={currentTab !== 0} sx={{ backgroundColor: 'white', pb: 4 }}>
                        {currentTab === 0 && <>
                            <ProfileDetails name={name} profileImgPath={'images/landingpage/reviews/image_1.png'}
                                            chainsNumber={getSentChainNumber()}
                                            postcardsNumber={getSentPostcardsNumber()}
                                            replyBacksNumber={getSentReplyBacksNumber()}
                                            isAmbassador={isAmbassador ?? false}/>

                            {isAmbassador && <Box sx={{ px: 1 }}>
                                <AmbassadorDashboard/>
                            </Box>}

                            <AddressWidget/>

                            <Box sx={{ px: 2 }}>
                                <Typography
                                    sx={{color: "#666666", fontSize: { xs: 32, md: 40 }, fontWeight: 500, pt: 3}}>Missions</Typography>

                                {[
                                    {
                                        name: "Send your first postcard",
                                        currentNumber: getSentPostcardsNumber() >= 1 ? 1 : 0,
                                        totalNumber: 1
                                    }, {
                                        name: "Receive your first postcard",
                                        currentNumber: getReceivedPostcardsNumber() >= 1 ? 1 : 0,
                                        totalNumber: 1
                                    }, {
                                        name: "Start a friends-chain",
                                        currentNumber: getSentChainNumber() >= 1 ? 1 : 0,
                                        totalNumber: 1
                                    }, {
                                        name: "Send 10 postcards",
                                        currentNumber: getSentPostcardsNumber() >= 10 ? 10 : getSentPostcardsNumber(),
                                        totalNumber: 10
                                    }, {
                                        name: "Receive 10 postcards",
                                        currentNumber: getReceivedPostcardsNumber() >= 10 ? 10 : getReceivedPostcardsNumber(),
                                        totalNumber: 10
                                    },
                                ].map((mission, key) =>
                                    <Mission key={key} name={mission.name} currentNumber={mission.currentNumber}
                                             totalNumber={mission.totalNumber}/>
                                )}
                            </Box>
                        </>}
                    </Box>
                    <Box hidden={currentTab !== 1} sx={{ backgroundColor: 'white', pb: 4 }}>
                        {currentTab === 1 && <>
                            <MyPostcards
                                sentPostcards={sentPostcards}
                                receivedPostcards={receivedPostcards}
                                isLoadingSentPostcards={isLoadingSentPostcards}
                                isLoadingReceivedPostcards={isLoadingReceivedPostcards}
                                errorMessageSent={errorMessageSent}
                                errorMessageReceived={errorMessageReceived}
                            />
                        </>}
                    </Box>
                </Box>
            </Box>)
            : <Navigate to={'/auth'} state={{ redirectsTo: location.pathname }} />}
    </PageWrapper>
    )
}

export default ProfilePage