import { useState, useEffect } from 'react'
import {getUserData} from "../api/api";
import { updateUserAttributes } from "aws-amplify/auth";
import {Address} from "./postcard";

const SEPARATOR = '§'

const stringToAddress = (_address: string): Address => {
    const strings = _address.split(SEPARATOR)
    return {
        id: '',
        fullName: strings[0] ?? '',
        streetAddress: strings[1] ?? '',
        city: strings[2] ?? '',
        zipCode: strings[3]
    }
}

const addressToString = (address: Address): string => {
    return (address.fullName ?? '') + SEPARATOR + (address.streetAddress ?? '') + SEPARATOR + (address.city ?? '') + SEPARATOR + (address.zipCode ?? '')
}

const useUserAttributes = (userId: string | undefined) => {
    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string | undefined>('')
    const [_address, _setAddress] = useState<string>('')

    const fetchUserData = async () => {
        const result = await getUserData()
        if (result) {
            setName(result.name)
            setEmail(result.email)
            _setAddress(result.address)
        } else {
            setEmail(undefined)
        }
        return true
    }

    const updateAddress = async (__address: Address) => {
        try {
            await updateUserAttributes({ userAttributes: { 'address': addressToString(__address) }})
        } catch (e) {
            console.log('Error while updating user address: ', e)
        }
    }

    useEffect(() => {
        if (userId)
            fetchUserData()
                .then(ignored => { })
    }, [userId])

    const address = _address ? stringToAddress(_address) : null
    const setAddress =  (newAddress: Address) => {
        _setAddress(addressToString(newAddress))
    }

    return { name, email, address, setAddress, updateAddress }
}

export default useUserAttributes