import './main.css'
import {useEffect} from "react";

const CookiePolicy = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://consent.cookiebot.com/a18af163-48af-46ae-b57f-52177979da15/cd.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return <></>
}

export default CookiePolicy