import {Box, Typography} from "@mui/material";
import React from "react";

const PriceDisplay = ({ quantity, totalPrice, promotion }: { quantity: number, totalPrice: number, promotion: number }) => {
    return <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant={'body1'}>{quantity} postcard{quantity !== 1 && 's'} {promotion !== 1.0 && `(${(Math.floor((1 - promotion + 0.00000000000001) * 100)).toString()}% OFF)`}</Typography>
            <Typography variant={'body1'} sx={{ color: 'black' }}>{ promotion === 1.0 ?
                Math.floor(totalPrice * 100) / 100 :
                <><del>&nbsp;{Math.floor(totalPrice * 100) / 100}&nbsp;</del>&nbsp; {Math.floor((totalPrice * promotion) * 100) / 100}</>
            }€</Typography>
        </Box>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', mb: 3}}>
            <Typography variant={'body1'}>Total</Typography>
            <Typography variant={'body1'} sx={{ color: 'black' }}>{Math.floor((totalPrice * promotion) * 100) / 100}€</Typography>
        </Box>
    </>
}

export default PriceDisplay