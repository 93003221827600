import PageWrapper from "../components/PageWrapper";
import {Box, Button, Card, CardContent, IconButton, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import {EffectCoverflow, Pagination} from 'swiper/modules';
import React from "react";

const Greetings = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const belowMd = useMediaQuery(theme.breakpoints.down('sm'));

    const StepCard = ({ number, text }: { number: string, text: string }) => <Box sx={{
        width: { xs: 'calc(100% - 32px)', sm: 'calc(50% - 32px)', maxWidth: 280 },
        m: 2,
    }}>
        <Card style={{ height: '100%', backgroundColor: theme.palette.secondary.main }}>
            <CardContent>
                <Box sx={{ pb: 2 }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: 40, fontFamily: 'Roboto' }}>{number}</Typography>
                </Box>
                <Typography>{text}</Typography>
            </CardContent>
        </Card>
    </Box>

    return <PageWrapper>
        <Box sx={{ display: 'flex', width: 'calc(100% - 48px)', flexDirection: 'column', mb: 4, m: 3, alignItems: 'center', pt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', maxWidth: 1000, px: { xs: 0, md: 2 }, mb: 3 }}>
                <Typography variant={'h1'} sx={{ fontSize: { xs: 40, md: 64 }, textAlign: 'center', m: { xs: 0, md: 3 }, pb: 3, lineHeight: { xs: '46px', md: '75px' } }}>
                    Memento <span className={ 'header-manual' } style={{ fontSize: belowMd ? 48 : 64 }}>Greetings</span>
                </Typography>
                {/* @ts-ignore */}
                <Typography variant={'subtitle'} sx={{ color: '#666', textAlign: 'center', mb: 3, fontSize: { xs: 16, md: 24 }, px: 3 }}>
                    Increase your <span style={{ fontWeight: 'bold' }}>brand awareness</span> and impress the people who matter!
                </Typography>
                {/* @ts-ignore */}
                <Typography variant={'subtitle'} sx={{ color: '#666', textAlign: 'center', fontSize: { xs: 16, md: 24 }, mb: 3 }}>
                    Send <span style={{ fontWeight: 'bold' }}>100% personalized postcards</span> in just a few clicks. The perfect solution for:
                </Typography>
                {/* @ts-ignore */}
                <Typography variant={'subtitle'}
                            sx={{color: '#666', textAlign: 'center', fontSize: {xs: 16, md: 24}, mb: 3}}>
                    <span style={{fontWeight: 'bold'}}>Travel agencies</span><br/>
                    <span style={{fontWeight: 'bold'}}>Associations hosting events</span><br/>
                    <span style={{fontWeight: 'bold'}}>Event and wedding planners</span><br/>
                    <span style={{fontWeight: 'bold'}}>Companies organizing retreats</span><br/>
                </Typography>
                <Button variant={'contained'} onClick={() => navigate('/greetings-app')}>
                    Send your Postcards
                </Button>
            </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', mb: 4, alignItems: 'center', py: 3, px: 0, backgroundColor: theme.palette.secondary.main }}>
            <Typography variant={'h4'} sx={{ fontSize: { xs: 30, md: 40 }, textAlign: 'center', px: 3 }}>
                Our postcard, your <span style={{ color: theme.palette.primary.main }}>Brand</span>
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', py: 4 }}>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    slidesPerView={belowMd ? 1 : 2}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 300
                    }}
                    modules={[EffectCoverflow, Pagination]}
                    style={{ paddingBottom: 32 }}
                    pagination={true}
                >
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/greetings/postcards/image_2-min.png"} alt={"Postcard with LTF logo"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/greetings/postcards/image_1.png"} alt={"Postcard with THe surf point logo"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/greetings/postcards/image_4-min.png"} alt={"Postcard with SVST logo"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/greetings/postcards/image_3.jpg"} alt={"Postcard with LTF logo"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/greetings/postcards/image_6-min.png"} alt={"Postcard with THe surf point logo"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/greetings/postcards/image_5.png"} alt={"Postcard with SVST logo"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                </Swiper>
            </Box>
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: 4, alignItems: 'center', px: 3, pb: 6, pt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant={'h4'} sx={{ fontSize: { xs: 30, md: 40 } }}>
                    How does it <span style={{ color: theme.palette.primary.main }}>work?</span>
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', pt: 2, alignItems: { xs: 'center', md: 'stretch' } }}>
                <StepCard number={'01'} text={'Upload your company logo'} />
                <StepCard number={'02'} text={'Upload a wonderful image'} />
                <StepCard number={'03'} text={'Write a thoughtful message'} />
                <StepCard number={'04'} text={'Upload a list of addresses with our excel template'} />
                <StepCard number={'05'} text={'Ship a real postcard to all your contacts in one shot'} />
            </Box>
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: 4, backgroundColor: theme.palette.secondary.main, alignItems: 'center', px: 3, pb: 6, pt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant={'h4'} sx={{ fontSize: { xs: 30, md: 40 }, textAlign: 'center' }}>
                    Upload easily all your <span style={{ color: theme.palette.primary.main }}>contacts</span>
                </Typography>
                <Typography variant={'body1'} sx={{ color: '#666', textAlign: 'center', fontSize: { xs: 16, md: 24 }, maxWidth: 800, pt: 3, zIndex: 1001 }}>
                    Get all the addresses by using your internal <span style={{ fontWeight: 'bold' }}>CRM</span> or just create a simple <span style={{ fontWeight: 'bold' }}>Google Form</span> (like <Link href={'https://forms.gle/EY4iqMdG3F4VcXiT6'} target={'_blank'} sx={{ color: '#666', fontWeight: 'normal', textDecoration: 'underline' }}>this</Link>).
                    Then you will just need to upload your excel!
                </Typography>
                <Box sx={{ pt: 4, maxWidth: 600 }}>
                    <img src={'images/greetings/template/image1.png'} alt={"Memento Greetings excel template"} style={{ width: '100%', height: '100%', aspectRatio: 'auto' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, width: '100%', flexGrow: 0 }}>
                        <Typography variant={'body1'} sx={{ color: '#666', textAlign: 'center', fontSize: { xs: 16, md: 24 } }}>
                            Download our blank excel template:
                        </Typography>
                        <IconButton component={'a'} download href={'greetingstemplate/Memento_Greetings.xlsx'}>
                            <img alt={'Download'} src={'icons/profile/save.svg'} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Box className={'howdoesitwork-bg'} />
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: 4, alignItems: 'center', px: 3, pb: 6, pt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant={'h4'} sx={{ fontSize: { xs: 30, md: 40 } }}>
                    Our <span style={{ color: theme.palette.primary.main }}>Pricing</span>
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', pt: 2, alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ m: 1, border: '2px solid ' + theme.palette.primary.main , p: 2, borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* @ts-ignore */}
                    <Typography variant={'label1'}>
                        1 &lt; POSTCARDS &lt; 50
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: 24, fontFamily: 'Roboto', py: 2 }}>2.99€</Typography>
                    {/* @ts-ignore */}
                    <Typography variant={'label1'}>
                        PER POSTCARD *
                    </Typography>
                </Box>
                <Box sx={{ m: 1, border: '2px solid ' + theme.palette.primary.main , p: 2, borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* @ts-ignore */}
                    <Typography variant={'label1'}>
                        51 &lt; POSTCARDS &lt; 500
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: 24, fontFamily: 'Roboto', py: 2 }}>2.84€</Typography>
                    {/* @ts-ignore */}
                    <Typography variant={'label1'}>
                        PER POSTCARD *
                    </Typography>
                </Box>
                <Box sx={{ m: 1, border: '2px solid ' + theme.palette.primary.main , p: 2, borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* @ts-ignore */}
                    <Typography variant={'label1'}>
                        &gt; 501 POSTCARDS
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: 24, fontFamily: 'Roboto', py: 2 }}>ENTERPRISE</Typography>
                    {/* @ts-ignore */}
                    <Typography variant={'label1'}>
                        <Link href={'/contact'} sx={{ color: '#666', fontWeight: 'normal', textDecoration: 'underline' }}>CONTACT US</Link>
                    </Typography>
                </Box>
            </Box>
            {/* @ts-ignore */}
            <Typography variant={'label1'} sx={{ pt: 3 }}>
                *Prices valid to shipping in Italy only. Worldwide shipping also available. VAT excluded
            </Typography>
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: { xs: 'calc(32px - 400px)', sm: 'calc(32px - 300px)' }, backgroundColor: theme.palette.secondary.main, alignItems: 'center', px: 3, py: 6 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 10 }}>
                <Typography variant={'h4'} sx={{ fontSize: { xs: 30, md: 40 }, textAlign: 'center' }}>
                    Gift your customers with an indelible <span style={{ color: theme.palette.primary.main }}>Memory</span>
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box sx={{ width: 160, transform: { xs: 'rotate(45deg)', md: 'none' } }}>
                        <img src={'images/greetings/arrow.png'} alt={'arrow'} style={{ height: 160 }} />
                    </Box>
                    <Box sx={{ alignSelf: 'end', pb: 4 }}>
                        <Button variant={'contained'} onClick={() => navigate('/greetings-app')}>
                            Send your Postcards
                        </Button>
                    </Box>
                    <Box sx={{ width: 160 }} />
                </Box>
            </Box>
            <Box className={'howdoesitwork-bg'} />
            <Box  sx={{ width: '100%', height: { xs: 400, sm: 300}, zIndex: -10 }} />
        </Box>
    </PageWrapper>
}

export default Greetings