import {useContext, useState} from "react";
import {EditLocationProps} from "./location";
import EditLocationBox from "./EditLocationBox";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import LocationAutocomplete from "./LocationAutocomplete";

const EditLocationComponentLarge = ({
                                        location, setLocation,
                                    }: EditLocationProps) => {
    const [editLocation, setEditLocation] = useState<boolean>(false)
    const onClickEditLocationBox = () => setEditLocation(true)

    const postcardWidth = useContext(PostcardWidthContext).width

    return !editLocation && !location ? <EditLocationBox
        onClick={onClickEditLocationBox}
        isSmall={false}
        postcardWidth={postcardWidth}
    /> : <LocationAutocomplete
        location={location}
        setLocation={setLocation}
        setEditLocation={setEditLocation}
        isSmall={false}
    />
}

export default EditLocationComponentLarge