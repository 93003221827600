import React, {useState} from "react";
import AuthenticatorWrapper from "../../common/AuthenticatorWrapper";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Button,
    Card, CardContent, CircularProgress,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import GreetingsCartPostcardRecap from "../../../postcardrecap/cartpostcardrecap/GreetingsCartPostcardRecap";
import {getPrice, STEPS, withLocation} from "../../common/postcardAppUtils";
import {PostcardAppInitialState} from "../../postcard-app/utils/PostcardAppInitialState";
import {RootState, useAppDispatch, useAppSelector} from "../../../../utils/store";
import {isFulfilled} from "../../../../utils/utils";
import {useNavigate} from "react-router-dom";
import {
    clearState, processGoToStepRequest,
    selectGreetings,
    submitOrderWithBankTransfer,
    submitOrderWithPayment
} from "../utils/greetingsSlice";
import {IDObject} from "../../../../api/apiUtils";
import PriceDisplay from "../../common/PriceDisplay";

type CartStepProps = { }

const InnerCartStep = () => {
    const navigator = useNavigate()
    const selectStatePostcard = (state: RootState) => state.greetings.postcard

    const {
        id,
        images, layout,
        message, font, color, location,
        addresses
    } = withLocation(useAppSelector(selectStatePostcard))

    const {
        isLoadingSubmission, promotion
    } = useAppSelector(selectGreetings)

    const dispatch = useAppDispatch()

    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)

    const basePrice = getPrice(PostcardAppInitialState.DEFAULT)
    const totalPrice = basePrice * addresses.length

    const postcardsCommonData = {
        id, images, message, font, color, layout, location, price: basePrice
    }

    const lastAddresses = addresses.slice(1)
    const firstAddress = addresses[0]

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))
    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    return <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 0.5, mb: 2 }}>
            <Typography variant={'h6'}>Postcard recap</Typography>
            <Typography variant={'body1'}>You have {addresses.length} item{addresses.length === 1 ? '' : 's'} in your cart</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'column' : 'row', alignItems: 'start', width: '100%' }}>
            <Box sx={{ width: belowMedium ? '100%' : 800, mr: belowMedium ? 0 : 4, flexShrink: 0 }}>
                {lastAddresses.length > 0 && <Accordion
                    sx={{boxShadow: 'none', backgroundColor: 'transparent'}}
                    onChange={(e, expanded) => setIsAccordionOpen(expanded)}
                >
                    <AccordionSummary
                        expandIcon={<img alt={'Expand'} src={isAccordionOpen ? 'icons/profile/open_accordion_arrow.svg' : 'icons/profile/accordion_arrow.svg'} draggable={false}/>}
                        sx={{
                            backgroundColor: 'white',
                            py: 1,
                            px: 1.5,
                            mb: 2,
                            borderRadius: 29,
                            '& .MuiAccordionSummary-content': {
                                margin: '12px 0 !important'
                            },
                            boxShadow: '0 0 3.5px rgba(0, 0, 0, 0.25)'
                        }}>
                        <Typography
                            sx={{color: isAccordionOpen ? theme.palette.primary.main : "#666666", fontSize: "24px", fontWeight: 500, px: 2, textAlign: 'left'}}>See the other postcards (x{addresses.length - 1})</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        {lastAddresses.map(address => <GreetingsCartPostcardRecap
                            {...postcardsCommonData}
                            address={address}
                            key={address.id}
                        />)}
                    </AccordionDetails>
                </Accordion>}
                <GreetingsCartPostcardRecap
                    {...postcardsCommonData}
                    address={firstAddress}
                />
            </Box>

            <Box sx={{ width: '100%' }}>
                <Card sx={{ width: '100%', mb: 2 }} className={'cart-postcard-recap'}>
                    <CardContent sx={{ p: 3 }}>
                        <PriceDisplay totalPrice={totalPrice} promotion={promotion} quantity={addresses.length} />
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'stretch', mb: 2 }}>
                            <Button
                                onClick={() => dispatch(submitOrderWithPayment())}
                                variant={'contained'}
                                sx={{ width: '100%', ...buttonDefaults }}
                            >
                                Checkout | {Math.floor((totalPrice * promotion) * 100) / 100}€
                            </Button>
                        </Box>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'stretch', mb: 2}}>
                            <Button onClick={() => {
                                dispatch(submitOrderWithBankTransfer())
                                    .then(result => {
                                        if (isFulfilled(result.type))
                                            navigator('/greetings-bk-success', {
                                                state: {
                                                    id: (result.payload as IDObject).id,
                                                    price: getPrice(PostcardAppInitialState.DEFAULT) * promotion,
                                                    quantity: addresses.length
                                                }
                                            })
                                    })
                            }} variant={'contained'} color={"secondary"} sx={{width: '100%', ...buttonDefaults}}>
                                Pay later with bank transfer
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    </Box>
}

const CartStep = (props: CartStepProps) => {
    return <AuthenticatorWrapper>
        <InnerCartStep {...props} />
    </AuthenticatorWrapper>
}

export default CartStep