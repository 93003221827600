import {Address, Chain} from "../../../../utils/postcard";

export enum PostcardAppInitialState {
    DEFAULT, REPLY_FREE, REPLY, CHAIN_CONTINUATION_FREE, CHAIN_CONTINUATION_TO_BE_PAID
}

export type DefaultPostcardBody = {}
export type ReplyPostcardBody = {
    repliesToPostcardId: string,
    settingsToUpdate: string
}
export type ReplyFreePostcardBody = {
    repliesToPostcardId: string,
    addressToPrefill: Address,
    settingsToUpdate: string
}
export type ChainPostcardBody = {
    repliesToPostcardId: string,
    settingsToUpdate: string,
    chain: Chain
}

export type PostcardAppState = {
    initialState: PostcardAppInitialState.DEFAULT,
    body: DefaultPostcardBody
} | {
    initialState: PostcardAppInitialState.REPLY,
    body: ReplyPostcardBody
} | {
    initialState: PostcardAppInitialState.REPLY_FREE,
    body: ReplyFreePostcardBody
} | {
    initialState: PostcardAppInitialState.CHAIN_CONTINUATION_FREE | PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID,
    body: ChainPostcardBody
}
