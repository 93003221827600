import PageWrapper from "../../components/PageWrapper";
import './main.css'
// @ts-ignore
import { Helmet } from 'react-helmet'

const TermsAndConditions = () => {
    return <PageWrapper>
        <Helmet>
            <meta name="title" content="Terms and Conditions - Memento Card | Termini
e Condizioni - Memento Card" />
            <meta name="description" content="Review the Terms and Conditions of
Memento Card to understand the rules and policies related to our service. Read
carefully to understand your rights and responsibilities. | Consulta i Termini e
Condizioni di Memento Card per conoscere le regole e le politiche relative
all'uso del nostro servizio. Leggi attentamente per comprendere i tuoi diritti e
le tue responsabilità." />
            <meta name="keywords" content="Memento Card Terms and Conditions,
service policies, Memento Card usage, Memento Card rules, rights and
responsibilities, Termini e condizioni Memento Card, politiche di servizio, uso
Memento Card, regole Memento Card, diritti e responsabilità" />
        </Helmet>
        <div className="center">
        <h1>
            Terms and Conditions
        </h1>
        <br/>
    </div>
    <div>
        <p>
            This document contains the general terms and conditions on the basis of which the use
            of the web site
            www.memento-card.com that provides
            a webapp that allows to generate and ship a real postcard is offered by
            the Owner
        </p>
    </div>
    <ol>
        <li>
            <h4>Definitions</h4>
            <p>
                To allow a complete understanding and acceptance of these terms and conditions, the following terms, in
                the singular and in the plural, shall have the meaning indicated below:
                <ul>
                    <li>
                        <strong>Owner:</strong>
                        <span>
                <strong>Memento S.r.l.</strong>, VAT number/Tax code 13344660967, REA MI-2717987, fully paid-up share capital of 10.000,00 €,
                certified e-mail address (PEC)
                <a href="mailto:certified@pec.memento-card.it">certified@pec.memento-card.it</a>
              </span>
                    </li>
                    <li>
                        <strong>Application:</strong>
                        the web site
                        www.memento-card.com
                    </li>
                    <li>
                        <strong>Products:</strong>
                        <ul>
                            <li>goods sold by the Owner</li>
                        </ul>
                    </li>
                    <li>
                        <strong>User:</strong>
                        any person who accesses and uses the Application
                    </li>
                    <li>
                        <strong>Consumer User:</strong>
                        the natural person who enters into a contract for purposes which are not related to his trade,
                        business or profession
                    </li>
                    <li>
                        <strong>Professional User:</strong>
                        the natural person of legal age or legal person who enters into a contract for the performance
                        or for the needs of his trade, business or profession
                    </li>
                    <li>
                        <strong>Content:</strong>
                        any textual or multimedia element in the Application, by way of example announcements,
                        insertions, reviews, images, etc.
                    </li>
                    <li>
                        <strong>Conditions:</strong>
                        this contract which governs the relationship between the Owner and the Users
                        as well as the sale of the Products offered by the Owner through the Application.
                    </li>
                </ul>
            </p>
        </li>
        <li>
            <h4>Detailed information on the Application’s offer</h4>
            <p>The Application provides Users an online tool to create a personalized postcard with personal images and
                allows the Users to ship the real paper postcard to a choosen worldwide adress. .</p>
        </li>
        <li>
            <h4>Scope of the Conditions</h4>
            <p>The use of the Application implies full acceptance of the Conditions by the User. Should the User not
                accept the Conditions and / or any other note, legal notice, information published or referred to
                therein, the User shall not use the Application or the services related.</p>
            <p>
                The Owner may amend the Conditions at any time.
            </p>
            <p>The applicable Conditions are those in force on the date of transmission of the purchase order of a
                Product.</p>
            <p>Before using the Application, the User is required to read the Conditions carefully save or print them
                for future reference.</p>
            <p>
                The Owner reserves the right to change, at his own discretion and at any time,
                even after the User has registered,
                the graphic interface of the Application, the Contents and their organisation, as well as any other
                feature that characterises the functionality and management of the Application, communicating to the
                User the relative instructions, when necessary.
            </p>
        </li>
        <li>
            <h4>Purchase through the Application</h4>
            <p>
                All Products offered through the Application are described in detail in the relevant product pages
                (quality, features, availability, price,
                delivery method, times and expenses,
                accessory charges, etc.). Some errors, inaccuracies or small differences between what is published in
                the Application and the Product may occur. Furthermore, the images of the Products shall be considered
                as a mere representation and do not constitute a contractual element.
            </p>
            <p>
                Purchases of one or more Products through the Application are permitted
                both to Consumer Users and to Professional Users.
            </p>
            <p>Purchases requests are permitted only to natural persons of legal age. For minors, any purchase of
                Products through the Application shall be examined and authorised by the parents or those exercising
                parental authority.</p>
            <p>The offer of Products through the Application shall be deemed as an invitation to prepare an offer and
                the order sent by the User as contractual purchase proposal, subject to the confirmation and / or the
                acceptance of the Owner as described below. Therefore, the Owner has the right to accept or refuse the
                User's order or delivery request at its discretion. Accordingly, the User is not entitled to complain
                about the Owner’s decision for any reason.</p>
            <p>
                The contract of sale of the Products is deemed to be concluded with the Owner’s acceptance of the User’s
                contractual purchase proposal or delivery request. The Owner shall inform the User of the acceptance by
                sending an order confirmation to the e-mail address indicated by the User. The confirmation shall
                contain the date of the order, User’s data, the characteristics of the Product and the information on
                its availability, the price or the manner in which the price is to be calculated, further charges and /
                or taxes, if any, delivery address, delivery times and costs, if any, the procedures for exercising the
                right to withdrawal or its possible exclusion and the guarantee.
            </p>
            <p>The contract of sale of the Products is not effective between the parties in absence of what is indicated
                in the preceding paragraph.</p>
            <p>In the event that the Product is not available, the Owner shall inform the User the new terms of delivery
                and ask if the User intends to confirm the order. It is understood that the contract shall be deemed as
                concluded only with reference to the Products accepted by the Owner.</p>
            <p>The User shall verify the correctness of the data reported in the order confirmation and immediately
                notify the Owner of any errors. The User shall keep a copy of the order, of the confirmation and of the
                Conditions.</p>
        </li>
        <li>
            <h4>Registration</h4>
            <p>
                To take advantage of the features of the Application, the User shall register and provide, truthfully
                and completely, all data requested in the registration form and accept the privacy policy (
                <a href="/privacy-policy">https://memento-card.it/privacy-policy</a>) and the Conditions.
            </p>
            <p>The User has the responsibility to keep login credentials. The login credentials shall be used
                exclusively by the User and cannot be transferred to third parties. The User undertakes to keep them
                confidential and to ensure that no third party has access to them. Should the User suspect or become
                aware of any improper use or disclosure, he shall immediately inform the Owner.</p>
            <p>The User guarantees that the personal information provided during the registration procedure is complete
                and truthful and undertakes to hold the Owner harmless from any damage, indemnity and / or penalty
                resulting from and / or in any way connected to the infringement by the User of the Application
                registration rules or the storage of the login credentials.</p>
        </li>
        <li>
            <h4>Account cancellation and closure</h4>
            <p>
                The registered User can interrupt the use of the Application at any time and deactivate his account or
                request the cancellation through the Application interface, if possible, or by sending a written
                communication to the e-mail address
                <a href="mailto:info@memento-card.com">info@memento-card.com</a>.
            </p>
            <p>In case of violation by the User of the Conditions or of the applicable legal provisions, the Owner
                reserves the right to suspend or close the User's account at any time and without notice.</p>
        </li>
        <li>
            <h4>Prices and payments</h4>
            <p>
                For each Product the price
                including VAT, if due
                is indicated. If the price cannot be calculated in advance, due to the nature of the Product, the
                methods for calculating the price shall be indicated.
            </p>
            <p>
                Furthermore, all possible taxes, additional costs
                and delivery costs
                which may vary depending on
                the destination, the chosen delivery method and / or
                the payment method used shall be indicated. If these expense items cannot reasonably be calculated in
                advance, there shall be an indication of which expenses to be charged to the User.
            </p>
            <p>The Owner reserves the right to change the price of the Products as well as any additional costs at any
                time. It is understood that price changes shall in no case affect the contracts already concluded before
                such change.</p>
            <p>The User undertakes to pay the price of the Product within the times with and methods indicated in the
                Application and to communicate the information requested.</p>
            <p>The Application uses third-party tools for processing payments and does not enter in any way into contact
                with the payment data provided (number of credit cards, name of the holder, password, etc.).</p>
            <p>Should these third party tools deny payment authorisation, the Owner shall not provide the Products and
                cannot be held responsible in any way.</p>
        </li>
        <li>
            <h4>Billing</h4>
            <p>
                The User who intended to receive the invoice for the payment shall provide the Owner with the billing
                information. In this case, the User declares that the information provided is true and releases the
                Owner from any liability in this regard.
            </p>
        </li>
        <li>
            <h4>Material Products’ delivery methods</h4>
            <p>The material products (including any tangible goods with digital elements) shall be delivered to the
                address indicated by the User, in the manner and at the time chosen or indicated in the Application and
                reported in the order confirmation. Unless otherwise specified, the delivery terms specified in the
                order confirmation shall provide the period of time usually required to deliver the Product from the
                moment the courier takes charge.</p>
            <p>In the event that it is not possible to provide the Products requested, a prompt notice shall be given to
                the User via e-mail, indicating the expected time of delivery or the reasons that make the delivery not
                possible.</p>
            <p>If the User does not intend to accept the new term or the delivery has become impossible, he can request
                a refund of the amount paid, which shall be paid promptly with the payment method used for the purchase,
                within a maximum of 14 days from the date on which the Owner became aware of the refund request.</p>
            <p>Upon receipt of the Product, the User is required to verify its compliance with the order as well as the
                integrity of the packaging. In case of evident damage to the packaging and / or to the Product, the User
                can refuse the delivery of the Product and may return it without any charge. Once the delivery document
                has been signed, the User cannot make any objection to the external characteristics of the Products
                delivered.</p>
            <p>The Owner is not liable for damages losses and costs incurred as a result of the failure to execute the
                contract due to force majeure.</p>
        </li>
        <li>
            <h4>Exclusion of the right to withdrawal of Professional Users</h4>
            <p>The Professional User is not entitled to withdraw from the contract of sale or delivery of the Products.
                The User expressly acknowledges that the indication in the order of a VAT identification number
                qualifies him as a Professional User.</p>
        </li>
        <li>
            <h4>
                Right to withdrawal of the Users from the purchase of Material Products
            </h4>
            <p>
                The Consumer User has the right to withdraw from the contract without penalty and without specifying the
                reason, within the term of
                14 days from the date of delivery of the Product, by sending a written communication to the e-mail
                address
                <a href="mailto:info@memento-card.com">info@memento-card.com</a>, using the optional withdrawal form
                provided in the following article or any other written declaration.
            </p>
            <p>
                In the case of separate delivery of more than one Product, ordered by the Consumer User with a single
                order, the term of
                14 days for exercising the right to withdrawal starts from the day of delivery of the last Product.
            </p>
            <p>In case of withdrawal, the User must return the Product to the Owner, without undue delay and in any case
                no later than 14 days from the date on which he notified his decision to withdraw from the contract. The
                User shall only bear the direct cost of returning the Product, unless the Owner has declared to bear
                it.</p>
            <p>In case of withdrawal exercised correctly, the Holder shall refund the payments received by the User. The
                refund shall be paid promptly with payment method used for the purchase, within a maximum of 14 days
                from the date on which the Owner became aware of refund request.</p>
            <p>The Owner shall not reimburse the delivery costs if the User has expressly chosen a type of delivery
                different from the least expensive type of delivery offered by the Owner.</p>
            <p>The Owner may withhold the reimbursement until the Product is returned or until the User has demonstrated
                that he has returned it, unless he has offered to pick up the Product himself.</p>
            <p>The Owner may not accept the return if the Product is malfunctioning due to improper use, negligence,
                physical, aesthetic or superficial damage or alteration, tampering or improper maintenance or wear and
                tear or absence of integral elements of the product (accessories, parts, etc.). In such cases, the Owner
                will return the purchased Product to the sender, charging the shipping costs to the sender.</p>
        </li>
        <li>
            <h4>Exclusion of the right to withdrawal of the User</h4>
            <p>The right to withdrawal from the contract of sale of the Products by the Consumer User is excluded
                relatively:</p>
            <ul>
                <li>to the supply of Products for which the price is dependent on fluctuations in the financial market
                    which cannot be controlled by the trader and which may occur within the withdrawal period
                </li>
                <li>the supply of Products made to the Consumer’s specifications or clearly personalised</li>
                <li>
                    to the supply of Products which are liable to deteriorate or expire rapidly. The same applies to all
                    foodstuff Products (including beverages) whose characteristics are subject to alteration also due to
                    incorrect conservation
                </li>
                <li>to the supply of sealed Products, which have been unsealed by the User and are not then suitable for
                    return due to health protection or hygiene reasons
                </li>
                <li>to the supply of Products that, according to their nature, been inseparably mixed with other items
                    after delivery
                </li>
                <li>to contracts where the User has specifically requested a visit from the trader for the purpose of
                    carrying out urgent repairs or maintenance. Where on the occasion of such a visit the trader
                    provides related services in addition to those specifically requested by the User or goods other
                    than replacement parts necessarily used in performing the maintenance or in making the repairs, the
                    right of withdrawal applies to those additional related services or goods
                </li>
                <li>to the supply of sealed audio or video recordings or computer software and that have been unsealed
                    after delivery
                </li>
                <li>to the supply of newspapers, periodical or magazines with the exception of subscription contracts
                    for the supply of such publications
                </li>
                <li>to contracts concluded at a public auction</li>
                <li>the provision of accommodation other than for residential purpose, transport of goods, car rental
                    services, catering or services related to leisure activities if the contract provides for a specific
                    date or period of performance
                </li>
            </ul>
            <p>
                For more information, the User shall contact the Owner at the e-mail address
                <a href="mailto:info@memento-card.com">info@memento-card.com</a>.
            </p>
        </li>
        <li>
            <h4>Optional form to exercise the right to withdrawal</h4>
            <p></p>
            The User can withdraw from the contract by using the following form, which must be completed in its entirety
            and sent to the e-mail address
            <a href="mailto:info@memento-card.com">info@memento-card.com</a>
            before the withdrawal period has expired:
            <p>
                <i>
                    I hereby communicate the withdrawal from the sales or supply contract relating to the following
                    product __________
                    <br/>
                    Order number:_______
                    <br/>
                    Date of the order: _______
                    <br/>
                    Name and surname: _______
                    <br/>
                    Address: ______
                    <br/>
                    E-mail associated with the account from which the order was made: ____________________
                    <br/>
                    Date: __________
                    <br/>
                </i>
            </p>
        </li>
        <li>
            <h4>Material Products’ guarantee for Professional Users</h4>
            <p>With regard to Material Products, Professional Users are subject to the guarantees for defects of the
                thing sold, the guarantee for defects of promised and essential quality and the other guarantees
                provided for by the Italian Civil Code (Sections 1490 and following of the Italian Civil Code).</p>
        </li>
        <li>
            <h4>Material Products’ guarantee of conformity for Consumer Users</h4>
            <p>Consumer Users are ensured the guarantee of conformity, provided for by articles 129 and following of the
                Consumer Code, for all tangible Products sold through the Application, except for the exclusion cases
                hypotheses provided for in article 128 of the Consumer Code.</p>
            <p>The Owner is liable responsible towards to the Consumer User for any lack of conformity that occurs
                within two years of delivery. During the first year, the Consumer User is not required to prove that the
                defect existed at the time of delivery as it is assumed that it already existed on that date, unless
                such presumption this hypothesis is incompatible with the nature of the Material Product or with the
                nature of the nature the lack of conformity.</p>
            <p>
                If the Consumer User intends to take advantage of the remedies provided for by the guarantee of
                conformity, he must send a written communication to the e-mail address
                <a href="mailto:info@memento-card.com">info@memento-card.com</a>.
            </p>
            <p>The Owner shall will promptly reply and indicate to the Consumer User the procedure to follow.</p>
            <p>For anything not provided for in this clause, articles 128 to 135-septies of the Consumer Code and the
                articles of the Italian civil code on the formation, validity and effectiveness of contracts, including
                the consequences of termination of the contract and the right to compensation for damage.</p>
        </li>
        <li>
            <h4>Content sent by the Users</h4>
            <p>The User can upload Content on the Application, provided that it is not illegal (e.g. obscene,
                intimidating, defamatory, pornographic, abusive or for any reason illegal or in violation of privacy,
                the intellectual and / or industrial property rights of the Owner and / or third parties), misleading,
                or is not otherwise harmful to the Owner and / or third parties or contains viruses, political
                propaganda, commercial solicitation, mass e-mail or any other form of spamming. In the event of a
                dispute by a third party regarding any announcement or conduct related to it, the User assumes full
                liability and undertakes to hold the Holder harmless from any damage, loss or expense.</p>
            <p>The User guarantees that the Contents are sent to the Application through his account from a natural
                person of legal age. For natural persons under legal age, the sending of Contents must be examined and
                authorised by the parents or by those exercising parental authority.</p>
            <p>The User is the sole and exclusive responsible for the use of the Application with regard to the
                publication, consultation, management of the Content and contact between Users and is therefore the sole
                guarantor and responsible for the correctness, completeness and lawfulness of the Contents and its own
                behaviour.</p>
            <p>It is forbidden to use an e-mail address that is not owned by the User, to use the personal data and
                credentials of another User in order to use his identity, or in any other way to declare false
                information about the origin of the Contents.</p>
            <p>The Owner is unable to ensure timely control over the Content received and reserves the right at any time
                to cancel, move or modify the Content, which, at its discretion, appears to be illegal, abusive,
                defamatory, obscene or prejudicial to the right to author and trademarks or in any case
                unacceptable.</p>
            <p>Users grant the Owner a non-exclusive right of use on the Content sent, without limitations of
                geographical areas. The Owner may therefore, directly or through trusted third ies, use, modify, copy,
                transmit, extract, publish, distribute, publicly perform, disseminate, create derivative works, host,
                index, store, note, encode, modify and adapt (including without limitation the right to adapt for
                transmission in any form of communication) in any form, any Content (including images, messages,
                including audio and video) that should be sent by the User, including through third parties.</p>
            <p>The Content sent will not be returned and the Owner will not be liable towards Users for the loss,
                modification or destruction of the transmitted Content.</p>
            <p>It is expressly forbidden, unless explicitly authorised by the Owner: i) the use of automatic
                announcement uploading systems, except those expressly authorised, ii) serial publication and / or
                management of advertisements for third parties by any means or method, iii) resell the Owner's services
                to third parties.</p>
        </li>
        <li>
            <h4>Industrial and intellectual property rights</h4>
            <p>All the contents of the Application, including texts, documents, trademarks, logos, images, graphics,
                their arrangement and their adaptations are protected by copyright and trademark legislation. The
                Application may also contain images, documents, logos and trademarks of third parties which have
                expressly authorized the Owner to be published in the Application. Except for strictly personal uses, it
                is not allowed to copy, alter, distribute, publish or use the Contents without the specific
                authorization of the Owner.</p>
        </li>
        <li>
            <h4>Exclusion of warranty</h4>
            <p>The Application is provided "as is" and "as available" and the Owner does not provide any explicit or
                implicit guarantee in relation to the Application, nor does it provide any guarantee that the
                Application will satisfy the needs of the Users or that it will not have never interrupt or be
                error-free or free of viruses or bugs.</p>
            <p>The Owner will endeavour to ensure that the Application is available continuously 24 hours a day, but
                cannot in any way be held responsible if, for any reason, the Application is not accessible and / or
                operational at any time or for any period . Access to the Application may be suspended temporarily and
                without notice in the event of system failure, maintenance, repairs or for reasons wholly unrelated to
                the owner's will or due to force majeure events.</p>
        </li>
        <li>
            <h4>Limitation of liability</h4>
            <p>The Owner shall not be held liable towards the User, except in the case of wilful misconduct or gross
                negligence, for disservices or malfunctions connected to the use of the internet outside of its own
                control or that of its suppliers.</p>
            <p>
                Furthermore, the Owner will not be liable for damages, losses and costs incurred by the User as a result
                of failure to execute the contract for reasons not attributable to him, the User only having the right
                to a full refund of the price paid and of any additional charges incurred.
            </p>
            <p>
                The Owner assumes no responsibility for any fraudulent or illegal use that may be made by third parties
                of credit cards and other means of payment,
                since he does not enter in any way in contact with the payment data used (credit card number, name of
                the holder, password, etc.).
            </p>
            <p>The Owner shall not be held liable for:</p>
            <ul>
                <li>any loss of business opportunities and any other loss, even indirect, possibly suffered by the User
                    that is not a direct result of the breach of contract by the Owner
                </li>
                <li>incorrect or unsuitable use of the Application by Users or third parties</li>
                <li>the issue of incorrect documents or fiscal data due to errors in the data provided by the User, the
                    latter being the only person responsible for the correct insertion
                </li>
            </ul>
            <p>In no case the Owner shall be held liable for a sum greater than twice the cost paid by the User.</p>
        </li>
        <li>
            <h4>Force majeure</h4>
            <p>The Owner shall not be held responsible for the failure or late fulfilment of its obligations, due to
                circumstances beyond its reasonable control due to events of force majeure or, in any case, to
                unforeseen and unforeseeable events and, in any case, independent of its will.</p>
            <p>The fulfilment of the obligations by the Owner shall be considered suspended for the period in which
                events of force majeure occur.</p>
            <p>The Owner will perform any act in his power in order to identify solutions that allow the correct
                fulfilment of his obligations despite the persistence of events due to force majeure.</p>
        </li>
        <li>
            <h4>Links to third-party web sites</h4>
            <p>The Application may contain links to third-party web sites / applications. The Owner has no control over
                them and, therefore, is in no way responsible for the contents of these sites / applications.</p>
            <p>Some of these links may link to third-party sites / applications that provide services through the
                Application. In these cases, the general conditions for the use of the site / application and for the
                use of the service prepared by the third parties will be applied to the individual services, with
                respect to which the Owner assumes no responsibility.</p>
        </li>
        <li>
            <h4>Privacy</h4>
            <p>
                The protection and processing of personal data will be in accordance with the Privacy Policy which can
                be consulted on the page
                <a href="/privacy-policy">https://memento-card.it/privacy-policy</a>
            </p>
        </li>
        <li>
            <h4>Applicable law and jurisdiction</h4>
            <p>The Conditions are subject to Italian law.</p>
            <p>For Consumer Users, any dispute concerning the application, execution and interpretation of these
                Conditions shall be referred to courts where the Consumer User resides or has elected domicile, if
                located in the territory of the Italian Republic, without prejudice to the right of the User Consumer of
                going to court other than the "consumer court" pursuant to Section 66 bis of the Italian Consumer Code,
                competent for the territory according to one of the criteria of the Sections 18, 19 and 20 of the civil
                procedural code.</p>
            <p>The application to Consumer Users who do not have their residence or domicile in Italy of any more
                favourable and mandatory provisions established by the law of the country in which they have their
                residence or domicile is reserved, in particular in relation to the term for the exercise of the right
                of withdrawal, after the return of the Products, in case of exercise of this right, the formalities of
                the communication and the legal guarantee of conformity.</p>
            <p>For Users who are not Consumers, any dispute concerning the application, execution and interpretation of
                these Conditions will be referred to the forum of the place where the Owner is based.</p>
        </li>
        <li>
            <h4>Online dispute resolution for Consumer Users</h4>
            <p>The consumer user residing in Europe shall be aware of the fact that the European Commission has
                established an online platform that provides an alternative dispute resolution tool. This tool can be
                used by the Consumer User to resolve any dispute relating to and / or deriving from contracts for the
                sale of goods and the provision of services concluded online. Consequently, the Consumer User can use
                this platform for the resolution of any dispute arising from the contract entered into online. The
                platform is available at the following address: ec.europa.eu/consumers/odr/</p>
        </li>
    </ol>
    <p>Date 11/03/2024</p>
    </PageWrapper>
}

export default TermsAndConditions