import React, {useContext} from "react";
import IsGreetingsContext from "./imagesselection/isGreetingsContext";
import {RootState, useAppSelector} from "../../../utils/store";
import {Backdrop, CircularProgress} from "@mui/material";

const SubmissionLoadingBackdrop = () => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const stateSelector = (state: RootState) =>
        isGreetings ? state.greetings : state.app

    const {
        isLoadingSubmission
    } = useAppSelector(stateSelector)

    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingSubmission}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
}

export default SubmissionLoadingBackdrop