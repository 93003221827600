import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../../utils/store";
import {getCurrentUser} from "aws-amplify/auth";
import {Cart, deletePostcardFromCart, isPromoCodeValid as _isPromoCodeValid} from "../../api/api";
import {getAlert, getPromotionFromPromoCode, sendToCheckout} from "../app/common/postcardAppUtils";
import {Postcard} from "../../utils/postcard";
import {AlertMessage} from "../app/common/imagesselection/alertsContext";

export interface CartState {
    cart: Cart | undefined
    promotion: number
    promoCodeName: string
    promoCodeId: string | undefined
    isLoadingCheckout: boolean
    isLoadingPromoCodeValidation: boolean
    alerts: AlertMessage[]
}

const initialState: CartState = {
    cart: undefined,
    promotion: 1.0,
    promoCodeName: '',
    promoCodeId: undefined,
    isLoadingCheckout: false,
    isLoadingPromoCodeValidation: false,
    alerts: [],
}

const validatePromoCode = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('cart/validatePromoCode', async (data, { getState }) => {
    const { cart } = getState()
    const promoCodeName = cart.promoCodeName

    if (promoCodeName === '') {
        throw Error('Please insert a valid promo code')
    } else {
        const {userId} = await getCurrentUser()
        const result = await _isPromoCodeValid(promoCodeName, userId)

        if (result === null) throw Error('Unable to validate promo code')
        else if (result === false) throw Error('The promo code is invalid')

        return result.id
    }
})

const submitOrder = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('cart/submitOrder', async (data: { postcard: Postcard, price: number }[], { getState }) => {
    const { cart } = getState()
    const promoCodeId = cart.promoCodeId

    const response = await sendToCheckout(data.map(item => ({
        id: item.postcard.id, price: item.price
    })), window, promoCodeId)

    if (!response) {
        throw Error('Failed to process submission')
    }
})

const deletePostcard =  createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('cart/deletePostcard', async (data: { id: string, cartItemId: string }): Promise<{ id: string, cartItemId: string }> => {
    const postcardIdToDelete = data.id
    const cartItemIdToDelete = data.cartItemId

    const response = await deletePostcardFromCart(postcardIdToDelete, cartItemIdToDelete)

    if (!response) {
        throw Error('Unable to delete postcard from cart')
    }

    return data
})

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updatePromoCodeName: (state, action: PayloadAction<string>) => {
            state.promoCodeName = action.payload
        },
        addErrorAlert: (state, action: PayloadAction<string>) => {
            state.alerts.push(getAlert(action.payload, 'error'))
        },
        removeAlert: (state, action: PayloadAction<string>) => {
            const id = action.payload
            state.alerts = [...state.alerts.filter(alert => alert.id !== id)]
        },
        setCart: (state, action: PayloadAction<Cart>) => {
            state.cart = action.payload
        }
    },
    extraReducers:  builder => {
        builder
            .addCase(validatePromoCode.pending, (state) => {
                state.isLoadingPromoCodeValidation = true
            })
            .addCase(validatePromoCode.fulfilled, (state, action: PayloadAction<string>) => {
                state.isLoadingPromoCodeValidation = false
                state.promotion = getPromotionFromPromoCode(true, state.promoCodeName)
                state.promoCodeId = action.payload
                state.promoCodeName = ''
            })
            .addCase(validatePromoCode.rejected, (state, action) => {
                state.isLoadingPromoCodeValidation = false
                state.promotion = 1.0
                state.promoCodeId = undefined
                state.promoCodeName = ''
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(submitOrder.pending, (state) => {
                state.isLoadingCheckout = true
            })
            .addCase(submitOrder.fulfilled, (state) => {
                state.cart = initialState.cart
                state.promotion = initialState.promotion
                state.promoCodeId = initialState.promoCodeId
                state.promoCodeName = initialState.promoCodeName
                state.isLoadingCheckout = false
            })
            .addCase(submitOrder.rejected, (state, action) => {
                state.isLoadingCheckout = false
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(deletePostcard.pending, (state) => {

            })
            .addCase(deletePostcard.fulfilled, (state, action: PayloadAction<{ id: string, cartItemId: string }>) => {
                if (state.cart) {
                    state.cart.postcards = state.cart.postcards.filter(
                        postcardData => postcardData.cartItemId !== action.payload.cartItemId
                    )
                    state.alerts.push(getAlert('Successfully removed postcard', 'info'))
                }
            })
            .addCase(deletePostcard.rejected, (state, action) => {
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
    }
})

export const { updatePromoCodeName, removeAlert, addErrorAlert, setCart } = cartSlice.actions
export { validatePromoCode, submitOrder, deletePostcard }
export default cartSlice.reducer
export const selectCart = (state: RootState) => state.cart