import {Box, IconButton, Typography} from "@mui/material";
import {Address} from "../../../../../utils/postcard";
import {ChangeEvent, useEffect, useRef} from "react";

type GreetingsAddressesProps = {
    addresses: Address[]
    fileName: string
    handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void
    onDelete: () => void
}

const AddressesBox = ({ addresses, fileName, handleFileUpload, onDelete }: GreetingsAddressesProps) => {
    const inputFileRef = useRef<HTMLInputElement>(null)

    const openDialog = () => {
        const inputFile = inputFileRef.current
        if (inputFile) inputFile.click()
    }

    useEffect(() => {
        const input = inputFileRef.current
        if (input) {
            input.onclick = function () {
                input.value = ''
            }
        }
    }, [inputFileRef])

    return <Box sx={{width: '100%', height: '100%'}}>
        <input ref={inputFileRef} type={'file'} accept={'.xlsx, .xls, .csv'} onChange={handleFileUpload} style={{
            display: 'none'
        }}/>
        <Typography sx={{fontWeight: 'bold', color: 'black', textAlign: 'left', fontSize: 12, fontFamily: 'Poppins'}}>
            {addresses.length} {addresses.length === 1 ? 'ADDRESS IMPORTED' : 'ADDRESSES IMPORTED'}
        </Typography>
        <Typography sx={{
            textAlign: 'left',
            fontSize: 10,
            textDecoration: 'underline',
            textTransform: 'uppercase',
            color: 'black',
            fontFamily: 'Poppins'
        }}>
            {fileName}
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 1}}>
            <IconButton onClick={openDialog}>
                <img src={'icons/app/edit.svg'} alt={'Change file'} height={20} width={20}/>
            </IconButton>
            <IconButton onClick={onDelete}>
                <img src={'icons/app/delete.svg'} alt={'Delete file'} height={20} width={20}/>
            </IconButton>
        </Box>
    </Box>
}

export default AddressesBox