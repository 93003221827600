import PageWrapper from "../../components/PageWrapper";
import './main.css'

const PrivacyPolicy = () => {
    return <PageWrapper>
        <div className="center">
            <h1>
                Privacy Policy
            </h1>
            <br/>
        </div>
        <div>
            <p>
                The purpose of this document is to inform the natural person (hereinafter “<strong>Data Subject</strong>”)
                about the processing of his/her personal data (hereinafter “<strong>Personal Data</strong>”) collected
                by the data controller,
                Memento S.r.l.,
                with registered office in Via Merano, 3; 20060; Gessate (MI) ,
                Tax Code/VAT No. 13344660967,
                MI-2717987,
                e-mail address info@memento-card.com,
                (hereinafter “<strong>Data Controller</strong>”), via
                the website
                www.memento-card.com (hereinafter “<strong>Application</strong>”).
            </p>
            <p>Changes and updates will be effective as soon as they are published on the Application. In case of
                non-acceptance of the changes made to the Privacy Policy, the Data Subject shall stop using this
                Application and may ask the Data Controller to delete his/her Personal Data.</p>
        </div>
        <ol>
            <li>
                <h4>Categories of Personal Data processed</h4>
                <p>The Data Controller processes the following types of Personal Data voluntarily provided by the Data
                    Subject:</p>
                <ul>
                    <li>
                        <strong>Contact Data</strong>: first name, last name, address, e-mail address, phone number,
                        pictures, authentication credentials, any further information sent by the Data Subject, etc.
                    </li>
                    <li>
                        <strong>Fiscal and payment Data</strong>: tax code, VAT number, credit card data, bank account
                        details, etc.
                    </li>
                </ul>
                <p>The Data Controller processes the following types of Personal Data collected automatically:</p>
                <ul>
                    <li>
                        <strong>Technical Data</strong>: Personal Data produced by devices, applications, tools and
                        protocols such as, for example, information about the device used, IP addresses, browser type,
                        type of Internet provider (ISP). Such Personal Data may leave traces which, combined with unique
                        identifiers and other information received by the servers, can be used to create profiles of
                        individuals
                    </li>
                    <li>
                        <strong>Usage Data</strong>: such as, for example, pages visited, number of clicks, actions
                        taken, duration of sessions, etc.
                    </li>
                </ul>
                <p>If the Data Subject decides not to provide Personal Data for which there is a legal or contractual
                    obligation, or if such data is a necessary requirement for the conclusion of the contract with the
                    Data Controller, it will be impossible for the Data Controller to establish or continue any
                    relationship with the Data Subject.</p>
                <p>The Data Subject who communicates Personal Data of third parties to the Data Controller is directly
                    and exclusively liable for their origin, collection, processing, communication or divulgation.</p>
            </li>
            <li>
                <h4>Cookies and similar technologies</h4>
                <p>
                    The Application uses cookies, web beacons, univocal identifiers and other similar technologies to
                    collect the Data Subject's Personal Data on visited pages and links and other actions performed
                    during the use of the Application. This data is stored and then used the next time the interested
                    party browses the Application. The full Cookie Policy can be viewed at the following address:
                    <a href={'/cookie-policy'}>https://memento-card.it/cookie-policy</a>
                </p>
            </li>
            <li>
                <h4>Legal basis and purpose of data processing</h4>
                <p>The processing of Personal Data is necessary:</p>
                <ol type="a">
                    <li>
                        for the performance of the contract with the Data Subject and especially:
                        <ol>
                            <li>
                                <strong>fulfillment of any obligation arising from the pre-contractual or contractual
                                    relationship with the Data Subject</strong>
                            </li>
                            <li>
                                <strong>registration and authentication of the Data Subject:</strong>
                                to allow the Data Subject to register in the Application, to access it and to be
                                identified in it, also via external platforms
                            </li>
                            <li>
                                <strong>support and contact with the Data Subject</strong>: to answer the Data Subject's
                                requests
                            </li>
                            <li>
                                <strong>management of payment</strong>: to manage payments by credit card, bank transfer
                                or other methods
                            </li>
                        </ol>
                    </li>
                    <li>
                        for legal obligations and especially:
                        <ol>
                            <li>
                                <strong>the fulfilment of any obligation provided for by the applicable norms</strong>,
                                laws and regulations, in particular, on tax and fiscal matters
                            </li>
                        </ol>
                    </li>
                    <li>
                        for the legitimate interest of the Data Controller, for:
                        <ol>
                            <li>
                                <strong>marketing purposes by e-mail of products and/or services of the Data
                                    Controller</strong>
                                &nbsp;to directly sell the Data Controller's products or services using the email
                                provided by the Data Subject in the context of the sale of a product or service similar
                                to the one being sold
                            </li>
                            <li>
                                <strong>management, optimization and monitoring of the technical infrastructure</strong>:
                                to identify and solve any technical issue, to improve the performance of the
                                Application, to manage and organize the information in a computer system (e.g. server,
                                database, etc.)
                            </li>
                            <li>
                                <strong>security and anti-fraud</strong>: to guarantee the security of the Data
                                Controller’s assets, infrastructures and networks
                            </li>
                            <li>
                                <strong>anonymous data based statistics</strong>: in order to carry out statistical
                                analysis on aggregated and anonymous data in order to analyze behaviors of the Data
                                Subject to improve products and/or services provided by the Data Controller and better
                                meet the expectations of the Data Subject
                            </li>
                        </ol>
                    </li>
                    <li>
                        on the basis the Data Subject's consent, for:
                        <ol>
                            <li>
                                <strong>profiling the Data Subject for marketing purposes</strong>: to provide the Data
                                Subject with information on the Data Controller's products and/or services through
                                automated processing designed to collect personal information to predict or assess the
                                Data Subject's preferences or behaviors
                            </li>
                            <li>
                                <strong>retargeting and remarketing</strong>: to reach with a customized advertisement
                                the Data Subject who has already visited or shown interest in the products and/or
                                services offered by the Application using his Personal Data. The Data Subject may
                                opt-out by visiting the
                                <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative
                                    page</a>
                            </li>
                            <li>
                                <strong>marketing purposes of the Data Controller’s products and/or services</strong>:
                                to send information or commercial and/or promotional materials, to perform direct sales
                                activities of the Data Controller’s products and/or services or to conduct market
                                research with automated and traditional methods
                            </li>
                            <li>
                                <strong>marketing purposes of third-party products and/or services</strong>: to send
                                information or commercial and/or promotional materials of third parties, to carry out
                                direct sales activities or to perform market research of their products and/or services
                                with automated and traditional methods
                            </li>
                            <li>
                                <strong>detection of the exact location of the Data Subject</strong>: to detect the
                                presence of the Data Subject, to control logins, times and presence of the Data Subject
                                in a specific place, etc.
                            </li>
                        </ol>
                    </li>
                </ol>
                <p>On the basis of the legitimate interest of the Data Controllerowner, the application allows
                    interactions with external web platforms or social networks whose processing of personal data is
                    governed by their respective privacy policies to which please refer. The interactions and
                    information acquired by this Application are in any case subject to the privacy settings that the
                    Data Subject has chosen on such platforms or social networks. Such information - in the absence of
                    specific consent to processing for other purposes - is used exclusively to allow the use of the
                    Application and to provide the information and services requested.</p>
                <p>The Data Subject's Personal Data may also be used by the Data Controller to protect itself in
                    judicial proceedings before the competent courts.</p>
            </li>
            <li>
                <h4>Data processing methods and receivers of Personal Data</h4>
                <p></p>
                <p>The processing of Personal Data is performed via paper-based and computer tools with methods of
                    organization and logics strictly related to the specified purposes and through the adoption of
                    appropriate security measures.</p>
                <p>
                    Personal Data are processed exclusively by:
                    <ul>
                        <li>persons authorized by the Data Controller to process Personal Data who have committed
                            themselves to confidentiality or have an appropriate legal obligation of confidentiality;
                        </li>
                        <li>subjects that operate independently as separate data controllers or by subjects designated
                            as data processors by the Data Controller in order to carry out all the processing
                            activities necessary to pursue the purposes set out in this policy (for example, business
                            partners, consultants, IT companies, service providers, hosting providers);
                        </li>
                        <li>subjects or bodies to whom it is mandatory to communicate Personal Data by law or by order
                            of the authorities.
                        </li>
                    </ul>
                </p>
                <p>The subjects listed above are required to use appropriate measures and guarantees to protect Personal
                    Data and may only access data necessary to perform their duties.</p>
                <p>Personal Data will not be indiscriminately shared in any way.</p>
            </li>
            <li>
                <h4>Place</h4>
                <p>Personal Data will not be transferred outside the territory of the European Economic Area (EEA).</p>
            </li>
            <li>
                <h4>Personal Data storage period</h4>
                <p>
                    Personal Data will be stored for the period of time that is required to fulfill the purposes for
                    which it was collected. In particular:
                    <ul>
                        <li>for purposes related to the execution of the contract between the Data Controller and the
                            Data Subject, will be stored for the entire duration of the contractual relationship and,
                            after termination, for the ordinary prescription period of 10 years. In the event of legal
                            disputes, for the entire duration of such disputes, until the time limit for appeals has
                            expired
                        </li>
                        <li>for purposes related to legitimate interests of the Data Controller, they will be stored
                            until the fulfilment of such interest
                        </li>
                        <li>in compliance with legal obligations, by order of an authority and for legal protection,
                            they shall be stored according to the relevant timeframes provided for by such obligations,
                            regulations and, in any case, until the expiry of the prescriptive term provided for by the
                            rules in force
                        </li>
                        <li>
                            for purposes based on the consent of the Data Subject, they will be stored until the consent
                            is revoked
                        </li>
                    </ul>
                </p>
                <p>At the end of the conservation period, all Personal Data will be deleted or stored in a form that
                    does not allow the identification of the Data Subject.</p>
            </li>
            <li>
                <h4>Rights of the Data Subject</h4>
                <p>Data Subjects may exercise specific rights regarding the Personal Data processed by the Data
                    Controller. In particular, the Data Subject has the right to:</p>
                <ul>
                    <li>be informed about the processing of their Personal Data</li>
                    <li>withdraw consent at any time</li>
                    <li>restrict the processing of his or her Personal Data</li>
                    <li>object to the processing of their Personal Data</li>
                    <li>access their Personal Data</li>
                    <li>verify and request the rectification of their Personal Data</li>
                    <li>restrict the processing of their Personal Data</li>
                    <li>obtain the erasure of their Personal Data</li>
                    <li>transfer their Personal Data to another data controller</li>
                    <li>file a complaint with the Personal Data protection supervisory authority and/or take legal
                        action.
                    </li>
                </ul>
                <p>
                    In order to use their rights, Data Subjects may send a request to the following e-mail address
                    info@memento-card.com. Requests will be immediately treated by the Data Controller and processed as
                    soon as possible, in any case within 30 days.
                </p>
            </li>
            <li>
                <h4>Data Protection Officer</h4>
                <p>
                    The Data Protection Officer is
                    Leonardo Bertelli,
                    Via Merano, 3; 20060; Gessate (MI),
                    Tax Code BRTLRD96T18L400F,
                    e-mail info@memento-card.com,
                    .
                </p>
            </li>
        </ol>
        <p>Last update: 11/03/2024</p>
    </PageWrapper>
}

export default PrivacyPolicy