import {Box, Typography, useTheme} from "@mui/material";

type EditLocationBoxProps = {
    onClick: () => void
    postcardWidth: number
    isSmall: boolean
}

const EditLocationBox = ({ postcardWidth, onClick, isSmall }: EditLocationBoxProps) => {
    const theme = useTheme();

    return <Box sx={{
        ml: '2.82%',
        width: '40%',
        height: '80%',
        top: 0,
        position: 'absolute',
        border: '1.5px solid ' + theme.palette.primary.main,
        borderRadius: '4.65608px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pl: '1.88%',
        backgroundColor: theme.palette.primary.light,
        '&:hover': {
            boxShadow: '0px 0px 4.2963px rgba(0, 0, 0, 0.25)',
            cursor: 'pointer'
        }
    }}>
        <Box>
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center'
            }} onClick={onClick}>
                {isSmall ? <>
                    <img src={'icons/app/messagestep/plus_sm.svg'} alt={'+'} width={'15%'} height={'15%'}/>
                    <Box sx={{width: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: postcardWidth * 0.0220,
                        color: theme.palette.primary.main,
                        ml: 0.5,
                        textAlign: 'left'
                    }}>ADD YOUR LOCATION</Typography>
                    </Box>
                </> : <>
                    <img src={'icons/app/messagestep/plus_sm.svg'} alt={'+'} width={'20%'} height={'20%'}/>
                    <Typography sx={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: postcardWidth * 0.0160,
                        color: theme.palette.primary.main,
                        ml: 1,
                        textAlign: 'left'
                    }}>ADD LOCATION</Typography>
                </>}
            </Box>
        </Box>
    </Box>
}

export default EditLocationBox