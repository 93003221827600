import PageWrapper from "../components/PageWrapper";
import {Box, Button, Card, CardContent, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useLoaderData, useNavigate} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
// @ts-ignore
import { Helmet } from 'react-helmet'

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import PriceBox from "../components/PriceBox";

const LandingPage = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const belowMd = useMediaQuery(theme.breakpoints.down('sm'));

    const StepCard = ({ src, alt, text }: { src: string, alt: string, text: string }) => <Box sx={{
        width: { xs: 'calc(100% - 32px)', sm: 'calc(50% - 32px)', maxWidth: 280 },
        m: 2
    }}>
        <Card style={{ height: '100%' }}>
            <CardContent>
                <Box sx={{ pb: 2 }}>
                    <img src={src} alt={alt} draggable={false} />
                </Box>
                <Typography>{text}</Typography>
            </CardContent>
        </Card>
    </Box>

    const OfferingCard = ({ src, alt, text, heading, price }: { src: string, alt: string, text: string, heading: string, price: number }) => <Card sx={{
            width: { xs: 'calc(100% - 32px)', md: 'calc(33.33% - 32px)', maxWidth: 360 },
            m: 2,
            px: 1,
            borderRadius: '12px',
            zIndex: 1000,
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0px 2px 4px -1px rgba(40, 40, 40, 0.2), 0px 4px 5px 0px rgba(40, 40, 40, 0.14), 0px 1px 10px 0px rgba(40, 40, 40, 0.12)'
            }
        }}
        onClick={() => navigate('/app')}
    >
            <CardContent>
                <Box sx={{ pb: 2 }}>
                    <img src={src} alt={alt} style={{ width: '100%' }} draggable={false} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant={'h6'}>{heading}</Typography>
                    <PriceBox price={price} />
                </Box>
                {/* @ts-ignore */}
                <Typography variant={'small'}>{text}</Typography>
            </CardContent>
    </Card>

    const PartnerCard = ({ src, alt }: { src: string, alt: string }) => <Box sx={{
        width: { xs: 'calc(100% - 32px)', sm: 'calc(50% - 32px)' },
        m: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <img src={src} alt={alt} style={{ width: '100%' }} />
    </Box>

    const CommunityCard = ({ src, alt, review, stars, name }: { src: string, alt: string, review: string, stars: number, name: string }) => <Box sx={{ width: 450 }}>
            <img src={src} alt={alt} style={{ padding: 8, borderRadius: 0, marginBottom: 19, backgroundColor: 'white' }} className={'review-card review-postcard'} draggable={false} />
            <Card className={'review-card'}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant={'body1'} sx={{ pb: 2 }}>{review}</Typography>
                        <Box  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                            {/* @ts-ignore */}
                            <Typography variant={'subtitle'}>{name}</Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>

    const community: { src: string, alt: string, review: string, stars: number, name: string }[] = [{
        src: 'images/landingpage/reviews/image_1.png',
        alt: 'Postcard',
        review: 'The first time I saw the Golden Gate Bridge I was amazed. I took a picture and sent instantly a postcard in Italy to my family with Memento. They are going to love It!',
        stars: 4,
        name: 'Daniel S.'
    }, {
        src: 'images/landingpage/reviews/image_2.png',
        alt: 'Postcard',
        review: 'This trip in Italy filled my heart with many new adventures to tell. I love this picture taken in Matera, so I created a postcard and sent it to my brother. I hope It will inspire him to travel more! ',
        stars: 4,
        name: 'July C.'
    }, {
        src: 'images/landingpage/reviews/image_3.png',
        alt: 'Postcard',
        review: 'I spent a whole day on the dunes of the Sahara desert, doing sandboard, seeing an unforgettable sunset and spotting countless stars in the sky. Insane I was able to send a postcard to my family directly from there, thanks Memento!',
        stars: 4,
        name: 'Leonardo B.'
    }, {
        src: 'images/landingpage/reviews/image_4.png',
        alt: 'Postcard',
        review: 'Discovering beautiful places, meeting amazing people and learning new things everyday. This is my life in Spain during my Erasmus. I shared this postcard with my mom using Memento. Best idea ever! ',
        stars: 4,
        name: 'Steve R.'
    }, {
        src: 'images/landingpage/reviews/image_5.png',
        alt: 'Postcard',
        review: 'I am spending a semester abroad in China, surrounded by a completely new culture and unique places. Memento is the best way to show all these adventures to my family back home!',
        stars: 4,
        name: 'Andrea V.'
    }, {
        src: 'images/landingpage/reviews/image6.png',
        alt: 'Postcard',
        review: 'Surf, eat, sleep and repeat. This week in a Surf House in Lanzarote changed my life. Looking forward to leave for the next island and sharing other memories to my friends with Memento!',
        stars: 4,
        name: 'Luca B.'
    }]

    return <PageWrapper>
        <Helmet>
            <meta name="title" content="Memento Card – A real postcard to your loved ones | Memento Card – Una vera cartolina per i tuoi cari" />
            <meta name="description" content="Discover Memento Card, the way to
preserve and share your most precious memories. Create your personalized
postcard and give your loved ones the emotions of every special moment. |
Scopri Memento Card, il modo per conservare e condividere i tuoi ricordi più
preziosi. Crea la tua cartolina personalizzata e regala alle persone care le
emozioni di ogni momento speciale." />
            <meta name="keywords" content="Memento Card, postcard, memories,
preserve memories, share memories, personalized card, special memories,
travel postcards, cartolina, ricordi, conservare ricordi, condividere ricordi, carta
personalizzata, ricordi speciali, cartoline viaggio" />
        </Helmet>
        <Box sx={{ display: 'flex', width: 'calc(100% - 48px)', flexDirection: { xs: 'column', md: 'row' }, mb: 4, m: 3, pt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'start', width: { xs: '100%', md: '50%' }, flexDirection: 'column' }}>
                <Typography variant={'h1'} sx={{ fontSize: belowMd ? 48 : 64 }}>
                    Send a real <span className={ 'header-manual' } style={{ fontSize: belowMd ? 51 : 64 }}>postcard</span><br /> to your friends!
                </Typography>
                <Typography variant={'h6'} sx={{ py: { xs: 4, md: 9 }, color: '#666' }}>
                    Yes, they will receive it at home!
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ width: 80, display: { xs: 'block', md: 'none' } }}>
                        <img alt={'Arrow right'} src={'images/landingpage/arrow.png'} width={80} draggable={false} />
                    </Box>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => navigate('/app')}
                    >
                        Send postcard
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: { xs: '100%', md: '50%' }, pl: { xs: 0, md: 5 } }}>
                <img alt={'Real postcards'} src={'images/hero.jpeg'} style={{ maxWidth: '100%', maxHeight: 640 }} draggable={false} />
            </Box>
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: 4, backgroundColor: theme.palette.secondary.main, alignItems: 'center', px: 3, pb: 6, pt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant={'h4'}>
                    How does it <span style={{ color: theme.palette.primary.main }}>work</span>
                </Typography>
                <img alt={'Plane'} src={'icons/landingpage/howdoesitwork/title.svg'} style={{ marginLeft: 16, display: belowMd ? 'none' : 'block' }} draggable={false} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', pt: 2, alignItems: { xs: 'center', md: 'stretch' } }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: { xs: '100%', md: '50%' }, alignItems: { xs: 'center', sm: 'stretch' }, justifyContent: { xs: 'center', md: 'end' } }}>
                    <StepCard src={'icons/landingpage/howdoesitwork/new_image.svg'} alt={'Add'} text={'Upload an image'} />
                    <StepCard src={'icons/landingpage/howdoesitwork/write.svg'} alt={'Write'} text={'Write a message'} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: { xs: '100%', md: '50%' }, alignItems: { xs: 'center', sm: 'stretch' }, justifyContent: { xs: 'center', md: 'start' } }}>
                    <StepCard src={'icons/landingpage/howdoesitwork/home.svg'} alt={'Insert'} text={'Insert the address'} />
                    <StepCard src={'icons/landingpage/howdoesitwork/ship.svg'} alt={'Ship'} text={'Ship a real postcard'} />
                </Box>
            </Box>
            {/* TODO add media query for mobile bg asset transform */}
            <Box className={'howdoesitwork-bg'} />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', mb: 4, alignItems: 'center', py: 3, px: 0 }}>
            <Typography variant={'h4'} sx={{ px: 3 }}>
                Our <span style={{ color: theme.palette.primary.main }}>community</span>
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', py: 4 }}>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    pagination={true}
                    slidesPerView={belowMd ? 1 : 2}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 300
                    }}
                    modules={[EffectCoverflow, Pagination]}
                    style={{ paddingBottom: 32 }}
                >
                    <SwiperSlide><CommunityCard {...community[2]} /></SwiperSlide>
                    <SwiperSlide><CommunityCard {...community[1]} /></SwiperSlide>
                    <SwiperSlide><CommunityCard {...community[3]} /></SwiperSlide>
                    <SwiperSlide><CommunityCard {...community[0]} /></SwiperSlide>
                    <SwiperSlide><CommunityCard {...community[4]} /></SwiperSlide>
                    <SwiperSlide><CommunityCard {...community[5]} /></SwiperSlide>
                </Swiper>
            </Box>
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: 4, backgroundColor: theme.palette.secondary.main, alignItems: 'center', px: 3, pb: 6, pt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant={'h4'}>
                    Our <span style={{ color: theme.palette.primary.main }}>offering</span>
                </Typography>
                <img alt={'Postcards'} src={'icons/landingpage/ouroffering/title.png'} style={{ marginLeft: 16, width: 77, display: belowMd ? 'none' : 'block' }} draggable={false} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', pt: 2, alignItems: { xs: 'center', md: 'stretch' }, justifyContent: 'center' }}>
                <OfferingCard src={'images/landingpage/single.png'} alt={'Normal'} text={'Send a postcard to your friend.'} heading={'Postcard'} price={3.59} />
                <OfferingCard src={'images/landingpage/replyback.png'} alt={'Reply back'} text={'Send a postcard to your friend and pay in advance for their reply back.'} heading={'Postcard + Reply back'} price={6.08} />
                <OfferingCard src={'images/landingpage/chain.png'} alt={'Chain'} text={'Everyone pays the postcard for the next one. Will you be able to create the longest chain?'} heading={'Chain'} price={6.08} />
            </Box>
            {/* TODO add media query for mobile bg asset transform */}
            <Box className={'ouroffering-bg'} />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', mb: 4, alignItems: 'center', p: 3 }}>
            <Typography variant={'h4'}>
                Scan the <span style={{ color: theme.palette.primary.main }}>QR code</span>
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', pt: 2, flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ width: '48%', display: { xs: 'none', md: 'block' } }} />
                    <Box sx={{ width: { xs: '100%', md: '52%' }, display: 'flex', flexDirection: 'row' }}>
                        {/* TODO rotate arrow on mobile */}
                        <Box sx={{ width: 80, mt: { xs: 2, md: 0 } }}>
                            <img alt={'Arrow right'} src={'images/landingpage/arrow.png'} width={80} draggable={false} />
                        </Box>
                        <Typography variant={'h6'} sx={{ color: '#666', fontFamily: 'Roboto' }}>see instantly on the map<br /> where the postcard comes from</Typography>
                    </Box>
                </Box>
                <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                    <img alt={'Postcard QR code'} src={'images/landingpage/postcard.png'} width={'100%'} draggable={false} />
                </Box>
            </Box>
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: { xs: 'calc(32px - 400px)', sm: 'calc(32px - 300px)' }, backgroundColor: theme.palette.secondary.main, alignItems: 'center', px: 3, pb: 6, pt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant={'h4'}>
                    Speak <span style={{ color: theme.palette.primary.main }}>about us</span>
                </Typography>
                <img alt={'Handshake'} src={'icons/landingpage/speakaboutus/title.svg'} style={{ marginLeft: 16, display: belowMd ? 'none' : 'block' }} draggable={false} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', pt: 6, pb: 4, px: { xs: 7, sm: 0 } }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: { xs: '100%', md: '50%' } }}>
                    <PartnerCard src={'images/landingpage/speakaboutus/image_3.png'} alt={'Partner 1'} />
                    <PartnerCard src={'images/landingpage/speakaboutus/image_4.png'} alt={'Partner 2'} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: { xs: '100%', md: '50%' } }}>
                    <PartnerCard src={'images/landingpage/speakaboutus/image_1.png'} alt={'Partner 3'} />
                    <PartnerCard src={'images/landingpage/speakaboutus/image_2.png'} alt={'Partner 4'} />
                </Box>
            </Box>
            {/* TODO add media query for mobile bg asset transform */}
            <Box className={'speakaboutus-bg'} />
            <Box  sx={{ width: '100%', height: { xs: 400, sm: 300}, zIndex: -10 }} />
        </Box>
    </PageWrapper>
}

export default LandingPage
