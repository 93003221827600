import {useContext, useLayoutEffect, useRef, useState} from "react";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import EditMessageBox from "./EditMessageBox";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {EditMessageProps} from "./message";

const EditMessageComponentSmall = ({
                                       message, font, color, setMessage
                                   }: EditMessageProps) => {
    const paragraphRef = useRef<HTMLParagraphElement>(null)

    const [editMessage, setEditMessage] = useState<boolean>(false)
    const [messageTooLong, setMessageTooLong] = useState<boolean>(false)
    const onClickEditMessageBox = () => setEditMessage(true)

    const theme = useTheme();

    const postcardWidth = useContext(PostcardWidthContext).width

    const fontSize = postcardWidth * 0.0292
    const lineHeight = fontSize * 1.40
    const maxLines = 7
    const paddingTop = postcardWidth * 0.0282 / 8

    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    useLayoutEffect(() => {
        const paragraph = paragraphRef.current
        if (paragraph) {
            const height = paragraph.scrollHeight
            setMessageTooLong(height - paddingTop >= maxLines * lineHeight)
        }
    }, [message, paragraphRef])

    return <>
        <Box sx={{ pl: '5.64%', width: '47.88%', pr: '5.64%' }}>
            {!message ? <EditMessageBox
                onClick={onClickEditMessageBox}
                isSmall={true}
                postcardWidth={postcardWidth}
            /> : <Typography ref={paragraphRef} sx={{
                    fontSize: fontSize,
                    fontFamily: font?.name ?? 'auto',
                    color: color ?? 'auto',
                    pt: paddingTop,
                    whiteSpace: 'pre-wrap',
                    textAlign: 'left',
                    width: '100%',
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }} onClick={onClickEditMessageBox}
            >
                {message}
            </Typography>}
        </Box>
        <Dialog
            open={editMessage}
            fullScreen
        >
            <DialogTitle>
                <Typography variant={'h6'}>Write your message</Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', height: 200, pt: 1 }}>
                    <TextField rows={7} multiline sx={{
                        width: 'calc(100% - 40px)',
                        height: '100%',
                        '& .MuiInputBase-root': {
                            height: '100% !important',
                            alignItems: 'start',
                            borderRadius: 2
                        },
                        '& textarea': {
                            fontFamily: font?.name ?? 'auto',
                            color: color ?? 'auto',
                            overflowY: 'hidden'
                        },
                        // @ts-ignore
                    }} value={message} onChange={e => {
                        setMessage(e.target.value)
                    }} label={'Message'} />
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 3, display: 'flex', flexDirection: 'column', width: '100%', gap: 2, alignItems: 'end' }}>
                {messageTooLong && <Typography>Your message is too long</Typography>}
                <Button disabled={messageTooLong} variant={"contained"} onClick={() => setEditMessage(false)} sx={{ width: '50%', ...buttonDefaults }}> Save </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default EditMessageComponentSmall