import React, {useEffect, useState} from "react";
import {Font, Postcard} from "../utils/postcard";
import {getFonts, getGreetingsPostcardsToExport, getPostcardsToExport} from "../api/api";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {getBackendEnvironment} from "../utils/utils";
import {Box, Button, Switch, Typography} from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
// @ts-ignore
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ExportPostcard from "../components/ExportPostcard";

const PDFs = () => {
    const [postcards, setPostcards] = useState<Postcard[]>([])
    const [isGreetings, setIsGreetings] = useState<boolean>(false)
    const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs())
    const { user } = useAuthenticator()

    const fetchPostcardsForPdfGeneration = async () => {
        if (fromDate)
            return isGreetings ? await getGreetingsPostcardsToExport(fromDate.toISOString().substring(0, 10)) : await getPostcardsToExport(fromDate.toISOString().substring(0, 10))
    }

    const fetchFonts = async () => {
        const _fonts = await getFonts()
        if (!_fonts) console.warn("Failed to load fonts")
        return _fonts
    }

    const loadFonts = async (fonts: Font[]) => {
        let fontFaces = []
        let i = 0, actualFonts = []
        for (const font of fonts) {
            try {
                const fontFace = new FontFace(font.name, `url(${font.file})`);// @ts-ignore
                (document as any).fonts.add(fontFace)
                fontFaces.push(fontFace)
            } catch (e) { }
        }
        for (const fontFace of fontFaces) {
            try {
                await fontFace.load()
                actualFonts.push(fonts[i])
            } catch (e) { }
            i++
        }
    }

    useEffect(() => {
        fetchFonts()
            .then((fonts) => {
                if (fonts) return loadFonts(fonts)
            })
    }, [])

    const fetch = async () => {
        fetchPostcardsForPdfGeneration()
            .then(result => {
                if (result) setPostcards(result)
            })
    }

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: 'block', '@media print': { display: 'none' }, p: 3 }}>
            <Typography variant={'h4'}>
                Memento printing
            </Typography>
            <Box sx={{ py: 2 }}>
                <Typography>
                    Printing Greetings?
                </Typography>
                <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple sx={{
                    width: 42,
                    height: 26,
                    padding: 0,
                    '& .MuiSwitch-track': {
                        backgroundColor: '#666666'
                    }
                }} checked={isGreetings} onChange={e => setIsGreetings(e.target.checked)} />
                <Box sx={{ pt: 2 }}>
                    <DatePicker
                        label={'Date from'}
                        // @ts-ignore
                        value={fromDate}
                        // @ts-ignore
                        onChange={value => {
                            setFromDate(value)
                        }}
                    />
                </Box>
            </Box>
            <Button variant={'contained'} onClick={fetch}>
                Fetch
            </Button>
        </Box>
        {user && user.username === (getBackendEnvironment() !== 'prod' ? 'viganoandrea02@gmail.com' : '868402d4-3c78-4531-9081-7825e618a7b9') ?
            postcards.map((postcard, i) => <ExportPostcard
                postcard={postcard}
                isGreetings={isGreetings}
                isLast={i === postcards.length - 1}
            />)
        : <>404 Page not found</>}
    </LocalizationProvider>
}

export default PDFs