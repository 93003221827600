import {
    createGreetingsOrder,
    createGreetingsPostcard,
    createOrderWithPostcardsAndOptionalPromoCode,
    createPostcard,
    IDObject,
    setPostcardReply
} from "../../../api/api";
import {v4 as uuid} from "uuid";
import {
    Address,
    CHAIN_SETTING,
    CommonPostcard,
    Font,
    GreetingsPostcard,
    Postcard,
    PostcardSettings,
    REPLY_BACK_SETTING
} from "../../../utils/postcard";
import {PostcardAppInitialState, PostcardAppState} from '../postcard-app/utils/PostcardAppInitialState'
import {
    AMBASSADOR_NEWS_LETTER_PROMO_CODE,
    AMBASSADOR_PROMO_CODE,
    DBAddress,
    DBGreetingsAddress,
    DBGreetingsPostcard,
    DBImage,
    DBPostcard,
    DBPostcardSettings, GREETINGS_20_PROMO_CODE, LAUNCH_PROMO_CODE, MEMENTALO_PROMO_CODE
} from "../../../api/apiUtils";
import {getBackendEnvironment, getWindowLocation, invoke} from "../../../utils/utils";
import {getImagesNumberForLayout, LAYOUT} from "../../../utils/layout";
import {AlertMessage} from "./imagesselection/alertsContext";
import {OverridableStringUnion} from "@mui/types";
import {AlertColor, AlertPropsColorOverrides} from "@mui/material/Alert/Alert";
import {AppState} from "../postcard-app/utils/postcardSlice";
import {GreetingsState} from "../greetings-postcard-app/utils/greetingsSlice";

export const DEFAULT_FONT = undefined
export const DEFAULT_COLOR = undefined
export const NO_FRAME = undefined
export const DEFAULT_LAYOUT = LAYOUT.SINGLE_IMAGE
export const NO_COORDINATE = -1000.00
export const DEFAULT_STATE: PostcardAppState = {
    initialState: PostcardAppInitialState.DEFAULT,
    body: {}
}
export const EMPTY_ADDRESS = (id: string): Address => ({
    id: id,
    fullName: '',
    streetAddress: '',
    city: '',
    zipCode: ''
})
const pecitaId = '4c6d8391-1a19-4871-880a-548eaa29eb63'
const namunPenId = '4d7f2c15-5d42-4073-9db4-e7b4c2076b92'
const segoeId = '4db3d718-d5fd-489d-8d0b-0c5f1b22fa80'
const dancingScriptId = 'd5c089fc-0152-4feb-9bcf-e4b2040e48f8'

const EMPTY_POSTCARD = {
    images: [],
    message: '',
    location: '',
    latitude: NO_COORDINATE,
    longitude: NO_COORDINATE,
    address: EMPTY_ADDRESS(uuid()),
    frame: NO_FRAME,
    layout: DEFAULT_LAYOUT,
    color: DEFAULT_COLOR,
    font: DEFAULT_FONT
}

const EMPTY_GREETINGS_POSTCARD = {
    images: [],
    message: '',
    location: '',
    latitude: NO_COORDINATE,
    longitude: NO_COORDINATE,
    addresses: [],
    frame: NO_FRAME,
    layout: DEFAULT_LAYOUT,
    color: DEFAULT_COLOR,
    font: DEFAULT_FONT
}

export enum STEPS {
    IMAGE, MESSAGE_AND_LOCATION, RECIPIENT_AND_SETTINGS, PERSONAL_ADDRESS, CART
}

export type PostcardSubmissionBundle = {
    postcard: Postcard
    state: PostcardAppInitialState.DEFAULT
} | {
    postcard: Postcard
    state: PostcardAppInitialState.REPLY |
        PostcardAppInitialState.REPLY_FREE |
        PostcardAppInitialState.CHAIN_CONTINUATION_FREE |
        PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID
    settingsToUpdate: string
    repliesToPostcardId: string
}

export type IDAndPrice = {
    id: string
    price: number
}

export type IDSPriceAndQuantity = {
    id: string
    price: number
    quantity: number
}

export type Location = {
    location: string
    latitude: number
    longitude: number
}

const getEmptyPostcard = (id: string): Postcard  => {
    const EMPTY_SETTINGS: PostcardSettings = {
        id: id,
        replyBackSetting: REPLY_BACK_SETTING.NONE,
        chainSetting: CHAIN_SETTING.NONE,
        replyAddress: null,
        postcardSettingsRepliesToId: null,
        postcardSettingsReplyId: undefined,
        chain: null,
        envelope: false,
        initialState: PostcardAppInitialState.DEFAULT
    }
    return {
        id: uuid() as string,
        settings: EMPTY_SETTINGS,
        ...EMPTY_POSTCARD
    }
}

const getEmptyGreetingsPostcard = (id: string): GreetingsPostcard => {
    return {
        id: id,
        ...EMPTY_GREETINGS_POSTCARD
    }
}

const processPostcardsCreation = async (postcardSubmissionBundles: PostcardSubmissionBundle[]): Promise<boolean> => {
    let isValid = true
    const postcardResponses = postcardSubmissionBundles.map(postcardSubmissionBundle => processSubmissionBundle(postcardSubmissionBundle))
    if (postcardSubmissionBundles[0].state !== PostcardAppInitialState.DEFAULT) {
        const settingsUpdateResult = await setPostcardAsReply(postcardSubmissionBundles[0])
        if (!settingsUpdateResult)
            console.warn('Unable to update settings')
            // TODO notify devs
    }
    await Promise.all(postcardResponses)
    for (const promise of postcardResponses) {
        const value = await promise
        if (!value)
            isValid = false
    }
    return isValid
}

const processPostcardCreation = async (submissionBundle: PostcardSubmissionBundle): Promise<false | IDObject> => {
    const creationResponse = await processSubmissionBundle(submissionBundle)
    if (creationResponse) {
        if (submissionBundle.state !== DEFAULT_STATE.initialState) {
            const settingsUpdateResponse = await setPostcardAsReply(submissionBundle)
            if (!settingsUpdateResponse)
                console.warn('Unable to update settings')
        }
        return creationResponse
    }
    return false
}

const processSubmissionBundle = async (postcardSubmissionBundle: PostcardSubmissionBundle): Promise<false | IDObject> => {
    const state = postcardSubmissionBundle.state
    const postcard = postcardSubmissionBundle.postcard
    const settings = postcard.settings
    const addressId = uuid()
    const replyAddressId = uuid()
    const dBPostcard: DBPostcard = {
        id: postcard.id,
        message: postcard.message,
        postcardRecipientAddressId: addressId,
        location: postcard.location,
        latitude: postcard.latitude,
        longitude: postcard.longitude,
        postcardFrameId: postcard.frame?.id,
        layout: postcard.layout,
        postcardFontId: postcard.font?.id,
        postcardMessageColorId: postcard.color?.id
    }
    const dbAddress: DBAddress = { ...postcard.address, id: addressId }
    const dBImages: DBImage[] = postcard.images.map(image => {
        // Refresh ids to prevent errors coming from caching / generate path here for the same reason
        return { ...image, id: uuid(), postcardImagesId: postcard.id }
    })
    const dbSettingsAddress: DBAddress | undefined = settings.replyAddress ? { ...settings.replyAddress, id: replyAddressId } : undefined
    const dBSettings: DBPostcardSettings = {
        id: uuid(),
        replyBackSetting: settings.replyBackSetting,
        postcardSettingsReplyAddressId: settings.replyAddress ? replyAddressId: undefined,
        chainSetting: settings.chainSetting,
        postcardSettingsPostcardId: postcard.id,
        envelope: settings.envelope,
        initialState: state
    }
    let result
    switch (state) {
        case PostcardAppInitialState.DEFAULT:
            const eventualChainId = uuid()
             result = await createPostcard(dBPostcard, dbAddress, dBImages, {
                 ...dBSettings,
                 postcardSettingsRepliesToId: settings.postcardSettingsRepliesToId ? settings.postcardSettingsRepliesToId : undefined,
                 chainPostcardsId: settings.chainSetting !== CHAIN_SETTING.NONE && settings.chain?.id ? eventualChainId : undefined,
                 postcardSettingsReplyAddressId: settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID ? replyAddressId : undefined
            },  settings.chainSetting !== CHAIN_SETTING.NONE ? { ...settings.chain, id: eventualChainId } : undefined,
                 settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID ? dbSettingsAddress : undefined)
            break
        case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
        case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
            result = await createPostcard(dBPostcard, dbAddress, dBImages, {
                ...dBSettings,
                postcardSettingsRepliesToId: postcardSubmissionBundle.repliesToPostcardId ? postcardSubmissionBundle.repliesToPostcardId : undefined,
                chainPostcardsId: settings.chain?.id,
                postcardSettingsReplyAddressId: undefined
            }, undefined, undefined)
            break
        default:
            result = await createPostcard(dBPostcard, dbAddress, dBImages, {
                ...dBSettings,
                postcardSettingsRepliesToId: postcardSubmissionBundle.repliesToPostcardId ? postcardSubmissionBundle.repliesToPostcardId : undefined,
                chainPostcardsId: undefined,
                postcardSettingsReplyAddressId: undefined
            }, undefined, undefined)
            break
    }
    return await result
}

const setPostcardAsReply = async (postcardSubmissionBundle: PostcardSubmissionBundle): Promise<boolean> => {
    if (postcardSubmissionBundle.state === PostcardAppInitialState.DEFAULT) return false
    const settingsToUpdate = postcardSubmissionBundle.settingsToUpdate
    const postcardId = postcardSubmissionBundle.postcard.id
    return await setPostcardReply(settingsToUpdate, postcardId)
}

const sendToCheckout = async (postcards: IDAndPrice[], _window: Window & any, promoCodeId: string | undefined): Promise<boolean> => {
    const environment = getBackendEnvironment()
    const response = await invoke(`createcheckoutsession-${environment}`, {
        items: postcards.map(postcard => ({
            price: postcard.price,
            quantity: 1
        })),
        location: getWindowLocation(_window),
        postcardIds: postcards.map(postcard => postcard.id),
        promoCodeId: promoCodeId,
        isGreetings: false
    })
    if (!response.url) {
        return false
    } else {
        _window.location.href = response.url
        return true
    }
}

const processOrderSubmission = async (ids: string[], promoCodeId: string | null): Promise<boolean> => {
    let isValid = true

    if (ids.length === 0) return false

    const result = await createOrderWithPostcardsAndOptionalPromoCode(ids, promoCodeId)
    if (!result)
        isValid = false

    return isValid
}

const processGreetingsOrderSubmission = async (id: string): Promise<boolean> => {
    const result = await createGreetingsOrder(id)
    return !!result
}

const shouldUpdateUserAddress = (userAddress: Address | null, replyAddress: Address | null): boolean => {
    return !!(!userAddress && replyAddress)
}

const getState = (state: any): PostcardAppState => {
    if (state)
        return {
            initialState: state.initialState,
            body: state.body
        }
    return DEFAULT_STATE
}

const getNextStep = (step: STEPS, initialState: PostcardAppInitialState, replyBack: boolean): STEPS => {
    if (step === STEPS.CART) return step

    if (step === STEPS.MESSAGE_AND_LOCATION) {
        switch (initialState) {
            case PostcardAppInitialState.REPLY_FREE:
                return STEPS.CART
            default:
                return STEPS.RECIPIENT_AND_SETTINGS
        }
    } else if (step === STEPS.RECIPIENT_AND_SETTINGS) {
        // NB: replyBack can be true only in DEFAULT state
        if (replyBack) return STEPS.PERSONAL_ADDRESS
        else return STEPS.CART
    } else return step + 1
}

const getGreetingsNextStep = (step: STEPS): STEPS => {
    if (step === STEPS.CART) return step
    else if (step === STEPS.RECIPIENT_AND_SETTINGS) return STEPS.CART
    else return step + 1
}

const getPreviousStep = (step: STEPS, initialState: PostcardAppInitialState, replyBack: boolean): STEPS => {
    if (step === STEPS.IMAGE) return step

    if (step === STEPS.CART) {
        switch (initialState) {
            case PostcardAppInitialState.REPLY_FREE:
                return STEPS.MESSAGE_AND_LOCATION
            case PostcardAppInitialState.DEFAULT:
                // NB: replyBack can be true only in DEFAULT state
                if (replyBack) return STEPS.PERSONAL_ADDRESS
                else return STEPS.RECIPIENT_AND_SETTINGS
            default:
                return STEPS.RECIPIENT_AND_SETTINGS
        }
    } else return step - 1
}

const getGreetingsPreviousStep = (step: STEPS): STEPS => {
    if (step === STEPS.IMAGE) return step
    else if (step === STEPS.CART) return STEPS.RECIPIENT_AND_SETTINGS
    else return step - 1
}

const getPrice = (state: PostcardAppInitialState) => {
    switch (state) {
        case PostcardAppInitialState.DEFAULT:
            return 3.59
        case PostcardAppInitialState.REPLY_FREE:
            // for completeness
            return 0.0
        case PostcardAppInitialState.REPLY:
            return 3.59
        case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
            return 0.0
        case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
            return 3.59
    }
}

const preparePostcardForSubmission = (postcard: Postcard, state: PostcardAppState): PostcardSubmissionBundle => {
    switch (state.initialState) {
        case PostcardAppInitialState.DEFAULT:
            return {
                postcard: postcard,
                state: state.initialState
            }
        case PostcardAppInitialState.REPLY:
        case PostcardAppInitialState.REPLY_FREE:
        case PostcardAppInitialState.CHAIN_CONTINUATION_FREE:
        case PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID:
            return {
                postcard: postcard,
                state: state.initialState,
                settingsToUpdate: state.body.settingsToUpdate,
                repliesToPostcardId: state.body.repliesToPostcardId
            }
    }
}

const getMissingInformationForCommonStep = (
    step: STEPS,
    postcard: CommonPostcard
): string => {
    switch (step) {
        case STEPS.IMAGE:
            const imagesNumber = getImagesNumberForLayout(postcard.layout ? postcard.layout : LAYOUT.SINGLE_IMAGE)
            if (imagesNumber === 1 && postcard.images.length === 0)
                return 'Please add an image for your postcard'
            else if (imagesNumber !== postcard.images.length)
                return 'Please add all images for your postcard'
            return ''

        case STEPS.MESSAGE_AND_LOCATION:
            if (!postcard.message)
                return 'Please add a message to your postcard'
            else if (!postcard.location)
                return 'Please select the location of where the picture was taken'
            else if (postcard.latitude === NO_COORDINATE
                || postcard.longitude === NO_COORDINATE)
                return 'Please select a location from the available ones'
            return ''

        case STEPS.CART:
            // No information to be set on cart step
            return ''
    }
    return ''
}

const getMissingInformationForStep = (
    step: STEPS,
    postcard: Postcard
): string => {
    switch (step) {
        case STEPS.IMAGE:
        case STEPS.MESSAGE_AND_LOCATION:
        case STEPS.CART:
            return getMissingInformationForCommonStep(step, postcard)

        case STEPS.RECIPIENT_AND_SETTINGS:
            if (!postcard.address.streetAddress ||
                !postcard.address.fullName ||
                !postcard.address.city ||
                !postcard.address.zipCode)
                return 'Please fill all address information'
            else if (getChainStartOrContinuation(postcard) && !getChainName(postcard))
                return 'Please add a name to your chain'
            return ''

        case STEPS.PERSONAL_ADDRESS:
            const replyAddress = getReplyAddress(postcard)
            if (!replyAddress?.streetAddress ||
                !replyAddress?.fullName ||
                !replyAddress?.city ||
                !replyAddress?.zipCode)
                return 'Please fill all address information'
            return ''
    }
}

const getNextButtonDisabled = (
    step: STEPS,
    isLoadingSubmission: boolean,
    user: boolean,
    postcard: Postcard
): boolean => {
    if (isLoadingSubmission) return true
    switch (step) {
        case STEPS.IMAGE:
            const imagesNumber = getImagesNumberForLayout(postcard.layout ? postcard.layout : LAYOUT.SINGLE_IMAGE)
            let _images = []
            for (let i = 0; i < imagesNumber; i++) {
                _images = postcard.images.filter(image => image.index === i)
                if (_images.length !== 1) return true
            }
            return false

        case STEPS.PERSONAL_ADDRESS:
            if (!user) return true
            return !!getMissingInformationForStep(step, postcard)

        case STEPS.CART:
        case STEPS.MESSAGE_AND_LOCATION:
        case STEPS.RECIPIENT_AND_SETTINGS:
            return !!getMissingInformationForStep(step, postcard)
    }
}

const canGoToStep = (
    step: STEPS,
    initialState: PostcardAppInitialState,
    replyBack: boolean,
    isLoadingSubmission: boolean,
    user: boolean,
    postcard: Postcard
): boolean => {
    // Check that we can go to every step up to given step
    let _step = 0
    let nextStep
    while (_step < step) {
        nextStep = getNextStep(_step, initialState, replyBack)
        if (getNextButtonDisabled(_step, isLoadingSubmission, user, postcard))
            return false
        _step = nextStep
    }
    return true
}

const canGoToGreetingsStep = (
    step: STEPS,
    isLoadingSubmission: boolean,
    postcard: GreetingsPostcard,
): boolean => {
    // Check that we can go to every step up to given step
    let _step = 0
    let nextStep
    while (_step < step) {
        nextStep = getGreetingsNextStep(_step)
        if (getGreetingsNextButtonDisabled(_step, isLoadingSubmission, postcard))
            return false
        _step = nextStep
    }
    return true
}

const getGreetingsMissingInformationForStep = (
    step: STEPS,
    postcard: GreetingsPostcard
): string => {
    switch (step) {
        case STEPS.IMAGE:
        case STEPS.MESSAGE_AND_LOCATION:
        case STEPS.CART:
            return getMissingInformationForCommonStep(step, postcard)
        case STEPS.RECIPIENT_AND_SETTINGS:
            if (postcard.addresses.length === 0)
                return 'Please insert at least one address'
            return ''
    }
    return ''
}

const getGreetingsNextButtonDisabled = (
    step: STEPS,
    isLoadingSubmission: boolean,
    postcard: GreetingsPostcard
): boolean => {
    if (isLoadingSubmission) return true

    switch (step) {
        case STEPS.IMAGE:
            const imagesNumber = getImagesNumberForLayout(postcard.layout ? postcard.layout : LAYOUT.SINGLE_IMAGE)
            let _images = []
            for (let i = 0; i < imagesNumber; i++) {
                _images = postcard.images.filter(image => image.index === i)
                if (_images.length !== 1) return true
            }
            return false

        case STEPS.MESSAGE_AND_LOCATION:
        case STEPS.RECIPIENT_AND_SETTINGS:
        case STEPS.CART:
            return !!getGreetingsMissingInformationForStep(step, postcard)
    }
    return false
}

const _getPriceWithoutEnvelope = (setting: PostcardSettings, initialState: PostcardAppInitialState): number => {
    if (initialState === PostcardAppInitialState.REPLY_FREE) return 0.00
    if (initialState === PostcardAppInitialState.CHAIN_CONTINUATION_FREE) {
        if (setting.chainSetting === CHAIN_SETTING.CHAIN_PAID) return 2.49
        else return 0.00
    }
    if (initialState === PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID) {
        if (setting.chainSetting === CHAIN_SETTING.CHAIN_PAID) return 6.08
        else return 3.59
    }
    if (initialState === PostcardAppInitialState.DEFAULT) {
        if (setting.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID) return 6.08
        if (setting.chainSetting === CHAIN_SETTING.CHAIN_PAID) return 6.08
        else return 3.59
    }
    return 3.59
}

const getPriceFromSettingAndInitialState = (setting: PostcardSettings, initialState: PostcardAppInitialState): number => {
    const envelope = setting.envelope
    return _getPriceWithoutEnvelope(setting, initialState) + (envelope ? 0.99 : 0)
}

// TODO Make all of this dynamic from DB
const sortFonts = (fonts: Font[]): Font[] => {
    const font0 = fonts.find(font => font.id === dancingScriptId)
    const font1 = fonts.find(font => font.id === pecitaId)
    const font2 = fonts.find(font => font.id === segoeId)
    const font3 = fonts.find(font => font.id === namunPenId)

    const result = []
    if (font0) result.push(font0)
    if (font1) result.push(font1)
    if (font2) result.push(font2)
    if (font3) result.push(font3)
    return result
}

const sendToGreetingsCheckout = async (postcard: IDSPriceAndQuantity, _window: Window & any, promotion: number): Promise<boolean> => {
    const GREETINGS_5_PROMO_CODE_ID = 'f2e25821-bed5-44ae-acb4-f645037ff145'
    const GREETINGS_10_PROMO_CODE_ID = 'b97d823b-f617-4887-bb3d-7f93615e3811'
    const GREETINGS_15_PROMO_CODE_ID = '7e0bd54a-baae-4fc6-a6e1-e416794ceaf1'
    const GREETINGS_20_PROMO_CODE_ID = 'fb1d4dae-d48b-4b04-a3d3-8bc75f22e721'

    let promoCodeId
    switch (promotion) {
        case 0.95:
            promoCodeId = GREETINGS_5_PROMO_CODE_ID
            break
        case 0.90:
            promoCodeId = GREETINGS_10_PROMO_CODE_ID
            break
        case 0.85:
            promoCodeId = GREETINGS_15_PROMO_CODE_ID
            break
        case 0.80:
            promoCodeId = GREETINGS_20_PROMO_CODE_ID
            break
        default:
            promoCodeId = undefined
    }

    const environment = getBackendEnvironment()
    const response = await invoke(`createcheckoutsession-${environment}`, {
        items: [{
            price: postcard.price,
            quantity: postcard.quantity
        }],
        location: getWindowLocation(_window),
        postcardIds: [postcard.id],
        promoCodeId: promoCodeId,
        isGreetings: true
    })
    if (!response.url) {
        console.log("Response: ", response)
        return false
    } else {
        _window.location.href = response.url
        return true
    }
}

const processGreetingsSubmission = async (
    context: GreetingsPostcard
): Promise<false | IDObject> => {
    const postcardId = uuid()
    const postcard: DBGreetingsPostcard = {
        id: postcardId,
        message: context.message,
        location: context.location,
        latitude: context.latitude,
        longitude: context.longitude,
        layout: context.layout,
        greetingsPostcardFrameId: context.frame?.id,
        greetingsPostcardFontId: context.font?.id,
        greetingsPostcardMessageColorId: context.color?.id
    }

    const addresses: DBGreetingsAddress[] = context.addresses.map(address => ({
        ...address,
        id: uuid(),
        greetingsPostcardRecipientAddressesId: postcardId
    }))

    const images: DBImage[] = context.images.map(image =>  ({
        ...image,
        id: uuid(),
        greetingsPostcardImagesId: postcardId
    }))

    return await createGreetingsPostcard(postcard, addresses, images)
}

const getGreetingsPromotion = (quantity: number): number => {
    if (quantity <= 50) {
        return 1.0
    } else {
        return 0.95
    }
}

const getAlert = (text: string, severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>): AlertMessage => {
    return { text, id: uuid(), severity }
}

const getLocation = <T extends {location: string, latitude: number, longitude: number}, >(object: T): Location => {
    return { location: object.location, latitude: object.latitude, longitude: object.longitude }
}
const getReplyBack = <T extends { settings: PostcardSettings }, >(object: T): boolean => {
    return object.settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID
}
const getChainStartOrContinuation = <T extends { settings: PostcardSettings }, >(object: T): boolean => {
    return object.settings.chainSetting === CHAIN_SETTING.CHAIN_PAID
}
const getChainName = <T extends { settings: PostcardSettings }, >(object: T): string => {
    return object.settings.chain?.name ?? ''
}
const getReplyAddress = <T extends { settings: PostcardSettings }, >(object: T): Address => {
    const address = object.settings.replyAddress
    if (!address) {
        return {
            id: uuid(),
            fullName: '',
            city: '',
            zipCode: '',
            streetAddress: ''
        }
    } else {
        return address
    }
}

const withLocation = <T extends {location: string, latitude: number, longitude: number}, >(state: T): Omit<T, 'latitude' | 'longitude' | 'location'> & { location: Location } => {
    return {
        ...state,
        location: getLocation(state),
        latitude: undefined,
        longitude: undefined
    }
}
const withBooleanSettings = <T extends { settings?: PostcardSettings }, >(state: T): T & { replyBack: boolean, chainStartOrContinuation: boolean, chainName: string } => {
    // Designed to work with both Postcards and GreetingsPostcards to maintain unified codebase
    return state.settings ? {
        ...state,
        replyBack: getReplyBack(state as { settings: PostcardSettings }),
        chainStartOrContinuation: getChainStartOrContinuation(state as { settings: PostcardSettings }),
        chainName: getChainName(state as { settings: PostcardSettings })
    } : {
        ...state,
        replyBack: false,
        chainStartOrContinuation: false,
        chainName: ''
    }
}
const withReplyAddress = <T extends { settings: PostcardSettings }, >(state: T): T & { replyAddress: Address } => {
    return {
        ...state,
        replyAddress: getReplyAddress(state)
    }
}
const withEnvelope = <T extends { settings: PostcardSettings }, >(state: T): T & { envelope: boolean } => {
    return {
        ...state,
        envelope: state.settings.envelope
    }
}

const getNextStepData = (state: AppState | GreetingsState, isGreetings: boolean) => {
    const currentStep = state.step
    const { replyBack } = withBooleanSettings(state.postcard as Postcard)
    const initialSettings: PostcardAppInitialState = isGreetings ? PostcardAppInitialState.DEFAULT : (state as AppState).initialState.initialState

    return { currentStep, replyBack, initialSettings }
}

const getImagesLengthForLayout = (layout: LAYOUT): number => {
    switch (layout) {
        case LAYOUT.SINGLE_IMAGE:
            return 1
        case LAYOUT.TWO_IMAGES_SPLIT_VERTICALLY:
        case LAYOUT.TWO_IMAGES_SPLIT_HORIZONTALLY:
            return 2
        case LAYOUT.FOUR_IMAGES_EVEN_GRID:
            return 4
        default:
            return 0
    }
}

const getPromotionFromPromoCode = (isValid: boolean, promoCode: string): number => {
    if (!isValid) return 1.0

    let promotion
    switch (promoCode) {
        case GREETINGS_20_PROMO_CODE:
            promotion = 0.80
            break
        case LAUNCH_PROMO_CODE:
            promotion = 0.50
            break
        case AMBASSADOR_PROMO_CODE:
        case MEMENTALO_PROMO_CODE:
        case AMBASSADOR_NEWS_LETTER_PROMO_CODE:
            promotion = 0.0
            break
        default:
            promotion = 0.5
            break
    }
    return promotion
}

export {
    getEmptyPostcard,
    getEmptyGreetingsPostcard,
    processPostcardsCreation,
    processPostcardCreation,
    sendToCheckout,
    processOrderSubmission,
    processGreetingsOrderSubmission,
    shouldUpdateUserAddress,
    getState,
    getNextStep,
    getGreetingsNextStep,
    getPreviousStep,
    getGreetingsPreviousStep,
    getPrice,
    preparePostcardForSubmission,
    getMissingInformationForStep,
    getNextButtonDisabled,
    getMissingInformationForCommonStep,
    getGreetingsMissingInformationForStep,
    getGreetingsNextButtonDisabled,
    getPriceFromSettingAndInitialState,
    sortFonts,
    sendToGreetingsCheckout,
    processGreetingsSubmission,
    getGreetingsPromotion,
    getAlert,
    getReplyBack,
    withLocation,
    withBooleanSettings,
    withReplyAddress,
    withEnvelope,
    getReplyAddress,
    canGoToStep,
    canGoToGreetingsStep,
    getNextStepData,
    getImagesLengthForLayout,
    getPromotionFromPromoCode
}