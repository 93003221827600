import {STEPS} from "../postcardAppUtils";
import {Box, Button} from "@mui/material";
import React, {useContext} from "react";
import IsGreetingsContext from "../imagesselection/isGreetingsContext";
import {RootState, useAppDispatch, useAppSelector} from "../../../../utils/store";
import useActions from "../utils/useActions";

const PreviousButton = () => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const stateSelector = (state: RootState) =>
        isGreetings ? state.greetings : state.app

    const {
        step, isLoadingSubmission
    } = useAppSelector(stateSelector)

    const dispatch = useAppDispatch()

    const {
        processPreviousStepRequest
    } = useActions(isGreetings)

    return <Box sx={{ display: step === STEPS.IMAGE ? 'none' : 'block' }}>
        <Button disabled={isLoadingSubmission} onClick={() => dispatch(processPreviousStepRequest())} variant={'contained'} color={'secondary'} className={'no-focus'}>
            Back
        </Button>
    </Box>
}

export default PreviousButton