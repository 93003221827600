import {Box, Switch, Typography} from "@mui/material";
import React from "react";

type SettingProps = {
    title: string
    message: JSX.Element
    price: string
    value: boolean,
    setValue: (value: boolean) => void
    hideLine?: boolean
}

const Setting = ({ title, message, price, value, setValue, hideLine }: SettingProps) => {
    return <Box sx={{ width: '100%', my: 2 }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple sx={{
                    width: 42,
                    height: 26,
                    padding: 0,
                    '& .MuiSwitch-track': {
                        backgroundColor: '#666666'
                    }
                }} checked={value} onChange={e => setValue(e.target.checked)} />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', ml: 2, '& > p': { textAlign: 'left' } }}>
                    <Typography variant={'h6'}>{title}</Typography>
                    <Typography variant={'body1'}>{message}</Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant={'h6'} sx={{ color: value ? 'auto' : '#666666' }}>{price}</Typography>
            </Box>
        </Box>

        <Box sx={{ width: '100%', backgroundColor: hideLine ? 'none' : '#666666', height: '1px', mt: 2 }} />
    </Box>
}

export default Setting