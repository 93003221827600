import {Box} from "@mui/material";

// TODO fix logo alignment
const LogoBox = ({ postcardWidth }: { postcardWidth: number }) => {
    return <Box sx={{ width: '4.85%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', pr: '2.82%' }}>
        <Box sx={{ width: '1px', height: '16.64%', backgroundColor: 'black', mb: postcardWidth * 0.0282 / 8, mt: postcardWidth * 0.0141 / 8  }} />
        <Box sx={{ height: '44.55%', aspectRatio: 26/101 }}>
            <img height={'100%'} style={{ objectFit: 'contain', transform: 'translateX(12%)' }} alt={'Logo'} src={'images/postcard/logo_black_extended.png'} />
        </Box>
        <Box sx={{ width: '1px', height: '16.64%', backgroundColor: 'black', mt: postcardWidth * 0.0282 / 8 }} />
    </Box>
}

export default LogoBox