import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import PostcardAppPage from "../pages/PostcardAppPage";
import ProfilePage from "../pages/ProfilePage";
import AuthenticationPage from "../pages/AuthenticationPage";
import PasswordChangePage from "../pages/PasswordChangePage";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentCancel from "../pages/PaymentCancel";
import GreetingsPaymentSuccess from "../pages/GreetingsPaymentSuccess";
import GreetingsBankTransferSuccess from "../pages/GreetingsBankTransferSuccess";
import GreetingsPaymentCancel from "../pages/GreetingsPaymentCancel";
import PostcardScan from "../pages/PostcardScan";
import GreetingsPostcardScan from "../pages/GreetingsPostcardScan";
import ComingSoon from "../pages/ComingSoon";
import Ambassador from "../pages/Ambassador";
import AmbassadorContact from "../pages/AmbassadorContact";
import FAQ from "../pages/FAQ";
import Greetings from "../pages/Greetings";
import GreetingsAppPage from "../pages/GreetingsAppPage";
import Cart from "../pages/Cart";
import Contact from "../pages/Contact";
import TermsAndConditions from "../pages/compliance/TermsAndConditions";
import PrivacyPolicy from "../pages/compliance/PrivacyPolicy";
import CookiePolicy from "../pages/compliance/CookiePolicy";
import PDFs from "../pages/PDFs";
import NoPage from "../pages/NoPage";
import {getFontsFetcher} from "../components/app/common/utils/fontsFetcher";
import fetchUser from "../components/auth/fetchUser";
import fetchCart from "../components/app/common/utils/fetchCart";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' loader={fetchUser}>
            <Route index element={<LandingPage />} />
            <Route path={'app'} element={<PostcardAppPage />} loader={getFontsFetcher(false)} />
            <Route path={'profile'} element={<ProfilePage />} />
            <Route path={'auth'} element={<AuthenticationPage />} />
            <Route path={'password-change'} element={<PasswordChangePage />} />
            <Route path={'payment-success'} element={<PaymentSuccess />} />
            <Route path={'payment-cancel'} element={<PaymentCancel />}/>
            <Route path={'greetings-payment-success'} element={<GreetingsPaymentSuccess />} />
            <Route path={'greetings-bk-success'} element={<GreetingsBankTransferSuccess />} />
            <Route path={'greetings-payment-cancel'} element={<GreetingsPaymentCancel />}/>
            <Route path={'postcard-scan'} element={<PostcardScan />} />
            <Route path={'greetings-postcard-scan'} element={<GreetingsPostcardScan />} />
            <Route path={'coming-soon'} element={<ComingSoon />}/>
            <Route path={'ambassador'} element={<Ambassador />} />
            <Route path={'ambassador-contact'} element={<AmbassadorContact />} />
            <Route path={'faq'} element={<FAQ />} />
            <Route path={'greetings'} element={<Greetings />} />
            <Route path={'greetings-app'} element={<GreetingsAppPage />} loader={getFontsFetcher(true)} />
            <Route path={'cart'} element={<Cart />} loader={fetchCart} />
            <Route path={'contact'} element={<Contact />} />
            <Route path={'terms-and-conditions'} element={<TermsAndConditions />} />
            <Route path={'privacy-policy'} element={<PrivacyPolicy />} />
            <Route path={'cookie-policy'} element={<CookiePolicy />} />
            <Route path={'mem-staff-pdfs-temp'} element={<PDFs />}/>
            <Route path={'*'} element={<NoPage />} />
        </Route>
    )
)

export default router