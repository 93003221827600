import {useNavigate, useSearchParams} from "react-router-dom";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import {useCallback, useEffect, useState} from "react";
import PageWrapper from "../components/PageWrapper";
import {
    addQRCodeScan,
    getChainProgression,
    getFonts,
    getPostcard,
    getUserName,
    setPostcardRecipientId
} from "../api/api";
import PostcardRecap from "../components/PostcardRecap";
import {Chain, CHAIN_SETTING, Font, Postcard, REPLY_BACK_SETTING} from "../utils/postcard";
import {PostcardAppInitialState, PostcardAppState} from "../components/app/postcard-app/utils/PostcardAppInitialState";
import {ChainProgressionPostcard} from "../api/apiUtils";
// @ts-ignore
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import {Box, Typography, Button, CircularProgress} from "@mui/material";
import {CacheManager} from "../utils/usePersistState";

const PostcardScan = () => {
    const [searchParams] = useSearchParams()
    const {user} = useAuthenticator()
    const navigate = useNavigate()
    const [postcard, setPostcard] = useState<undefined | Postcard>(undefined)
    const [senderName, setSenderName] = useState<string>('')
    const [isLoadingPostcard, setIsLoadingPostcard] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [addPostcardErrorMessage, setAddPostcardErrorMessage] = useState<string>('')

    const [chainProgression, setChainProgression] = useState<ChainProgressionPostcard[]>([])

    const id: string = searchParams.get('id') as string

    let time = 0

    const fetchFonts = async () => {
        return await getFonts()
    }

    const loadFonts = async (fonts: Font[]) => {
        let fontFaces = []
        let i = 0, actualFonts = []
        for (const font of fonts) {
            try {
                const fontFace = await new FontFace(font.name, `url(${font.file})`);// @ts-ignore
                (document as any).fonts.add(fontFace)
                fontFaces.push(fontFace)
            } catch (e) { }
        }
        for (const fontFace of fontFaces) {
            try {
                await fontFace.load()
                actualFonts.push(fonts[i])
            } catch (e) { }
            i++
        }
    }

    const recordScan = useCallback(async () => {
        console.log("Adding scan record")
        return addQRCodeScan(id)
    }, [id])

    const fetchPostcard = useCallback(async () => {
        return getPostcard(id)
    }, [id])

    const getPostcardChainProgression = async (chain: Chain) => {
        return getChainProgression(chain.id)
    }

    const getSenderName = async (userId: string) => {
        const sender = await getUserName(userId)
        console.log("SENDER: ", sender)
        if (sender) setSenderName(sender)
    }

    const handleAddToYourPostcards = async () => {
        // Redundant atp
        if (postcard?.recipientId) {
            setAddPostcardErrorMessage('Postcard already has a recipient')
        } else {
            // @ts-ignore
            const result = await setPostcardRecipientId(postcard.id, user.userId)
            if (!result) setAddPostcardErrorMessage('Unable to add postcard')
            else if (postcard && user) setPostcard({ ...postcard, recipientId: user.userId } )
        }
    }

    const handleReply = async () => {
        // Clear cache
        await CacheManager.clear()
        if (postcard) {
            const state: PostcardAppState = {
                initialState: PostcardAppInitialState.REPLY,
                body: {
                    repliesToPostcardId: postcard.id,
                    settingsToUpdate: postcard.settings.id
                }
            }
            navigate('/app', {state: state})
        } else {
            setErrorMessage('Unable to get postcard data')
        }
    }

    const handleReplyWithAFreePostcard = async () => {
        // Clear cache
        await CacheManager.clear()
        if (postcard && postcard.settings.replyAddress) {
            const state: PostcardAppState  = {
                initialState: PostcardAppInitialState.REPLY_FREE,
                body: {
                    addressToPrefill: postcard.settings.replyAddress,
                    repliesToPostcardId: postcard.id,
                    settingsToUpdate: postcard.settings.id
                }
            }
            navigate('/app', {state: state})
        } else {
            setErrorMessage(postcard ? 'Unable to get recipient address' : 'Unable to get postcard data')
        }
    }

    const handleContinueTheChain = async () => {
        // Clear cache
        await CacheManager.clear()
        if (postcard && postcard.settings.chain) {
            let state: PostcardAppState
            if (postcard.settings.chainSetting === CHAIN_SETTING.CHAIN)
                state = {
                    initialState: PostcardAppInitialState.CHAIN_CONTINUATION_TO_BE_PAID,
                    body: {
                        repliesToPostcardId: postcard.id,
                        settingsToUpdate: postcard.settings.id,
                        chain: postcard.settings.chain
                    }
                }
            else
                state = {
                    initialState: PostcardAppInitialState.CHAIN_CONTINUATION_FREE,
                    body: {
                        repliesToPostcardId: postcard.id,
                        settingsToUpdate: postcard.settings.id,
                        chain: postcard.settings.chain
                    }
                }
            navigate('/app', {state: state})
        } else {
            setErrorMessage('Unable to get postcard data')
        }
    }

    useEffect(() => {
        if (user && time === 0) {
            // Bypass duplicate execution bug after QR code scan
            // eslint-disable-next-line
            time = Date.now()
            recordScan()
                .then(result => {
                    if (!result) {
                        // TODO Notify devs
                    } else {

                    }
                })
        }
    }, [recordScan, user])

    useEffect(() => {
        fetchFonts()
            .then((fonts) => {
                if (fonts) return loadFonts(fonts)
            })
        fetchPostcard()
            .then(result => {
                if (!result) {
                    setErrorMessage('Unable to fetch postcard')
                }
                else {
                    console.log("IMAGES: ",result.images)
                    console.log("OWNER: ", result.owner)
                    if (result.owner)
                        getSenderName(result.owner).then(ignored => { })
                    if (result.settings.chain) {
                        getPostcardChainProgression(result.settings.chain)
                            .then(result => {
                                if (!result) {
                                    // TODO Notify devs
                                } else {
                                    setChainProgression(result)
                                }
                            })
                    }
                    setPostcard(result)
                }
                setIsLoadingPostcard(false)
            })
    }, [fetchPostcard, user])

    const isDefaultPostcard = () => postcard?.settings.replyBackSetting === REPLY_BACK_SETTING.NONE &&
        postcard?.settings.chainSetting === CHAIN_SETTING.NONE
    const isReplyPaid = () => postcard?.settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID
    const isReply = () => postcard?.settings.replyBackSetting === REPLY_BACK_SETTING.REPLY
    const isChain = () => postcard?.settings.chainSetting === CHAIN_SETTING.CHAIN_PAID
        || postcard?.settings.chainSetting === CHAIN_SETTING.CHAIN
    const isChainPaid = () => postcard?.settings.chainSetting === CHAIN_SETTING.CHAIN
    const hasReplyAlreadySet = () => !!(postcard?.settings.postcardSettingsReplyId)
    const hasRecipientAlreadySet = () => !!(postcard?.recipientId)
    const isThisPostcardClaimedByCurrentUser = () => hasRecipientAlreadySet() && user && postcard?.recipientId === user.userId
    console.log("Aoo: ", hasRecipientAlreadySet())
    return <PageWrapper>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column', px: 1 }}>
            {isLoadingPostcard && <CircularProgress sx={{ '& svg': { transform: 'scale(3) !important' }, marginBottom: 16 }} />}
            {isLoadingPostcard ?
                <Typography variant={'h4'}>Loading...</Typography> :
                (errorMessage === '' && postcard !== undefined ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, width: '100%', py: 3 }}>
                        <Box sx={{ px: 2, textAlign: 'center' }}>
                            {user ? (senderName && <Typography variant={'h4'} sx={{ pb: 1 }}>{senderName} sent you a postcard</Typography>) : <Typography variant={'h4'}>Someone sent you a postcard</Typography>}
                            {user ? <>{isReplyPaid() && <Typography>
                                He is waiting for a reply with one of your memories...
                                Don’t worry, <span style={{fontWeight: 'bold'}}>he has already paid</span> the postcard for
                                you.
                            </Typography>}
                                {isChain() && <Typography>
                                    He invited you to continue the “Surfers” chain around the world. What are you waiting
                                    for? {isChainPaid() &&
                                    <span>He <span
                                        style={{fontWeight: 'bold'}}>has already paid for you!</span></span>}
                                </Typography>}</> : <Typography>Log in to know more details!</Typography>}
                        </Box>

                        <Box sx={{ pt: 2 }} />
                        <Authenticator>
                            <PostcardRecap
                                postcard={postcard}
                                handleAddToYourPostcards={handleAddToYourPostcards}
                                isPostcardAlreadyClaimed={hasRecipientAlreadySet()}
                                isPostcardClaimedByCurrentUser={isThisPostcardClaimedByCurrentUser()}
                            />
                        </Authenticator>
                        {user && user.userId && postcard && <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                            {!hasReplyAlreadySet() && (isDefaultPostcard() ? <Button variant={'contained'} onClick={handleReply}>
                                    Reply
                                </Button> :
                                isReplyPaid() ? <Button variant={'contained'} onClick={handleReplyWithAFreePostcard}>
                                        Reply with a free postcard
                                    </Button> :
                                    isChain() ? <Button variant={'contained'} onClick={handleContinueTheChain}>
                                            Continue the chain {postcard.settings.chain?.name.toUpperCase()}
                                        </Button> :
                                        isReply() ?
                                            <Button variant={'contained'} onClick={handleReply}>
                                                Reply again
                                            </Button> : null)}
                            <Typography>{addPostcardErrorMessage}</Typography>
                            {/*{isChain() && chainProgression.length !== 0 && <div>
                                <Typography>Chain progression</Typography>
                                {chainProgression.map((postcard, i) =>
                                    <Box key={postcard.id} style={{ margin: 16, padding: 8, backgroundColor: 'lightgray', maxWidth: 200 }}>
                                        <Typography>Postcard number {i + 1}</Typography>
                                        <Typography>{postcard.location}</Typography>
                                        <Typography>{postcard.message}</Typography>
                                    </Box>)
                                }
                            </div>}*/}
                        </Box>}
                        {user && hasReplyAlreadySet() && <Box>
                            <Typography>This postcard already has a reply</Typography>
                        </Box>}
                    </Box> :
                    <Typography variant={'h4'}>{errorMessage}</Typography>)
            }
        </Box>
    </PageWrapper>
}

export default PostcardScan
