import {Box, Typography, useTheme} from "@mui/material";

const PriceBox = ({ price }: { price: number }) => {
    const theme = useTheme()

    return <Box sx={{
        background: theme.palette.primary.light,
        borderRadius: '10px',
        p: 1,
    }}>
        <Typography variant={'body1'}>
            <span style={{ fontSize: '20px', color: theme.palette.primary.main }}>{price}€</span>
        </Typography>
    </Box>
}

export default PriceBox