import {Box, Typography, useTheme} from "@mui/material";

type EditMessageBoxProps = {
    onClick: () => void
    postcardWidth: number
    isSmall: boolean
}

const EditMessageBox = ({ onClick, postcardWidth, isSmall }: EditMessageBoxProps) => {
    const theme = useTheme();

    return <Box sx={{
        width: isSmall ? 'calc(100% - 20px)' : 'calc(100% - 40px)',
        height: '100%',
        border: '1.5px solid ' + theme.palette.primary.main,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mr: 5,
        gap: '30px',
        backgroundColor: theme.palette.primary.light,
        '&:hover': {
            boxShadow: '0px 0px 4.2963px rgba(0, 0, 0, 0.25)',
            cursor: 'pointer'
        }
    }} onClick={onClick}>
        <img src={'icons/app/messagestep/plus_sm.svg'} alt={'+'} height={32} width={32} />
        <Typography sx={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: postcardWidth * 0.0220,
            color: theme.palette.primary.main,
        }}>WRITE YOUR MESSAGE</Typography>
    </Box>
}

export default EditMessageBox