export enum LAYOUT {
    SINGLE_IMAGE = "SINGLE_IMAGE",
    TWO_IMAGES_SPLIT_VERTICALLY = "TWO_IMAGES_SPLIT_VERTICALLY",
    TWO_IMAGES_SPLIT_HORIZONTALLY = "TWO_IMAGES_SPLIT_HORIZONTALLY",
    FOUR_IMAGES_EVEN_GRID = "FOUR_IMAGES_EVEN_GRID",
}

const BASE_WIDTH = 600, BASE_HEIGHT = 400

const getImageDimensionsForLayout = (layout: LAYOUT): { width: number, height: number } => {
    switch (layout) {
        case LAYOUT.SINGLE_IMAGE:
            return { width: BASE_WIDTH, height: BASE_HEIGHT }
        case LAYOUT.TWO_IMAGES_SPLIT_HORIZONTALLY:
            return { width: BASE_WIDTH, height: BASE_HEIGHT / 2 }
        case LAYOUT.TWO_IMAGES_SPLIT_VERTICALLY:
            return { width: BASE_WIDTH / 2, height: BASE_HEIGHT }
        case LAYOUT.FOUR_IMAGES_EVEN_GRID:
            return { width: BASE_WIDTH / 2, height: BASE_HEIGHT / 2 }
    }
}

const getImagesNumberForLayout = (layout: LAYOUT): number => {
    switch (layout) {
        case LAYOUT.SINGLE_IMAGE:
            return 1
        case LAYOUT.TWO_IMAGES_SPLIT_HORIZONTALLY:
            return 2
        case LAYOUT.TWO_IMAGES_SPLIT_VERTICALLY:
            return 2
        case LAYOUT.FOUR_IMAGES_EVEN_GRID:
            return 4
    }
}

const getImageFromLayout = (layout: LAYOUT): string => {
    switch (layout) {
        case LAYOUT.SINGLE_IMAGE:
            return 'images/app/imagestep/layouts/single_image.png'
        case LAYOUT.TWO_IMAGES_SPLIT_HORIZONTALLY:
            return ''
        case LAYOUT.TWO_IMAGES_SPLIT_VERTICALLY:
            return 'images/app/imagestep/layouts/two_images_vertical.png'
        case LAYOUT.FOUR_IMAGES_EVEN_GRID:
            return 'images/app/imagestep/layouts/four_images.png'
    }
}

export { getImageDimensionsForLayout, getImagesNumberForLayout, getImageFromLayout }