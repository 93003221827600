import React, {Suspense, useContext} from "react";
import IsGreetingsContext from "./imagesselection/isGreetingsContext";
import {Box, CircularProgress, Typography, useMediaQuery, useTheme} from "@mui/material";
import {sortFonts, Location, withLocation} from "./postcardAppUtils";
import MessageSettings from "../postcard-app/messagesettings/MessageSettings";
import MessageAndLocationPostcardBack from "../../postcardrecap/postcard/templates/MessageAndLocationPostcardBack";
import {RootState, useAppDispatch, useAppSelector} from "../../../utils/store";
import {Fonts} from "./utils/fontsFetcher";
import {Await, useLoaderData} from "react-router-dom";
import {Font} from "../../../utils/postcard";
import useActions from "./utils/useActions";

const MessageAndLocationStep = () => {
    const { isGreetings } = useContext(IsGreetingsContext)
    const data = useLoaderData() as Fonts

    const stateSelector = (state: RootState) =>
        isGreetings ? state.greetings.postcard : state.app.postcard

    const {
        message, location, font
    } = withLocation(useAppSelector(stateSelector))

    const dispatch = useAppDispatch()

    const {
        updateFont, updateMessage, updateLocation
    } = useActions(isGreetings)

    const belowMedium = useMediaQuery(useTheme().breakpoints.down('md'))

    return <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'column' : 'row-reverse', alignItems: belowMedium ? 'center' : 'auto', width: belowMedium ? '100%' : 'auto' }} >
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <Box sx={{ width: belowMedium ? '100%' : 600 }} >
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ textAlign: 'center' }} variant={'h6'}>Back</Typography>
            </Box>
            <Box sx={{ width: '100%', px: 1 }}>
                <MessageAndLocationPostcardBack
                    message={message}
                    setMessage={(value: string) => dispatch(updateMessage(value))}
                    font={font}
                    location={location}
                    setLocation={(value: Location) => dispatch(updateLocation(value))}
                    // Never display envelope stamp in this step
                    envelope={false}
                />
            </Box>
        </Box>

        <Box sx={{ p: belowMedium ? 0 : 2, pt: belowMedium ? 3 : 0, width: belowMedium ? '100%' : 250 }}>
            <Typography variant={'h6'}>Choose your font</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'start', pt: 2, height: belowMedium ? 'auto' : 378, flexDirection: belowMedium ? 'row' : 'column' }}>
                <Suspense fallback={<CircularProgress sx={{ transform: 'scale(1.5)' }} />}>
                    <Await
                        resolve={data.fonts}
                        errorElement={
                            <Typography>Failed to fetch fonts</Typography>
                        }
                        children={(fonts: Font[]) => <MessageSettings<Font>
                            items={sortFonts(fonts).map((_font: Font) => ({
                                value: _font,
                                component: <Box sx={{ height: '24px', width: '24px', display: 'flex', justifyContent: 'center' }}>
                                    <Typography sx={{ fontFamily: _font.name }}>Aa</Typography>
                                </Box>
                            }))}
                            currentItem={font ?? fonts[3]}
                            setCurrentItem={(item: Font) => dispatch(updateFont(item))}
                        />}
                    />
                </Suspense>
            </Box>
        </Box>
    </Box>
}

export default MessageAndLocationStep