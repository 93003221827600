import {Alert, Box, IconButton, Snackbar} from "@mui/material";
import React from "react";
import {AlertMessage} from "./imagesselection/alertsContext";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

const AppSnackbar = ({ alerts, removeAlert }: { alerts: AlertMessage[], removeAlert: (id: string) => void }) => {
    return <Snackbar open={true} autoHideDuration={10000} onClose={(event, reason) => {
        if (reason === 'timeout') alerts.forEach(alert => removeAlert(alert.id))
    }}>
        <Box>
            {alerts.map(alert => <Alert key={alert.id} severity={alert.severity} sx={{
                mb: 1,
                backgroundColor: 'rgb(50, 50, 50)',
                color: 'white',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '& .MuiAlert-action': {
                    pt: 0
                },
                boxShadow: '0px 2px 4px -1px rgba(40, 40, 40, 0.2), 0px 4px 5px 0px rgba(40, 40, 40, 0.14), 0px 1px 10px 0px rgba(40, 40, 40, 0.12)'
            }} icon={
                <ErrorOutlineIcon sx={{ '& path': { fill: 'white' } }} />
            } action={<IconButton onClick={() => {
                removeAlert(alert.id)
            }}>
                <CloseIcon sx={{ '& path': { fill: 'white' } }} />
            </IconButton>}>
                {alert.text}
            </Alert>)}
        </Box>
    </Snackbar>
}

export default AppSnackbar