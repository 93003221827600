import {RootState} from "../../../utils/store";
import {Action} from "@reduxjs/toolkit";
import {STEPS} from "./postcardAppUtils";
import PageWrapper from "../../PageWrapper";
import {Box} from "@mui/material";
import CurrentStepBreadcrumbs from "./steps/CurrentStepBreadcrumbs";
import PreviousButton from "./steps/PreviousButton";
import NextButton from "./steps/NextButton";
import Exceptions from "./Exceptions";
import SubmissionLoadingBackdrop from "./SubmissionLoadingBackdrop";
import React from "react";

type AppPageProps = {
    steps: JSX.Element[]
    step: STEPS
}

const AppPage = ({ steps, step }: AppPageProps) => {
    const currentStep = steps[step]

    return <PageWrapper>
        <Box sx={{ display: 'flex', flexGrow: 0, flexShrink: 0, width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 4, mb: 2 }}>
            <CurrentStepBreadcrumbs />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
            {currentStep}
        </Box>

        <Box sx={{ flexDirection: 'row', display: 'flex', width: '100%', justifyContent: 'center', gap: 4, mb: 8 }}>
            <PreviousButton />
            <NextButton />
        </Box>
        <Exceptions />
        <SubmissionLoadingBackdrop />
    </PageWrapper>
}

export default AppPage