import {createTheme} from "@mui/material";

let muiTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#fa7e48',
            contrastText: '#fff',
            light: '#ffe7db'
        },
        secondary: {
            main: '#f3f5ff',
            contrastText: '#fa7e48'
        }
    },
    components: {
        MuiLink: {
            defaultProps: {
                fontWeight: 'bold',
                underline: 'hover',
                color: 'white',
                sx: {
                    cursor: 'pointer'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                sx: {
                    textTransform: 'Capitalize',
                    fontWeight: 700,
                }
            }
        },
        MuiAppBar: {
            defaultProps: {
                sx: {
                    boxShadow: '0px 2px 4px -1px rgba(250, 126, 72, 0.2), 0px 4px 5px 0px rgba(250, 126, 72, 0.14), 0px 1px 10px 0px rgba(250, 126, 72, 0.12)',
                    height: 64
                }
            }
        },
        MuiCard: {
            defaultProps: {
                sx: {
                    borderRadius: '6px',
                    boxShadow: 'none',
                    p: 1,
                    color: '#666',
                    border: '1.49px solid rgba(102, 102, 102, 0.08)',
                    boxSizing: 'border-box'
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                sx: {
                    [`& fieldset`]: {
                        borderRadius: 3,
                        backgroundColor: 'white',
                    },
                    '& input': {
                        zIndex: 10
                    }
                }
            }
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'h1' },
                    style: {
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontSize: '64px',
                        lineHeight: 'auto',
                        letterSpacing: '-0.02em',

                        /* black */
                        color: '#000000'
                    },
                },
                {
                    props: { variant: 'h4' },
                    style: {
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontSize: '40px',
                    },
                },
                {
                    props: { variant: 'h6' },
                    style: {
                        fontFamily: 'Roboto-bold',
                        fontStyle: 'normal',
                        fontSize: '24px',
                        lineHeight: '28px',
                        letterSpacing: '-0.02em',
                        color: '#000'
                    },
                },
                {
                    // @ts-ignore
                    props: { variant: 'small' },
                    style: {
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '140%',
                        color: '#666'
                    },
                },
                {
                    props: { variant: 'body1' },
                    style: {
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        lineHeight: '140%',
                        letterSpacing: '0px',
                        color: '#666'
                    },
                },
                {
                    // @ts-ignore
                    props: { variant: 'subtitle' },
                    style: {
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontSize: '24px',
                        lineHeight: '140%',
                        letterSpacing: '-2%',
                        color: '#000'
                    },
                },
                {
                    // @ts-ignore
                    props: { variant: 'label1' },
                    style: {
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        fontSize: '12px',
                        lineHeight: '150%',
                        letterSpacing: '1px',
                        color: '#666666'
                    },
                },
                {
                    // @ts-ignore
                    props: { variant: 'order-successful' },
                    style: {
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '20px',
                        lineHeight: '23px',
                        textAlign: 'center',
                        letterSpacing: '-0.02em',
                        color: '#666666'
                    },
                },
            ],
        },
    }
})

// muiTheme = responsiveFontSizes(muiTheme)

export default muiTheme