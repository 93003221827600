import {generateClient, get, GraphQLQuery, GraphQLResult} from '@aws-amplify/api';
import {getUrl, uploadData} from 'aws-amplify/storage'
import {
    CreateAddressMutation,
    CreateCartItemMutation,
    CreateCartMutation,
    CreateChainMutation,
    CreateGreetingsAddressMutation,
    CreateGreetingsPostcardMutation,
    CreateImageMutation,
    CreateOrderLineItemMutation,
    CreateOrderMutation,
    CreateOrderPromoCodeMutation,
    CreatePostcardMutation,
    CreatePostcardSettingsMutation,
    CreateQRCodeScanMutation,
    DeleteCartItemMutation,
    GetCartQuery,
    GetChainQuery,
    GetGreetingsPostcardQuery,
    GetPostcardQuery,
    ListOrderLineItemsQuery,
    ListOrdersQuery,
    ListPostcardFontsQuery,
    ListPostcardFramesQuery,
    ListPostcardMessageColorsQuery,
    ListPostcardsQuery,
    ListPromoCodesQuery,
    UpdatePostcardMutation,
    UpdatePostcardSettingsMutation,
} from "../API";
import {
    addQRCodeScan,
    createAddress,
    createCart,
    createCartItem as _createCartItem,
    createChain as _createChain,
    createGreetingsAddress,
    createGreetingsPostcard,
    createImage as _createImage,
    createOrder as _createOrder,
    createOrderLineItem as _createOrderLineItem,
    createOrderPromoCode,
    createPostcard as _createPostcard,
    createPostcardSettings as _createPostcardSettings,
    deleteCartItem as _deleteCartItem,
    getCart,
    getCartAndItems,
    getChainForProgression,
    getFullCart,
    getGreetingsPostcardForScan,
    getPostcard,
    getPostcardForPricing,
    getPostcardGuestUser,
    listFonts,
    listFrames,
    listMessageColors,
    listOrderLineItems,
    listOrderLineItemsAndGreetingsPostcard,
    listOrderLineItemsAndPostcard,
    listOrdersForPromoCodes,
    listPostcards,
    listPostcardsForCount,
    listPostcardsFromOrders,
    listPromoCodes,
    listPromoCodesForValidation,
    removePostcardFromCart as _removePostcardFromCart,
    updatePostcard,
    updatePostcardSettings
} from "./queries";
import {v4 as uuid} from 'uuid'
import {fetchAuthSession, fetchUserAttributes} from "aws-amplify/auth";
import {Chain, CHAIN_SETTING, GreetingsPostcard, Image, Postcard, REPLY_BACK_SETTING} from "../utils/postcard";
import {LAYOUT} from "../utils/layout";
import {PostcardAppInitialState} from "../components/app/postcard-app/utils/PostcardAppInitialState";
import {DEFAULT_COLOR, DEFAULT_FONT, NO_COORDINATE, NO_FRAME} from "../components/app/common/postcardAppUtils";

export const LAUNCH_PROMO_CODE = 'MEMENTOLAUNCH'
export const AMBASSADOR_PROMO_CODE = 'MEMENTO100'
export const MEMENTALO_PROMO_CODE = 'MEMENTALO'
export const AMBASSADOR_NEWS_LETTER_PROMO_CODE = 'AMBASSADOR100'
export const GREETINGS_20_PROMO_CODE = 'GREETINGS20'
export type DBPostcard = {
    id: string
    message?: string,
    postcardRecipientAddressId: string,
    recipientId?: string,
    location?: string,
    latitude?: number,
    longitude?: number,
    postcardFrameId?: string,
    layout?: string,
    postcardFontId?: string,
    postcardMessageColorId?: string,
    postcardSettingsId?: string,
    owner?: string
}

export type DBAddress = {
    id: string
    fullName: string
    streetAddress: string
    city: string
    zipCode: string
}

export type DBGreetingsAddress = {
    id: string
    fullName: string
    streetAddress: string
    city: string
    zipCode: string
    greetingsPostcardRecipientAddressesId: string
}

export type ChainProgressionPostcard = {
    id: string
    message?: string,
    location?: string,
    settings: {
        postcardSettingsReplyId?: string
    }
}

export type _DBImage = {
    id: string,
    index: number,
    path: string,
    postcardImagesId: string
}

export type DBGreetingsImage = {
    id: string,
    index: number,
    path: string,
    greetingsPostcardImagesId: string
}

export type DBImage = _DBImage | DBGreetingsImage

export type IDObject = {
    id: string
}

export type DBFrame = {
    id: string,
    name: string
}

export type DBFont = {
    id: string,
    name: string,
    extension: string
}

export type DBMessageColor = {
    id: string,
    name: string,
    hex: string
}

type RawImage = {
    id: string,
    index: number,
    path: string,
}

export type RawPostcard = {
    id: string
    message?: string,
    recipientId?: string,
    address: DBAddress,
    location?: string,
    latitude?: number,
    longitude?: number,
    layout?: string,
    createdAt: string,
    messageColor?: DBMessageColor,
    images: RawImage[],
    frame: DBFrame,
    font: DBFont,
    settings?: DBPostcardSettings
    owner?: string
}

export type RawGreetingsPostcard = {
    id: string
    message?: string,
    recipientId?: string,
    addresses: DBAddress[],
    location?: string,
    latitude?: number,
    longitude?: number,
    layout?: string,
    createdAt: string,
    messageColor?: DBMessageColor,
    images: RawImage[],
    frame: DBFrame,
    font: DBFont,
    owner?: string
}

export type RawCart = {
    id: string
    postcards: {
        postcard: RawPostcard
        cartItemId: string
    }[]
}

export type DBPostcardSettings = {
    id: string
    replyBackSetting: REPLY_BACK_SETTING
    postcardSettingsReplyAddressId?: string
    repliesTo?: Postcard
    reply?: Postcard
    chainSetting: CHAIN_SETTING
    postcardSettingsRepliesToId?: string
    postcardSettingsReplyId?: string
    chainPostcardsId?: string
    initialState: PostcardAppInitialState
    chain?: Chain
    envelope: boolean
    postcardSettingsPostcardId: string
};

export type SuggestedLocation = {
    longitude: number
    latitude: number
    displayName: string
}

export type DBPromoCode = {
    id: string,
    name: string,
    active: boolean,
    orderPromoCode: {
        id: string,
        redeemed: boolean,
        order: {
            id: string,
            orderLineItems: {
                id: string,
                postcard: {
                    id: string
                }
            }[]
        }
    }[]
}

export type PostcardPricingInformation = {
    id: string,
    chainSetting: CHAIN_SETTING,
    replyBackSetting: REPLY_BACK_SETTING,
    envelope: boolean,
    initialState: PostcardAppInitialState
}

export type DBGreetingsPostcard = {
    id: string
    message?: string,
    location?: string,
    latitude?: number,
    longitude?: number,
    layout?: string,
    greetingsPostcardFrameId?: string,
    greetingsPostcardFontId?: string,
    greetingsPostcardMessageColorId?: string,
    owner?: string
}

const client = generateClient()

const fetchImageFromUri = async (uri: string): Promise<Blob> => {
    const response = await fetch(uri);
    return await response.blob();
};

const uploadImageInStorage = async (image: DBImage): Promise<false | string> => {
    try {
        const imageData = await fetchImageFromUri(image.path)
        const imageExtension = imageData.type.split('/')[1]
        const imagePath = 'postcards_images/' + image.id + '.' + imageExtension
        const uploadStatus = uploadData({
            key: imagePath,
            data: imageData
        })

        try {
            await (uploadStatus.result)
        } catch (e) {
            console.warn("Error while uploading your image: " + e)
            return false
        }
        return imagePath
    } catch (e) {
        // Send email to devs
        console.warn("Error while uploading image for postcard: ", e)
        return false
    }
}

const createImageInDB = async (image: DBImage): Promise<false | IDObject> => {
    try {
        // @ts-ignore
        if (!(image.postcardImagesId || image.greetingsPostcardImagesId)) return false
        if (!image.path) return false
        const rawResult = await client.graphql<GraphQLQuery<CreateImageMutation>>({
            query: _createImage,
            variables: { input: image }
        })

        if (rawResult.data?.createImage?.id) {
            return { id: rawResult.data.createImage.id }
        } else  {
            console.warn("Error in create new image in DB: " + rawResult.errors)
            return false
        }
    } catch(e) {
        // Send email to devs
        console.warn("Error while creating new image at DB: ", e)
        return false
    }
}

const createPostcardSettings = async (settings: DBPostcardSettings): Promise<false | IDObject> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<CreatePostcardSettingsMutation>>({
            query: _createPostcardSettings,
            variables: { input: {
                id: settings.id,
                replyBackSetting: settings.replyBackSetting.toString(),
                postcardSettingsReplyAddressId: settings.postcardSettingsReplyAddressId,
                chainSetting: settings.chainSetting.toString(),
                postcardSettingsRepliesToId: settings.postcardSettingsRepliesToId,
                chainPostcardsId: settings.chainPostcardsId,
                postcardSettingsPostcardId: settings.postcardSettingsPostcardId,
                envelope: settings.envelope,
                initialState: PostcardAppInitialState[settings.initialState]
            } }
        })

        if (rawResult.data?.createPostcardSettings?.id) {
            return { id: rawResult.data.createPostcardSettings.id }
        } else return false
    } catch(e) {
        // Send email to devs
        console.warn("Error while creating new postcard settings at DB: ", e)
        return false
    }
}

const createChain = async (chain: Chain): Promise<false | IDObject> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<CreateChainMutation>>({
            query: _createChain,
            variables: { input: chain }
        })

        if (rawResult.data?.createChain?.id) {
            return { id: rawResult.data.createChain.id }
        } else return false
    } catch(e) {
        // Send email to devs
        console.warn("Error while creating new postcard chain at DB: ", e)
        return false
    }
}

const createPostcardInDB = async (postcard: DBPostcard): Promise<false | IDObject> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<CreatePostcardMutation>>({
            query: _createPostcard,
            variables: { input: postcard }
        })

        if (rawResult.data?.createPostcard?.id) {
            return { id: rawResult.data.createPostcard.id }
        } else return false
    } catch(e) {
        // Send email to devs
        console.warn("Error while creating new postcard at DB: ", e)
        return false
    }
}

const _createOrderPromoCode = async (id: string, orderId: string, promoCodeId: string): Promise<boolean> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<CreateOrderPromoCodeMutation>>({
            query: createOrderPromoCode,
            variables: {
                input: {
                    id: id,
                    redeemed: false,
                    promoCodeOrderPromoCodeId: promoCodeId,
                    orderPromoCodeOrderId: orderId
                }
            }
        })

        if (rawResult.data?.createOrderPromoCode?.id) {
            return true
        } else {
            console.warn("Error in create orderPromoCode response: ", rawResult)
            return false
        }
    } catch (e) {
        // Send email to devs
        console.warn("Error while creating new orderPromoCode at DB: ", e)
        return false
    }
}

const createOrder = async (promoCodeId: string | null): Promise<false | IDObject> => {
    try {
        const connectionId = uuid()
        const rawResult = await client.graphql<GraphQLQuery<CreateOrderMutation>>({
            query: _createOrder,
            variables: { input: promoCodeId !== null ? { orderOrderPromoCodeId: connectionId } : { } }
        })

        if (rawResult.data?.createOrder?.id) {
            const id = rawResult.data.createOrder.id
            if (promoCodeId !== null) {
                const connectionResult = await _createOrderPromoCode(connectionId, id, promoCodeId)
                if (!connectionResult) return false
            }
            return { id: id }
        } else {
            console.warn("Error in create order response: ", rawResult)
            return false
        }
    } catch (e) {
        // Send email to devs
        console.warn("Error while creating new order at DB: ", e)
        return false
    }
}

const createOrderLineItem = async (postcardId: string, orderId: string, price: number, isGreetings: boolean): Promise<false | IDObject> => {
    try {
        if (!postcardId || !orderId || !price) return false
        const commonData = {
            price: price,
            orderOrderLineItemsId: orderId
        }

        const rawResult = await client.graphql<GraphQLQuery<CreateOrderLineItemMutation>>(
            {
                query: _createOrderLineItem,
                variables: {
                    input: isGreetings ? {
                        ...commonData,
                        orderLineItemGreetingsPostcardId: postcardId
                    } : {
                        ...commonData,
                        orderLineItemPostcardId: postcardId
                    }
                }
            })
        console.log("Order line item creation: ", rawResult)
        if (rawResult.data?.createOrderLineItem?.id) {
            return { id: rawResult.data.createOrderLineItem.id }
        } else return false
    } catch (e) {
        // Send email to devs
        console.warn("Error while creating new orderLineItem at DB: ", e)
        return false
    }
}

const getFramesInDB = async (): Promise<false | DBFrame[]> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListPostcardFramesQuery>>({
            query: listFrames,
            variables: { },
            authMode: 'apiKey'
        })
        if (rawResult.data?.listPostcardFrames?.items) {
            const items = rawResult.data.listPostcardFrames.items
            const result = []
            for (const frame of items)
                if (frame) result.push({ id: frame.id, name: frame.name })
            return result
        } else return false
    } catch (e) {
        console.warn('Error while fetching frames from DB: ', e)
        return false
    }
}

const getFrameImage = async (id: string): Promise<false | string> => {
    try {
        const imagePath = 'frames/' + id + '.png'
        const result = await getUrl({ key: imagePath })
        if (result)
            return result.url.toString()
        else return false
    } catch (e) {
        console.warn('Error while fetching frame image for ', id, ': ', e)
        return false
    }
}

const getFontsInDB = async (): Promise<false | DBFont[]> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListPostcardFontsQuery>>({
            query: listFonts,
            variables: { },
            authMode: 'apiKey'
        })
        if (rawResult.data?.listPostcardFonts?.items) {
            const items = rawResult.data.listPostcardFonts.items
            const result: DBFont[] = []
            for (const font of items)
                if (font) result.push(font)
            return result
        } else return false
    } catch (e) {
        console.warn('Error while fetching fonts from DB: ', e)
        return false
    }
}

const getFontFile = async (id: string, extension: string): Promise<false | string> => {
    try {
        const fontPath = 'fonts/' + id + '.' + extension.toLowerCase()
        const result = await getUrl({ key: fontPath })
        if (result)
            return result.url.toString()
        else return false
    } catch (e) {
        console.warn('Error while fetching font file for ', id, ': ', e)
        return false
    }
}

const getColorsInDB = async (): Promise<false | DBMessageColor[]> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListPostcardMessageColorsQuery>>({
            query: listMessageColors,
            variables: { },
            authMode: 'apiKey'
        })
        if (rawResult.data?.listPostcardMessageColors?.items) {
            const items = rawResult.data.listPostcardMessageColors.items
            const result: DBMessageColor[] = []
            for (const color of items)
                if (color) result.push(color)
            return result
        } else return false
    } catch (e) {
        console.warn('Error while fetching message colors from DB: ', e)
        return false
    }
}

const createImage = async (image: DBImage): Promise<false | IDObject> => {
    const imagePath = await uploadImageInStorage(image)
    if (imagePath)
        return await createImageInDB({ ...image, path: imagePath })
    return false
}

const _getImage = async (rawImage: RawImage): Promise<false | Image> => {
    try {
        const result = await getUrl({ key: rawImage.path })
        if (result)
            return {
                id: rawImage.id,
                index: rawImage.index,
                path: result.url.toString()
            }
        else return false
    } catch (e) {
        console.warn('Error while fetching image with id', rawImage.id, ': ', e)
        return false
    }
}

const processPostcardRawData = async (postcard: RawPostcard): Promise<Postcard> => {
    const hasFont = !!(postcard.font)
    const hasFrame = !!(postcard.frame)
    const hasImages = postcard.images.length !== 0

    let fontFilePromise: Promise<false | string>,
        frameImagePromise: Promise<false | string>,
        imagePromises: Promise<false | Image>[]

    let fontFile: string | false = false,
        frameImage: string | false = false,
        images: Image[] | undefined = []

    if (hasFont) fontFilePromise = getFontFile(postcard.font.id, postcard.font.extension)
    if (hasFrame) frameImagePromise = getFrameImage(postcard.frame.id)
    if (hasImages) imagePromises = postcard.images.map(image => _getImage(image))

    if (hasFont) { // @ts-ignore
        fontFile = await fontFilePromise
    }
    if (hasFrame) { // @ts-ignore
        frameImage = await frameImagePromise
    }
    if (hasImages) {
        // @ts-ignore
        for (const imagePromise of imagePromises)
            if (imagePromise)
                images.push((await imagePromise) as Image)
    }

    return {
        id: postcard.id,
        color: postcard.messageColor,
        font: fontFile ? { ...postcard.font, file: fontFile } : undefined,
        frame: frameImage ? { ...postcard.frame, image: frameImage } : undefined,
        images: images,
        layout: LAYOUT[postcard.layout as keyof typeof LAYOUT],
        location: postcard.location ? postcard.location : '',
        latitude: postcard.latitude ? postcard.latitude : NO_COORDINATE,
        longitude: postcard.longitude ? postcard.longitude : NO_COORDINATE,
        message: postcard.message ? postcard.message : '',
        address: postcard.address,
        recipientId: postcard.recipientId,
        owner: postcard.owner,
        // @ts-ignore
        settings: postcard.settings ? postcard.settings : {
            id: uuid(),
            replyBackSetting: REPLY_BACK_SETTING.NONE,
            chainSetting: CHAIN_SETTING.NONE,
        }
    }
}

const processGreetingsPostcardRawData = async (postcard: RawGreetingsPostcard): Promise<GreetingsPostcard> => {
    const hasFont = !!(postcard.font)
    const hasFrame = !!(postcard.frame)
    const hasImages = postcard.images.length !== 0

    let fontFilePromise: Promise<false | string>,
        frameImagePromise: Promise<false | string>,
        imagePromises: Promise<false | Image>[]

    let fontFile: string | false = false,
        frameImage: string | false = false,
        images: Image[] | undefined = []

    if (hasFont) fontFilePromise = getFontFile(postcard.font.id, postcard.font.extension)
    if (hasFrame) frameImagePromise = getFrameImage(postcard.frame.id)
    if (hasImages) imagePromises = postcard.images.map(image => _getImage(image))

    if (hasFont) { // @ts-ignore
        fontFile = await fontFilePromise
    }
    if (hasFrame) { // @ts-ignore
        frameImage = await frameImagePromise
    }
    if (hasImages) {
        // @ts-ignore
        for (const imagePromise of imagePromises)
            if (imagePromise)
                images.push((await imagePromise) as Image)
    }

    return {
        id: postcard.id,
        color: postcard.messageColor,
        font: fontFile ? { ...postcard.font, file: fontFile } : undefined,
        frame: frameImage ? { ...postcard.frame, image: frameImage } : undefined,
        images: images,
        layout: LAYOUT[postcard.layout as keyof typeof LAYOUT],
        location: postcard.location ? postcard.location : '',
        latitude: postcard.latitude ? postcard.latitude : NO_COORDINATE,
        longitude: postcard.longitude ? postcard.longitude : NO_COORDINATE,
        message: postcard.message ? postcard.message : '',
        addresses: postcard.addresses,
        owner: postcard.owner,
        // @ts-ignore
        settings: postcard.settings ? postcard.settings : {
            id: uuid(),
            replyBackSetting: REPLY_BACK_SETTING.NONE,
            chainSetting: CHAIN_SETTING.NONE,
        }
    }
}

const _getOwnerString = (userId: string): string => {
    return `${userId}::${userId}`
}

const _getRawGreetingsPostcardFromGraphQLResult = (postcard: object): RawGreetingsPostcard => {
    return {
        // @ts-ignore
        id: postcard.id,
        // @ts-ignore
        message: postcard.message ? postcard.message : undefined,
        // @ts-ignore
        addresses: postcard.recipientAddresses ? postcard.recipientAddresses.items : [],
        // @ts-ignore
        recipientId: postcard.recipientId,
        // @ts-ignore
        location: postcard.location ? postcard.location : undefined,
        // @ts-ignore
        latitude: postcard.latitude ? postcard.latitude : undefined,
        // @ts-ignore
        longitude: postcard.longitude ? postcard.longitude : undefined,
        // @ts-ignore
        layout: postcard.layout ? postcard.layout : undefined,
        // @ts-ignore
        createdAt: postcard.createdAt,
        // @ts-ignore
        messageColor: postcard.messageColor ? postcard.messageColor : DEFAULT_COLOR,
        // @ts-ignore
        images: postcard.images,
        // @ts-ignore
        frame: postcard.frame ? postcard.frame : NO_FRAME,
        // @ts-ignore
        font: postcard.font ? postcard.font : DEFAULT_FONT,
        // @ts-ignore
        owner: postcard.owner ? postcard.owner : undefined
    }
}

const _getRawPostcardFromGraphQLResult = (postcard: object): RawPostcard => {
    return {
        // @ts-ignore
        id: postcard.id,
        // @ts-ignore
        message: postcard.message ? postcard.message : undefined,
        // @ts-ignore
        address: postcard.recipientAddress,
        // @ts-ignore
        recipientId: postcard.recipientId,
        // @ts-ignore
        location: postcard.location ? postcard.location : undefined,
        // @ts-ignore
        latitude: postcard.latitude ? postcard.latitude : undefined,
        // @ts-ignore
        longitude: postcard.longitude ? postcard.longitude : undefined,
        // @ts-ignore
        layout: postcard.layout ? postcard.layout : undefined,
        // @ts-ignore
        createdAt: postcard.createdAt,
        // @ts-ignore
        messageColor: postcard.messageColor ? postcard.messageColor : DEFAULT_COLOR,
        // @ts-ignore
        images: postcard.images,
        // @ts-ignore
        frame: postcard.frame ? postcard.frame : NO_FRAME,
        // @ts-ignore
        font: postcard.font ? postcard.font : DEFAULT_FONT,
        // @ts-ignore
        settings: postcard.settings,
        // @ts-ignore
        owner: postcard.owner ? postcard.owner : undefined
    }
}

const _processRawPostcards = (_postcards: any[]): false | RawPostcard[] => {
    const postcards: RawPostcard[] = []
    for (const postcard of _postcards) {
        if (postcard) {
            // @ts-ignore
            postcard.images = postcard.images.items
            postcard.createdAt = postcard.createdAt.substring(0, 10)
            postcards.push(_getRawPostcardFromGraphQLResult(postcard))
        }
    }

    if (postcards.length === 0) return false
    else return postcards
}

const _processRawGreetingsPostcards = (_postcards: any[]): false | RawGreetingsPostcard[] => {
    const postcards: RawGreetingsPostcard[] = []
    for (const postcard of _postcards) {
        if (postcard) {
            // @ts-ignore
            postcard.images = postcard.images.items
            postcard.createdAt = postcard.createdAt.substring(0, 10)
            postcards.push(_getRawGreetingsPostcardFromGraphQLResult(postcard))
        }
    }

    if (postcards.length === 0) return false
    else return postcards
}

const getUserReceivedPostcards = async (userId: string): Promise<false | RawPostcard[]> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListPostcardsQuery>>({
            query: listPostcards,
            variables: {
                filter: {
                    recipientId: { eq: userId }
                }
            }
        })
        if (rawResult.data?.listPostcards?.items) {
            // @ts-ignore
            return _processRawPostcards(rawResult.data.listPostcards.items.sort((a, b) => {
                console.log(a?.createdAt, b?.createdAt)
                if (!a) return -1
                else if (!b) return 1
                else return a?.createdAt > b?.createdAt ? -1 : 1
            }))
        } else return false
    } catch (e) {
        // @ts-ignore
        if (e.data?.listPostcards?.items) {
            // @ts-ignore
            return _processRawPostcards(e.data.listPostcards.items.sort((a, b) => {
                if (!a) return -1
                else if (!b) return 1
                else return a?.createdAt > b?.createdAt ? -1 : 1
            }))
        }
        console.warn(`Error while fetching received postcards from DB: ` + e)
        return false
    }
}

const _extractPostcardsFromOrder = (orders: any[]): any[] => {
    try {
        // @ts-ignore
        return orders.map(
            order => order.orderLineItems.items.map(
                // @ts-ignore
                orderLineItem => orderLineItem.postcard
            )
        ).flat()
    } catch (e) {
        console.warn("Error while parsing orders for sent postcards from DB: ", e)
        return []
    }
}

const getUserSentPostcards = async (userId: string): Promise<false | RawPostcard[]> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListOrdersQuery>>({
            query: listPostcardsFromOrders,
            variables: {
                filter: {
                    owner: { eq: _getOwnerString(userId) }
                }
            }
        })
        if (rawResult.data?.listOrders?.items) {
            const orders = rawResult.data?.listOrders?.items.sort((a, b) => {
                if (!a) return -1
                else if (!b) return 1
                else return a?.createdAt > b?.createdAt ? -1 : 1
            })
            const postcards = _extractPostcardsFromOrder(orders)
            // @ts-ignore
            return _processRawPostcards(postcards)
        } else return false
    } catch (e) {
        // @ts-ignore
        if (e.data?.listOrders?.items) {
            // @ts-ignore
            const orders = e.data?.listOrders?.items
            const postcards = _extractPostcardsFromOrder(orders)
            // @ts-ignore
            return _processRawPostcards(postcards)
        }
        console.warn(`Error while fetching sent postcards from DB: ` + e)
        return false
    }
}

const getCountOfSentPostcards = async (userId: string): Promise<false | number> => {
    // Not the safest option because any wrong userId would return 0 sent postcards and not raise error.
    // Should be good enough for the operation context of the function
    if (!userId) return false
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListPostcardsQuery>>({
            query: listPostcardsForCount,
            variables: {
                filter: {
                    owner: { eq: _getOwnerString(userId) }
                }
            }
        })
        if (rawResult.data && rawResult.data.listPostcards) {
            return rawResult.data.listPostcards?.items.length
        } else {
            return false
        }
    } catch (e) {
        console.warn('Error while fetching sent postcards count from DB: ', e)
        return false
    }
}

const _processRawPostcard = (rawResult: { data: { getPostcard: any; }; }): RawPostcard => {
    const postcard = rawResult.data.getPostcard
    // @ts-ignore
    postcard.images = postcard.images.items
    postcard.createdAt = postcard.createdAt.substring(0, 10)
    return _getRawPostcardFromGraphQLResult(postcard)
}

const _processRawGreetingsPostcard = (rawResult: { data: { getGreetingsPostcard: any; }; }): RawGreetingsPostcard => {
    const postcard = rawResult.data.getGreetingsPostcard
    // @ts-ignore
    postcard.images = postcard.images.items
    postcard.createdAt = postcard.createdAt.substring(0, 10)
    return _getRawGreetingsPostcardFromGraphQLResult(postcard)
}

const _isUserLogged = async (): Promise<boolean> => {
    const authSession = (await fetchAuthSession()).tokens ?? null
    return !!authSession
}

const getRawPostcard = async (id: string): Promise<false | RawPostcard> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<GetPostcardQuery>>({
            query: (await _isUserLogged()) ? getPostcard : getPostcardGuestUser,
            variables: { id: id },
            authMode: (await _isUserLogged()) ? 'userPool' : 'apiKey'
        })
        if (rawResult.data && rawResult.data.getPostcard) {
            // @ts-ignore
            return _processRawPostcard(rawResult)
        } else {
            console.warn("Error in fetch postcard response: ", rawResult)
            return false
        }
    } catch (e) {
        // @ts-ignore
        if (e.data && e.data.getPostcard) {
            console.warn('Non breaking error while fetching postcard from DB: ', e)
            // @ts-ignore
            return _processRawPostcard(e)
        }
        console.warn('Error while fetching postcard from DB: ', e)
        return false
    }
}

const getGreetingsRawPostcard = async (id: string): Promise<false | RawGreetingsPostcard> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<GetGreetingsPostcardQuery>>({
            query: getGreetingsPostcardForScan,
            variables: { id: id },
            authMode: 'apiKey'
        })
        if (rawResult.data && rawResult.data.getGreetingsPostcard) {
            // @ts-ignore
            return _processRawGreetingsPostcard(rawResult)
        } else {
            console.warn("Error in fetch postcard response: ", rawResult)
            return false
        }
    } catch (e) {
        // @ts-ignore
        if (e.data && e.data.getPostcard) {
            console.warn('Non breaking error while fetching postcard from DB: ', e)
            // @ts-ignore
            return _processRawGreetingsPostcard(e)
        }
        console.warn('Error while fetching postcard from DB: ', e)
        return false
    }
}

const createQRCodeScan = async (postcardId: string): Promise<boolean> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<CreateQRCodeScanMutation>>({
            query: addQRCodeScan,
            variables: { input: { postcardScansId: postcardId } },
            authMode: (await _isUserLogged()) ? 'userPool' : 'apiKey'
        })
        return !!(rawResult.data && rawResult.data.createQRCodeScan);
    } catch (e) {
        console.warn('Error while creating QR code scan: ', e)
        return false
    }
}

const setPostcardScanned = async (postcardId: string): Promise<boolean> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<UpdatePostcardMutation>>({
            query: updatePostcard,
            variables: { input: { id: postcardId, scanned: true } }
        })
        if (rawResult.errors) {
            console.warn("Errors in update postcard scan response: ", rawResult.errors)
            return false
        } else {
            return true
        }
    } catch (e) {
        console.warn('Error while updating postcard scan: ', e)
        return false
    }
}

const setPostcardRecipientId = async (postcardId: string, recipientId: string): Promise<boolean> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<UpdatePostcardMutation>>({
            query: updatePostcard,
            variables: { input: { id: postcardId, recipientId: recipientId } }
        })
        if (rawResult.errors) {
            console.warn("Errors in update postcard recipient id response: ", rawResult.errors)
            return false
        } else {
            return true
        }
    } catch (e) {
        console.warn('Error while updating postcard recipient id: ', e)
        return false
    }
}

const _useAdminQueries = async <T>(path: string, userId: string): Promise<false | T> => {
    let apiName = 'AdminQueries';
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString() as string
    let options = {
        queryParams: {
            username: userId,
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
        }
    }

    try {
        const response = await (await get({apiName, path, options}).response)
        const body = await response.body.json()
        if (response.statusCode !== 200 || !body) {
            console.warn("Error in response from ", path, ": ", body)
            return false
        }
        // @ts-ignore
        return body
    } catch (e) {
        console.warn("Error while calling ", path, ": ", e)
        return false
    }
}

const getUser = async (userId: string): Promise<false | { Enabled: boolean, UserAttributes: { Name: string, Value: string }[], UserStatus: string, Username: string }> => {
    return await _useAdminQueries<{ Enabled: boolean, UserAttributes: { Name: string, Value: string }[], UserStatus: string, Username: string }>('/getUser', userId)
}

const getUserData = async (): Promise<false | { name: string, email: string, address: string }> => {
    try {
        const attributes = await fetchUserAttributes()
        if (attributes.name && attributes.email)
            return { name: attributes.name, email: attributes.email, address: attributes.address ? attributes.address : ''}
        return false
    } catch (e) {
        console.warn('Error while fetching user attributes: ', e)
        return false
    }
}

const setPostcardSettingsReply = async (settingsId: string, replyPostcardId: string): Promise<boolean> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<UpdatePostcardSettingsMutation>>({
            query: updatePostcardSettings,
            variables: { input: { id: settingsId, postcardSettingsReplyId: replyPostcardId } }
        })
        if (rawResult.errors) {
            console.warn("Errors in update postcard setting reply id response: ", rawResult.errors)
            return false
        } else {
            return true
        }
    } catch (e) {
        console.warn('Error while updating postcard setting reply id: ', e)
        return false
    }
}

const getChain = async (id: string): Promise<false | Chain & { postcards: ChainProgressionPostcard[] }> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<GetChainQuery>>({
            query: getChainForProgression,
            variables: { id: id }
        })
        if (rawResult.data && rawResult.data.getChain) {
            const chainData = rawResult.data.getChain
            return {
                id: chainData.id,
                name: chainData.name as string,
                // @ts-ignore
                postcards: chainData.postcards.items.map(postcardSettings => ({
                    id: postcardSettings.postcard.id,
                    location: postcardSettings.postcard.location,
                    message: postcardSettings.postcard.message,
                    settings: {
                        postcardSettingsReplyId: postcardSettings.postcardSettingsReplyId
                    }
                }))
            }
        } else {
            console.warn("Error in fetch chain for progression response: ", rawResult)
            return false
        }
    } catch (e) {
        // @ts-ignore
        if (e.data && e.data.getChain) {
            console.warn('Non breaking error while fetching chain for progression from DB: ', e)
            // @ts-ignore
            return e.data.getChain
        }
        console.warn('Error while fetching chain for progression from DB: ', e)
        return false
    }
}

const getAutocompleteLocations = async (partialLocation: string): Promise<SuggestedLocation[] | false> => {
    try {
        const key = 'pk.7bc1988a14ec571121a4bc01f2bf46d0'
        const limit = 5
        const url = `
            https://api.locationiq.com/v1/autocomplete?key=${key}&q=${partialLocation}&limit=${limit}&dedupe=1&
        `
        const result = await fetch(url)
        const response = await result.json()
        if (result.ok) {
            // @ts-ignore
            return response.map((location) => ({
                latitude: location.lat,
                longitude: location.lon,
                displayName: location.display_place
            }))
        } else {
            console.warn("Error autocomplete location response: ", response.body)
            return false
        }
    } catch (e) {
        console.warn("Error while fetching autocomplete location: ", e)
        return false
    }
}

const getUserGroups = async (userId: string): Promise<false | string[]> => {
    const result = await _useAdminQueries<{ Groups: {Description: string, GroupName: string}[] }>('/listGroupsForUser', userId)
    if (result) {
        return result.Groups.map(group => group.GroupName)
    } else return false
}

const getRawPromoCode = async (userId: string): Promise<false | DBPromoCode> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListPromoCodesQuery>>({
            query: listPromoCodes,
            variables: {
                filter: {
                    owner: {
                        eq: userId
                    }
                }
            }
        })
        if (rawResult.data && rawResult.data.listPromoCodes && rawResult.data.listPromoCodes.items.length >= 1) {
            const promoCodeData = rawResult.data.listPromoCodes.items[0]
            if (promoCodeData === null) return false
            return {
                id: promoCodeData.id,
                active: promoCodeData.active,
                name: promoCodeData.name,
                // @ts-ignore
                orderPromoCode: promoCodeData.orderPromoCode.items.map(orderPromoCode => ({
                    ...orderPromoCode,
                    order: {
                        ...orderPromoCode.order,
                        orderLineItems: orderPromoCode.order.orderLineItems.items
                    }
                }))
            }
        } else {
            console.warn("Error in fetch promo code response: ", rawResult)
            return false
        }
    } catch (e) {
        console.warn("Error while fetching promo code: ", e)
        return false
    }
}

const getActivePromoCodeFromName = async (name: string): Promise<false | IDObject | null> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListPromoCodesQuery>>({
            query: listPromoCodesForValidation,
            variables: {
                filter: {
                    name: {
                        eq: name
                    },
                    active: {
                        eq: true
                    }
                }
            }
        })
        if (rawResult.data && rawResult.data.listPromoCodes) {
            const promoCodesData = rawResult.data.listPromoCodes.items
            if (promoCodesData !== null && promoCodesData.length === 1 && promoCodesData[0] !== null) {
                return { id: promoCodesData[0].id }
            } else return null
        } else {
            console.warn("Error in get promo code from name response: ", rawResult)
            return false
        }
    } catch (e) {
        console.warn("Error while getting promo code from name: ", e)
        return false
    }
}

const getUserOrderWithPromoCodeIfExists = async (userId: string, promoCodeId: string): Promise<false | IDObject | null> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListOrdersQuery>>({
            query: listOrdersForPromoCodes,
            variables: {
                filter: {
                    orderOrderPromoCodeId: {
                        attributeExists: true
                    },
                    owner: {
                        eq: userId + '::' + userId
                    }
                }
            }
        })

        if (rawResult.data && rawResult.data.listOrders && rawResult.data.listOrders.items) {
            const orders = rawResult.data.listOrders.items
            if (orders && orders.length > 0) {
                const orderWithPromoCode = orders.filter(order => {
                    if (!order) return false
                    // @ts-ignore
                    return order.orderPromoCode && order.orderPromoCode.promoCodeOrderPromoCodeId === promoCodeId;
                })
                if (orderWithPromoCode && orderWithPromoCode.length > 0 && orderWithPromoCode[0]) return { id:  orderWithPromoCode[0].id }
                else return null
            } else return null
        } else {
            console.warn("Error in list orders response: ", rawResult)
            return false
        }
    } catch (e) {
        console.warn("Error while listing orders: ", e)
        return false
    }
}

const checkIfPostcardsHaveOrderLineItemAlready = async (ids: string[], isGreetings: boolean): Promise<boolean> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<ListOrderLineItemsQuery>>({
            query: listOrderLineItems,
            variables: {
                filter: {
                    or: ids.map(id => (isGreetings ? {
                        orderLineItemGreetingsPostcardId: { eq: id }
                    } : {
                        orderLineItemPostcardId: { eq: id }
                    }))
                }
            }
        })
        if (rawResult.data && rawResult.data.listOrderLineItems) {
            const data = rawResult.data.listOrderLineItems.items
            return data && data.length > 0;
        } else {
            console.warn("Error in list order line items response: ", rawResult)
            return false
        }
    } catch (e) {
        console.warn("Error while listing order line items: ", e)
        return false
    }
}

const createAddressInDB = async (address: DBAddress): Promise<false | IDObject> => {
    try {
        if (!address.fullName) return false
        if (!address.streetAddress) return false
        if (!address.city) return false
        const rawResult = await client.graphql<GraphQLQuery<CreateAddressMutation>>({
            query: createAddress,
            variables: { input: address }
        })

        if (rawResult.data?.createAddress?.id) {
            return { id: rawResult.data.createAddress.id }
        } else return false
    } catch(e) {
        // Send email to devs
        console.warn("Error while creating new address at DB: ", e)
        return false
    }
}

const createGreetingsAddressInDB = async (address: DBGreetingsAddress): Promise<false | IDObject> => {
    try {
        if (!address.fullName) return false
        if (!address.streetAddress) return false
        if (!address.city) return false
        if (!address.greetingsPostcardRecipientAddressesId) return false
        const rawResult = await client.graphql<GraphQLQuery<CreateGreetingsAddressMutation>>({
            query: createGreetingsAddress,
            variables: { input: address }
        })

        if (rawResult.data?.createGreetingsAddress?.id) {
            return { id: rawResult.data.createGreetingsAddress.id }
        } else {
            console.warn("Error in create new greetings address in DB: " + rawResult.errors)
            return false
        }
    } catch(e) {
        // Send email to devs
        console.warn("Error while creating new greetings address at DB: ", e)
        return false
    }
}

const getPostcardPricingData = async (id: string): Promise<false | PostcardPricingInformation> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<GetPostcardQuery>>({
            query: getPostcardForPricing,
            variables: {id: id}
        })
        if (rawResult.data && rawResult.data.getPostcard) {
            const data = rawResult.data.getPostcard
            if (data.settings) {
                return {
                    id: data.id,
                    replyBackSetting: data.settings.replyBackSetting,
                    chainSetting: data.settings.chainSetting,
                    envelope: !!data.settings.envelope,
                    initialState: PostcardAppInitialState[
                        data.settings.initialState as keyof typeof PostcardAppInitialState
                        ] ?? PostcardAppInitialState.DEFAULT
                }
            } else return false
        } else {
            console.warn("Error in get postcard for pricing response: ", rawResult)
            return false
        }
    } catch (e) {
        console.warn("Error while getting postcard for pricing: ", e)
        return false
    }
}

const _parseQueryResult = (result: GraphQLResult<GraphQLQuery<ListOrderLineItemsQuery>>, dateFrom: string) => {
    return result.data?.listOrderLineItems?.items.filter(a => {
        if (!a) return false
        // @ts-ignore
        return a && a.createdAt > dateFrom
    }).sort((a, b) => {
        if (!a) return 1
        if (!b) return -1
        // @ts-ignore
        if (a.createdAt > b.createdAt) return -1
        else return 1
    })
}

const getUnprocessedPostcards = async (dateFrom: string): Promise<RawPostcard[] | false> => {
    try {
        const result = await client.graphql<GraphQLQuery<ListOrderLineItemsQuery>>({
            query: listOrderLineItemsAndPostcard,
            variables: {
                filter: {
                    or: [{
                        processed: { eq: false }
                    }, {
                        processed: { attributeExists: false }
                    }]
                }
            }
        })
        if (result.data && result.data.listOrderLineItems) {
            // @ts-ignore
            const postcards: any[] = _parseQueryResult(result, dateFrom)?.map(order => order.postcard)
            return _processRawPostcards(postcards)
        } else return false
    } catch (e) {
        // @ts-ignore
        if (e.data) {
            console.warn("Non breaking error while fetching unprocessed postcards: ", e)
            // @ts-ignore
            if (e.data && e.data.listOrderLineItems) {
                // @ts-ignore
                const postcards: any[] = _parseQueryResult(e, dateFrom)?.map(order => order.postcard)
                return _processRawPostcards(postcards)
            } else return false
        } else {
            console.warn("Error while fetching unprocessed postcards: ", e)
        }
        return false
    }
}

const getUnprocessedGreetingsPostcards = async (dateFrom: string): Promise<RawGreetingsPostcard[] | false> => {
    try {
        const result = await client.graphql<GraphQLQuery<ListOrderLineItemsQuery>>({
            query: listOrderLineItemsAndGreetingsPostcard,
            variables: {
                filter: {
                    or: [{
                        processed: { eq: false }
                    }, {
                        processed: { attributeExists: false }
                    }]
                }
            }
        })
        if (result.data && result.data.listOrderLineItems) {
            // @ts-ignore
            const postcards: any[] = _parseQueryResult(result, dateFrom)?.map(order => order.greetingsPostcard)
            return _processRawGreetingsPostcards(postcards)
        } else return false
    } catch (e) {
        // @ts-ignore
        if (e.data) {
            console.warn("Non breaking error while fetching unprocessed postcards: ", e)
            // @ts-ignore
            if (e.data && e.data.listOrderLineItems) {
                // @ts-ignore
                const postcards: any[] = _parseQueryResult(e, dateFrom)?.map(order => order.greetingsPostcard)
                return _processRawGreetingsPostcards(postcards)
            } else return false
        } else {
            console.warn("Error while fetching unprocessed postcards: ", e)
        }
        return false
    }
}

const createGreetingsPostcardInDB = async (postcard: DBGreetingsPostcard): Promise<false | IDObject> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<CreateGreetingsPostcardMutation>>({
            query: createGreetingsPostcard,
            variables: { input: postcard }
        })

        if (rawResult.data?.createGreetingsPostcard?.id) {
            return { id: rawResult.data.createGreetingsPostcard.id }
        } else {
            console.warn("Error in create new greetings postcard in DB: " + rawResult.errors)
            return false
        }
    } catch(e) {
        // Send email to devs
        console.warn("Error while creating new greetings postcard at DB: ", e)
        return false
    }
}

const fromGreetingsPostcardsToPlain = (greetingsPostcards: GreetingsPostcard[]): Postcard[] => {
    const plainPostcards: Postcard[] = []
    for (const postcard of greetingsPostcards) {
        for (const address of postcard.addresses) {
            plainPostcards.push({
                id: postcard.id,
                images: postcard.images,
                frame: postcard.frame,
                layout: postcard.layout,
                message: postcard.message,
                location: postcard.location,
                latitude: postcard.latitude,
                longitude: postcard.longitude,
                font: postcard.font,
                color: postcard.color,
                address: address,
                settings: {
                    id: '',
                    replyBackSetting: REPLY_BACK_SETTING.NONE,
                    chainSetting: CHAIN_SETTING.NONE,
                    replyAddress: null,
                    postcardSettingsRepliesToId: null,
                    postcardSettingsReplyId: undefined,
                    chain: null,
                    envelope: false,
                    initialState: PostcardAppInitialState.DEFAULT
                }
            })
        }
    }
    return plainPostcards
}

const getCartOrCreate = async (userId: string): Promise<false | string> => {
    let rawResult

    // Part I, try and fetch cart
    try {
        rawResult = await client.graphql<GraphQLQuery<GetCartQuery>>({
            query: getCart,
            variables: { id: userId }
        })

        if (rawResult.data) {
            if (rawResult.data.getCart?.id)
                return rawResult.data.getCart.id
        } else {
            console.warn("Error after get cart in DB: ", rawResult.errors)
            return false
        }
    } catch (e) {
        console.warn('Error while fetching cart in DB: ', e)
        return false
    }

    // Part II, create cart for new user
    try {
        rawResult = await client.graphql<GraphQLQuery<CreateCartMutation>>({
            query: createCart,
            // Create cart with same ID as user
            variables: { input: { id: userId } }
        })

        if (rawResult.data.createCart?.id) {
            return rawResult.data.createCart.id
        } else {
            console.warn("Error after create cart in DB: ", rawResult.errors)
            return false
        }
    } catch (e) {
        console.warn("Error while creating cart in DB: ", e)
        return false
    }
}

const createCartItem = async (cartId: string, postcardId: string): Promise<boolean> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<CreateCartItemMutation>>({
            query: _createCartItem,
            variables: {
                input: {
                    cartCartItemsId: cartId,
                    cartItemPostcardId: postcardId
                }
            }
        })

        if (rawResult.data.createCartItem?.id) {
            return true
        } else {
            console.warn("Error after create cart item in DB: ", rawResult.errors)
            return false
        }
    } catch (e) {
        console.warn("Error while creating cart item: ", e)
        return false
    }
}

const _getPostcardsListFromCart = (
    cart: { cartItems: { items: { postcard: any, id: string }[] } }
): { postcard: any, cartItemId: string }[] => {
    return cart.cartItems.items.map(cartItem => ({
        postcard: cartItem.postcard,
        cartItemId: cartItem.id
    }))
}

const getUserCartWithPostcards = async (userId: string): Promise<false | RawCart> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<GetCartQuery>>({
            query: getFullCart,
            variables: { id: userId }
        })

        if (rawResult.data) {
            const cartItems = rawResult.data.getCart?.cartItems
            const cartId = rawResult.data.getCart?.id

            let result: RawCart

            // Check if cart was fetched
            if (cartId) {
                result = {
                    id: cartId,
                    postcards: [],
                }
            } else {
                console.warn("Error after get cart with postcards [no cart ID] in DB: ", rawResult.errors)
                return false
            }

            // Check if there are any postcards in cart
            if (cartItems) {
                // @ts-ignore
                const graphQLPostcards = _getPostcardsListFromCart(rawResult.data.getCart)

                result.postcards = graphQLPostcards.map(postcardData => {
                    const postcard = postcardData.postcard
                    const cartItemId = postcardData.cartItemId
                    postcard.images = postcard.images.items
                    postcard.createdAt = postcard.createdAt.substring(0, 10)
                    return {
                        postcard: _getRawPostcardFromGraphQLResult(postcard),
                        cartItemId: cartItemId
                    }
                })
            }

            return result
        } else {
            console.warn("Error after get cart with postcards in DB: ", rawResult.errors)
            return false
        }
    } catch (e) {
        console.warn("Error while fetching cart with postcards: ", e)
        return false
    }
}

const getUserCartItems = async (userId: string): Promise<false | IDObject[]> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<GetCartQuery>>({
            query: getCartAndItems,
            variables: { id: userId }
        })

        if (rawResult.data) {
            if (rawResult.data.getCart?.cartItems) {
                // @ts-ignore
                return rawResult.data.getCart.cartItems.items.map(item => ({ id: item.id }))
            }
            return false
        } else {
            console.warn("Error after get cart items in DB: ", rawResult.errors)
            return false
        }
    } catch (e) {
        console.warn("Error while fetching cart items: ", e)
        return false
    }
}

const deleteCartItem = async (id: string): Promise<boolean> => {
    try {
        const rawResult = await client.graphql<GraphQLQuery<DeleteCartItemMutation>>({
            query: _deleteCartItem,
            variables: { input: { id: id } }
        })

        if (rawResult.data) return true
        else {
            console.warn("Error after delete cart item in DB: ", rawResult.errors)
            return false
        }
    } catch (e) {
        console.warn("Error while deleting cart item: ", e)
        return false
    }
}

const removePostcardFromCart = async (postcardId: string, cartItemId: string): Promise<boolean> => {
    try {
        const rawResult = await client.graphql({
            query: _removePostcardFromCart,
            variables: {
                postcardInput: { id: postcardId },
                cartItemInput: { id: cartItemId }
            }
        })
        // @ts-ignore
        if (rawResult.data) return true
        else {
            // @ts-ignore
            console.warn("Error after delete postcard and  cart item in DB: ", rawResult.errors)
            return false
        }
    } catch (e) {
        console.warn("Error while deleting postcard and cart item: ", e)
        return false
    }
}

export {
    fetchImageFromUri,
    uploadImageInStorage,
    createImage,
    createImageInDB,
    createPostcardSettings,
    createChain,
    createPostcardInDB,
    createOrder,
    createOrderLineItem,
    getFramesInDB,
    getFrameImage,
    getFontsInDB,
    getFontFile,
    getColorsInDB,
    getUserReceivedPostcards,
    getUserSentPostcards,
    processPostcardRawData,
    processGreetingsPostcardRawData,
    _getImage,
    getCountOfSentPostcards,
    getRawPostcard,
    getGreetingsRawPostcard,
    createQRCodeScan,
    setPostcardScanned,
    setPostcardRecipientId,
    getUser,
    getUserData,
    setPostcardSettingsReply,
    getChain,
    getAutocompleteLocations,
    getUserGroups,
    getRawPromoCode,
    getActivePromoCodeFromName,
    getUserOrderWithPromoCodeIfExists,
    checkIfPostcardsHaveOrderLineItemAlready,
    createAddressInDB,
    createGreetingsAddressInDB,
    getPostcardPricingData,
    getUnprocessedPostcards,
    getUnprocessedGreetingsPostcards,
    createGreetingsPostcardInDB,
    fromGreetingsPostcardsToPlain,
    getCartOrCreate,
    createCartItem,
    getUserCartWithPostcards,
    getUserCartItems,
    deleteCartItem,
    removePostcardFromCart
}
