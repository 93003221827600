import {createContext} from "react";

type IsGreetingsContextProps = {
    isGreetings: boolean
}

const EMPTY_FUNC = (value: boolean) => {}

const IsGreetingsContext = createContext<IsGreetingsContextProps>({
    isGreetings: false
})

export default IsGreetingsContext