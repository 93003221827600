import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";

type EditAddressBoxProps = {
    onClick: () => void
    postcardWidth: number
}

const EditAddressBox = ({ onClick, postcardWidth }: EditAddressBoxProps) => {
    const theme = useTheme();
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{
        width: '100%',
        height: '100%',
        border: '1.5px solid ' + theme.palette.primary.main,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        py: '12px',
        px:  '4px',
        backgroundColor: theme.palette.primary.light,
        '&:hover': {
            boxShadow: '0px 0px 4.2963px rgba(0, 0, 0, 0.25)',
            cursor: 'pointer'
        }
    }} onClick={onClick}>
        <img src={'icons/app/messagestep/plus_sm.svg'} alt={'+'} height={20} width={20} />
        <Typography sx={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            textAlign: 'center',
            fontSize: postcardWidth * (belowMedium ? 0.0180 : 0.0220),
            color: theme.palette.primary.main,
        }}>INSERT THE DESTINATION ADDRESS</Typography>
    </Box>
}

export default EditAddressBox