import {configureStore, createAsyncThunk} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import appReducer from '../components/app/postcard-app/utils/postcardSlice'
import greetingsReducer from '../components/app/greetings-postcard-app/utils/greetingsSlice'
import authSlice from "../components/auth/authSlice";
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import cartSlice from "../components/cart/cartSlice";

const persistConfigApp = {
    key: 'appStore',
    storage,
    whiteList: ['app', 'greetings']
}

const persistConfigGreetings = {
    key: 'greetingsStore',
    storage,
    whiteList: ['app', 'greetings']
}

export const store = configureStore({
    reducer: {
        app: persistReducer(persistConfigApp, appReducer),
        greetings: persistReducer(persistConfigGreetings, greetingsReducer),
        auth: authSlice,
        cart: cartSlice
    }
})

export const persistor = persistStore(store)

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch
// Same for the `RootState` type
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()