import {STEPS} from "../postcardAppUtils";
import {Box, useTheme} from "@mui/material";
import LinkItem from "./LinkItem";

// TODO fix reply free case => dynamically build component with the right steps given the initial state
const CurrentStepBreadcrumbs = () => {
    const theme = useTheme()

    return <Box sx={{
        display: 'flex',
        height: 40,
        py: 0,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 32,
    }}>
        {[STEPS.IMAGE, STEPS.MESSAGE_AND_LOCATION, STEPS.RECIPIENT_AND_SETTINGS, STEPS.CART].map(step => <LinkItem
            key={step.toString()}
            step={step}
        />)}
    </Box>
}

export default CurrentStepBreadcrumbs