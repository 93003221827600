import {Typography} from "@mui/material";
import {useContext} from "react";
import {Address as AddressType} from '../../../../../utils/postcard'
import {PostcardWidthContext} from "../../templates/PostcardTemplate";

const AddressBox = ({ address }: { address: AddressType }) => {
    const postcardWidth = useContext(PostcardWidthContext).width

    return <Typography sx={{ fontFamily: 'Poste', fontSize: postcardWidth * 0.0176, textTransform: 'uppercase' }}>
        {address.fullName} <br />
        {address.streetAddress} <br />
        {address.city} <br />
        {address.zipCode} <br />
    </Typography>
}

export default AddressBox