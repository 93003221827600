import {useNavigate} from "react-router-dom";
import {Box, Button, Card, CardContent, Typography, useMediaQuery, useTheme} from "@mui/material";
import PageWrapper from "../components/PageWrapper";
// @ts-ignore
import { Helmet } from 'react-helmet'
import { Swiper, SwiperSlide } from 'swiper/react';
import {EffectCoverflow, Pagination} from 'swiper/modules';
import React from "react";

const Ambassador = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const belowMd = useMediaQuery(theme.breakpoints.down('sm'));

    const StepCard = ({ src, alt, text }: { src: string, alt: string, text: string }) => <Box sx={{
        width: { xs: 'calc(100% - 32px)', sm: 'calc(50% - 32px)', maxWidth: 280 },
        m: 2
    }}>
        <Card style={{ height: '100%', backgroundColor: theme.palette.secondary.main }}>
            <CardContent>
                <Box sx={{ pb: 2 }}>
                    <img src={src} alt={alt} draggable={false} />
                </Box>
                <Typography>{text}</Typography>
            </CardContent>
        </Card>
    </Box>

    return <PageWrapper>
        <Helmet>
            <meta name="title" content="Become an Ambassador - Memento Card | Diventa un Ambassador - Memento Card" />
            <meta name="description" content="Join the Memento Card Ambassador
program and share your passion for travel and memories. Learn how to
become an ambassador, exclusive benefits, and collaboration opportunities. |
Unisciti al programma Ambassador di Memento Card e condividi la tua
passione tra viaggio e ricordi. Scopri come diventare un ambasciatore, i
vantaggi esclusivi e le opportunità di collaborazione." />
            <meta name="keywords" content="Memento Card Ambassador, become an
ambassador, ambassador program, Memento Card collaborations, exclusive
benefits, share memories, Ambassador Memento Card, diventare
ambasciatore, programma ambassador, collaborazioni Memento Card, vantaggi
esclusivi, condividere ricordi" />
        </Helmet>
        <Box sx={{ display: 'flex', width: 'calc(100% - 48px)', flexDirection: 'column', mb: 4, m: 3, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', maxWidth: 1000, px: { xs: 0, md: 2 }, mb: 3 }}>
                <Typography variant={'h1'} sx={{ fontSize: { xs: 32, md: 64 }, textAlign: 'center', m: { xs: 0, md: 3 }, pb: 3, lineHeight: { xs: '46px', md: '75px' } }}>
                    Memento <span className={ 'header-manual' } style={{ fontSize: belowMd ? 40 : 64 }}>ambassador</span> program
                </Typography>
                {/* @ts-ignore */}
                <Typography variant={'subtitle'} sx={{ color: '#666', textAlign: 'center', mb: 3, fontSize: { xs: 16, md: 24 } }}>
                    Are you an <span style={{ fontWeight: 'bold' }}>influencer</span>, a <span style={{ fontWeight: 'bold' }}>travel coordinator</span> or an <span style={{ fontWeight: 'bold' }}>Hostel owner</span>? Become a Memento Ambassador, get your own promo code and start sharing it!
                </Typography>
                {/* @ts-ignore */}
                <Typography variant={'subtitle'} sx={{ color: '#666', textAlign: 'center', fontSize: { xs: 20, md: 28 } }}>
                    Earn 0.5$ for each order sent!
                </Typography>
                {/* @ts-ignore */}
                <Typography variant={'subtitle'} sx={{ color: '#666', textAlign: 'center', fontSize: { xs: 16, md: 20 }, mb: 3 }}>
                    (+15% discount for your followers/clients)
                </Typography>
                <Button variant={'contained'} onClick={() => navigate('/ambassador-contact')}>
                    Become Ambassador
                </Button>
            </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', mb: 4, alignItems: 'center', py: 3, px: 0, backgroundColor: theme.palette.secondary.main }}>
            <Typography variant={'h4'} sx={{ fontSize: { xs: 30, md: 40 }, textAlign: 'center', px: 3 }}>
                Our <span style={{ color: theme.palette.primary.main }}>Ambassadors</span>
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', py: 4 }}>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    slidesPerView={belowMd ? 1 : 3}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 300
                    }}
                    modules={[EffectCoverflow, Pagination]}
                    style={{ paddingBottom: 32 }}
                    pagination={true}
                >
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/ambassador/ambassadors/image1.png"} alt={"Ambassador"} style={{ width: '100%', height: '70%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/ambassador/ambassadors/image2.png"} alt={"Ambassador"} style={{ width: '100%', height: '70%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/ambassador/ambassadors/image3.png"} alt={"Ambassador"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/ambassador/ambassadors/image4.png"} alt={"Ambassador"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/ambassador/ambassadors/image5.png"} alt={"Ambassador"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <img src={"images/ambassador/ambassadors/image6.png"} alt={"Ambassador"} style={{ width: '100%', height: '100%', aspectRatio: 'auto', boxShadow: '0px 3px 15px rgba(0,0,0,0.2)' }} />
                        </Box>
                    </SwiperSlide>
                </Swiper>
            </Box>
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: 4, alignItems: 'center', px: 3, pb: 6, pt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant={'h4'} sx={{ fontSize: { xs: 30, md: 40 } }}>
                    How does it <span style={{ color: theme.palette.primary.main }}>work?</span>
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', pt: 2, alignItems: { xs: 'center', md: 'stretch' } }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: { xs: '100%', md: '50%' }, alignItems: { xs: 'center', sm: 'stretch' }, justifyContent: { xs: 'center', md: 'end' } }}>
                    <StepCard src={'icons/ambassador/howdoesitwork/request.svg'} alt={'Request'} text={'Request to because Memento Ambassador'} />
                    <StepCard src={'icons/ambassador/howdoesitwork/share.svg'} alt={'Share'} text={'Get your personal promo code and start sharing'} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: { xs: '100%', md: '50%' }, alignItems: { xs: 'center', sm: 'stretch' }, justifyContent: { xs: 'center', md: 'start' } }}>
                    <StepCard src={'icons/ambassador/howdoesitwork/monitor.svg'} alt={'Monitor'} text={'Monitor your sales from a simple dashboard'} />
                    <StepCard src={'icons/ambassador/howdoesitwork/get.svg'} alt={'Get'} text={'Get your part'} />
                </Box>
            </Box>
        </Box>
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', mb: 4, backgroundColor: theme.palette.secondary.main, alignItems: 'center', px: 3, pb: 6, pt: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant={'h4'} sx={{ fontSize: { xs: 30, md: 40 } }}>
                    Our <span style={{ color: theme.palette.primary.main }}>values</span>
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', pt: 2, alignItems: { xs: 'center', md: 'stretch' }, justifyContent: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '90%', md: '30%' } }}>
                    <img alt={'Adventure'} src={'images/ambassador/ourvalues/image1.png'} />
                    {/* @ts-ignore */}
                    <Typography variant={'order-successful'} sx={{ color: '#000', mt: 2, textAlign: 'start', fontStyle: 'italic' }}>
                        <span style={{ textDecoration: 'underline' }}>Adventure</span> as a lifestyle. Desire to discover the world and live unique emotions.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '90%', md: '30%' } }}>
                    <img alt={'Memory'} src={'images/ambassador/ourvalues/image2.png'} />
                    {/* @ts-ignore */}
                    <Typography variant={'order-successful'} sx={{ color: '#000', mt: 2, textAlign: 'start', fontStyle: 'italic'  }}>
                        <span style={{ textDecoration: 'underline' }}>Memory</span> as willingness to capture the moment and make it indelible over time.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '90%', md: '30%' } }}>
                    <img alt={'Sharing'} src={'images/ambassador/ourvalues/image3.png'} />
                    {/* @ts-ignore */}
                    <Typography variant={'order-successful'} sx={{ color: '#000', mt: 2, textAlign: 'start', fontStyle: 'italic'  }}>
                        <span style={{ textDecoration: 'underline' }}>Sharing</span> as desire to share special moments with the important people in your life.
                    </Typography>
                </Box>
            </Box>
            {/* TODO add media query for mobile bg asset transform */}
            <Box className={'ouroffering-bg'} />
        </Box>
        <Box sx={{ display: 'flex', width: 'calc(100% - 48px)', flexDirection: 'column', m: 3, my: 6, alignItems: 'center' }}>
            <Typography variant={'h4'} sx={{ textAlign: 'center', mb: 5, fontSize: { xs: 30, md: 40 } }}>
                Do you share Memento's values? <br />
                What are you waiting for then?
            </Typography>
            <Button variant={'contained'} onClick={() => navigate('/ambassador-contact')}>
                Become Ambassador
            </Button>
        </Box>
    </PageWrapper>
}

export default Ambassador