import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input, Link,
    Typography,
    useTheme
} from "@mui/material";
import React, {ChangeEvent, useRef, useState} from "react";

type EditAddressBoxProps = {
    handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void
    postcardWidth: number
}

const AddAddressesBox = ({ handleFileUpload, postcardWidth }: EditAddressBoxProps) => {
    const theme = useTheme();
    const inputFileRef = useRef<HTMLInputElement>(null)

    const [isOpen, setIsOpen] = useState<boolean>(false)


    const openDialog = () => {
        const inputFile = inputFileRef.current
        if (inputFile) inputFile.click()
    }

    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    return <>
        <input ref={inputFileRef} type={'file'} accept={'.xlsx, .xls, .csv'} onChange={handleFileUpload} style={{
            display: 'none'
        }} />
        <Box sx={{
            width: '100%',
            height: '100%',
            border: '1.5px solid ' + theme.palette.primary.main,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
            py: '12px',
            px:  '4px',
            backgroundColor: theme.palette.primary.light,
            '&:hover': {
                boxShadow: '0px 0px 4.2963px rgba(0, 0, 0, 0.25)',
                cursor: 'pointer'
            }
        }} onClick={openDialog} >
            <img src={'icons/app/messagestep/plus_sm.svg'} alt={'+'} height={20} width={20} />
            <Typography sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 700,
                textAlign: 'center',
                fontSize: postcardWidth * 0.0220,
                color: theme.palette.primary.main,
            }}>UPLOAD YOUR EXCEL FILE WITH ALL THE ADDRESSES</Typography>
        </Box>
        <Typography sx={{ position: 'relative', top: postcardWidth * 0.015, fontSize: postcardWidth * 0.0240, textDecoration: 'underline', textAlign: 'left', '&:hover': { cursor: 'pointer' } }} onClick={() => setIsOpen(true)}>
            Click here for more info
        </Typography>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} sx={{ '& .MuiDialog-paper': { borderRadius: 3 } }}>
            <DialogTitle sx={{ px: 3, pt: 3 }}>
                <Typography variant={'h6'}>How to get all the addresses?</Typography>
            </DialogTitle>
            <DialogContent sx={{ px: 3 }}>
                <Typography variant={'body1'}>
                    Get all the addresses by using your internal <span style={{ fontWeight: 'bold' }}>CRM</span> or just create a simple <span style={{ fontWeight: 'bold' }}>Google Form</span> (like <Link href={'https://forms.gle/EY4iqMdG3F4VcXiT6'} target={'_blank'} sx={{ color: '#666', fontWeight: 'normal', textDecoration: 'underline' }}>this</Link>).
                    Then you will just need to upload your excel!
                </Typography>
                <Typography variant={'body1'}>
                    Please, be sure the excel file follows this <Link href={'greetingstemplate/Memento_Greetings.xlsx'} download sx={{ color: '#666', fontWeight: 'normal', textDecoration: 'underline' }}>template</Link>.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3, display: 'flex', justifyContent: 'center' }}>
                <Button variant={"contained"} onClick={() => {
                    setIsOpen(false)
                }}> Close </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default AddAddressesBox