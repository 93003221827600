import {Address, Font} from "../../../../utils/postcard";
import {Dispatch, SetStateAction} from "react";
import {useMediaQuery, useTheme} from "@mui/material";
import {EditAddressProps} from "../body/address/address";
import PostcardTemplate from "./PostcardTemplate";
import QRCodeImage from "../header/QRCodeImage";
import LocationBox from "../header/location/LocationBox";
import MessageBox from "../body/message/MessageBox";
import EditAddressComponentSmall from "../body/address/EditAddressComponentSmall";
import EditAddressComponentLarge from "../body/address/EditAddressComponentLarge";
import {Location} from "../../../app/common/postcardAppUtils";
import StampImage from "../header/stamps/StampImage";

type RecipientProps = {
    message: string
    font?: Font
    color?: string

    location: Location

    address: Address
    setAddress: (value: Address) => void

    envelope: boolean

    isGiftQRCode: boolean
}

const RecipientPostcardBack = (props: RecipientProps) => {
    const {
        message, font, color,
        location,
        address, setAddress, envelope, isGiftQRCode
    } = props

    const theme = useTheme();
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const editAddressProps: EditAddressProps = {
        address, setAddress
    }

    return <PostcardTemplate
        location={<LocationBox
            location={location}
        />}
        qrcode={<QRCodeImage isGiftQRCode={isGiftQRCode} />}
        message={<MessageBox
            font={font}
            color={color}
            message={message}
        />}
        address={belowMedium ?
            <EditAddressComponentSmall {...editAddressProps} /> :
            <EditAddressComponentLarge {...editAddressProps} />
        }
        stamp={<StampImage envelope={envelope} />}
        showStampBox={!envelope}
    />
}

export default RecipientPostcardBack