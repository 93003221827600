import React from "react";
import {Box, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import Setting from "./setting/Setting";
import RecipientPostcardBack from "../../postcardrecap/postcard/templates/RecipientPostcardBack";
import {RootState, useAppDispatch, useAppSelector} from "../../../utils/store";
import {
    selectApp,
    updateAddress,
    updateChainName,
    updateChainSetting,
    updateReplyBackSetting
} from "./utils/postcardSlice";
import {Address} from "../../../utils/postcard";
import {getPrice, withBooleanSettings, withEnvelope, withLocation} from "../common/postcardAppUtils";

const RecipientStep = () => {
    const stateSelector = (state: RootState) => state.app
    const {
        address,
        location,
        message, font,
        replyBack, chainStartOrContinuation, chainName, envelope
    } = withEnvelope(withBooleanSettings(withLocation(useAppSelector(selectApp))))

    const {
        initialState
    } = useAppSelector(stateSelector)

    const price = getPrice(initialState.initialState)

    const dispatch = useAppDispatch()

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))
    const textFieldDefaults = theme.components?.MuiTextField?.defaultProps?.sx ? theme.components.MuiTextField.defaultProps.sx : {}

    return <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: belowMedium ? 'center' : 'auto', width: belowMedium ? '100%' : 'auto' }}>
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <Box sx={{ width: belowMedium ? '100%' : 600 }} >
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ textAlign: 'center' }} variant={'h6'}>Back</Typography>
            </Box>
            <Box sx={{ width: '100%', px: 1 }}>
                <RecipientPostcardBack
                    message={message}
                    font={font}
                    location={location}
                    address={address}
                    setAddress={(value: Address) => dispatch(updateAddress(value))}
                    isGiftQRCode={replyBack || chainStartOrContinuation}
                    envelope={envelope}
                />
            </Box>
            <Box sx={{ mt: 4 }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant={'h6'}>This postcard</Typography>
                    <Typography variant={'h6'}>{price}€</Typography>
                </Box>
                <Box sx={{ width: '100%', backgroundColor: '#666666', height: '1px', mt: 2 }} />
                <Setting
                    title={'Reply'}
                    message={<>Do you want also a reply back? <br /> Pay in advance your friend's postcard.</>}
                    price={'+2.49€'}
                    value={replyBack}
                    setValue={(value: boolean) => dispatch(updateReplyBackSetting(value))}
                />
                <Setting
                    title={'Chain'}
                    message={<>Start a chain of friends around the world.<br /> Everyone will pay the postcard for the next one.</>}
                    price={'+2.49€'}
                    value={chainStartOrContinuation}
                    setValue={(value: boolean) => dispatch(updateChainSetting(value))}
                />
                {chainStartOrContinuation && <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant={'h6'}>Name of your chain</Typography>
                    <TextField
                        value={chainName}
                        onChange={e=> dispatch(updateChainName(e.target.value))}
                        placeholder={'Chain name'}
                        sx={{ width: '100%', ml: 2, ...textFieldDefaults }}
                    />
                </Box>}
            </Box>
        </Box>

        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}
    </Box>
}

export default RecipientStep