import {Box, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageWrapper from "../components/PageWrapper";

const ComingSoon = () => {
    const theme = useTheme()
    const belowMd = useMediaQuery(theme.breakpoints.down('sm'));

    return <PageWrapper>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography variant={'h1'} sx={{ fontSize: { xs: 40, md: 64 } }}>
                <span className={ 'header-manual' } style={{ fontSize: belowMd ? 48 : 64, color: 'black' }}>Coming soon</span>
            </Typography>
            <Link href={'/'} sx={{ fontSize: { xs: 16, md: 24 }, pt: 6, color: 'black' }}> <ArrowBackIcon/> Go back home</Link>
        </Box>
    </PageWrapper>
}

export default ComingSoon