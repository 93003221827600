import {Amplify} from 'aws-amplify';
import {ThemeProvider as AmplifyThemeProvider} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

import {RouterProvider} from "react-router-dom";
import awsExports from './aws-exports-copy';
import {CssBaseline, ThemeProvider as MuiThemeProvider} from "@mui/material";
import muiTheme from './utils/themes/muiTheme';
import amplifyTheme from "./utils/themes/amplifyTheme";
import router from "./utils/routes";
import AuthenticationWrapper from "./components/auth/AuthenticationWrapper";
// import LogRocket from 'logrocket';

// LogRocket.init('mbdl1m/memento');
Amplify.configure(awsExports);

export default function App() {
    return (
        <AmplifyThemeProvider theme={amplifyTheme}>
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                <AuthenticationWrapper>
                    <RouterProvider router={router} />
                </AuthenticationWrapper>
            </MuiThemeProvider>
        </AmplifyThemeProvider>
    )
}
