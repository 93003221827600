import {useContext} from "react";
import ImagesSelection from "./ImagesSelection";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {getImageFromLayout} from "../../../../utils/layout";
import {getLayouts} from "../../../../api/api";
import IsGreetingsContext from "./isGreetingsContext";
import {RootState, useAppDispatch, useAppSelector} from "../../../../utils/store";
import useActions from "../utils/useActions";

const ImageStep = () => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const stateSelector = (state: RootState) =>
        isGreetings ? state.greetings.postcard : state.app.postcard

    const {
        layout
    } = useAppSelector(stateSelector)

    const dispatch = useAppDispatch()

    const {
        updateLayout
    } = useActions(isGreetings)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'column' : 'row-reverse', alignItems: belowMedium ? 'center' : 'auto' }} >
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <ImagesSelection layout={layout} />

        <Box sx={{ p: belowMedium ? 0 : 2, pt: belowMedium ? 3 : 0, pb: 0, width: belowMedium ? '100%' : 250 }}>
            <Typography variant={'h6'}>Choose the layout</Typography>
            <Box sx={{ p: 1, pt: 2, height: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: belowMedium ? 'row' : 'column', justifyContent: 'space-between', height: '100%', width: belowMedium ? '100%' : 'auto' }}>
                    {getLayouts().map(_layout =>
                        <Box key={_layout} style={{
                            padding: 5,
                            width: belowMedium ? 'auto' : 190,
                            height: belowMedium ? 'auto' : 130,
                            cursor: 'pointer',
                            border: _layout === layout ? '1px solid ' + theme.palette.primary.main : 'none'
                        }}
                             onClick={() => dispatch(updateLayout(_layout))}>
                            <img src={getImageFromLayout(_layout)} alt={'Layout'} style={{ width: belowMedium ? '100%' : 180, height: belowMedium ? '100%' : 120 }} />
                        </Box>)}
                </Box>
            </Box>
        </Box>
    </Box>
}

export default ImageStep