import {Box, Typography} from "@mui/material";
import React from "react";

export type ProfileDetailsProps = {
    name: string,
    profileImgPath: string,
    isAmbassador: boolean,
    postcardsNumber: number,
    replyBacksNumber: number,
    chainsNumber: number
}

export const ProfileDetails = (props: ProfileDetailsProps) =>{
    const {name, profileImgPath, isAmbassador, replyBacksNumber, postcardsNumber, chainsNumber} = props;

    return(
        <Box sx={{display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "center", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "15px",
            marginBottom: "10px", p: 2, pt: 3, textAlign: 'center' }}>

            <Typography sx={{color: "#000000", fontSize:"24px", fontWeight: 500}}>{name}</Typography>

            {isAmbassador &&
                <Box sx={{backgroundColor: "#fa7e48", padding: "2px 15px 2px"}}>
                    <Typography sx={{color:"#FFFFFF", fontSize:"16px", fontWeight: 500}}>Ambassador</Typography>
                </Box>
            }

            <Box sx={{display: "flex", width:"100%", justifyContent: "space-around", marginTop: "20px"}}>
                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: '33%',
                    padding: "5px"}}>
                    <Typography sx={{color: "#fa7e48", fontWeight:700, fontSize: "24px"}}>{postcardsNumber}</Typography>
                    <Typography sx={{color: "#666666", fontWeight:700, fontSize: "16px"}}>Postcards</Typography>
                </Box>

                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: '33%',
                    borderRight: "2px solid #666666", borderLeft: "2px solid #666666", padding: "5px 20px 5px"}}>
                    <Typography sx={{color: "#fa7e48", fontWeight:700, fontSize: "24px"}}>{replyBacksNumber}</Typography>
                    <Typography sx={{color: "#666666", fontWeight:700, fontSize: "16px"}}>Reply Backs</Typography>
                </Box>

                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: '33%',
                    padding: "5px"}}>
                    <Typography sx={{color: "#fa7e48", fontWeight:700, fontSize: "24px"}}>{chainsNumber}</Typography>
                    <Typography sx={{color: "#666666", fontWeight:700, fontSize: "16px"}}>Chains</Typography>
                </Box>
            </Box>
        </Box>
    );
}

