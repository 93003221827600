import {Box} from "@mui/material";

const StampImage = ({ envelope }: { envelope: boolean }) => {
    return envelope ? <Box sx={{
        width: '21.87%',
        height: '100%',
        mr: '1.94%',
        left: 'calc(79.7%)',
        position: 'absolute',
        transform: 'scale(1.3) translateX(22%)'
    }}>
        <img height={'100%'} alt={'Stamp'} src={'images/postcard/stamp.png'}/>
    </Box> : <Box sx={{
        width: '21.87%',
        height: '100%',
        mr: '1.94%',
        left: 'calc(100% - 23.46% - 1.94%)',
        position: 'absolute'
    }}>
        <img width={'100%'} height={'100%'} alt={'Stamp'} src={'images/postcard/stamp_poste.png'} />
    </Box>
}

export default StampImage