import PostcardTemplate from "./PostcardTemplate";
import QRCodeImage from "../header/QRCodeImage";
import {useMediaQuery, useTheme} from "@mui/material";
import {EditLocationProps} from "../header/location/location";
import EditLocationComponentSmall from "../header/location/EditLocationComponentSmall";
import EditLocationComponentLarge from "../header/location/EditLocationComponentLarge";
import {EditMessageProps} from "../body/message/message";
import EditMessageComponentLarge from "../body/message/EditMessageComponentLarge";
import EditMessageComponentSmall from "../body/message/EditMessageComponentSmall";
import {Font} from "../../../../utils/postcard";
import {Location} from "../../../app/common/postcardAppUtils";
import StampImage from "../header/stamps/StampImage";

type MessageAndLocationProps = {
    message: string
    font?: Font
    color?: string
    setMessage:  (value: string) => void

    location: Location
    setLocation:  (value: Location) => void

    envelope: boolean
}

const MessageAndLocationPostcardBack = (props: MessageAndLocationProps) => {
    const {
        message, font, color, setMessage,
        location, setLocation, envelope
    } = props

    const theme = useTheme();
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    const locationComponentProps: EditLocationProps = {
        location, setLocation
    }

    const messageComponentProps: EditMessageProps = {
        message, font, color, setMessage
    }

    return <PostcardTemplate
        location={belowMedium ?
            <EditLocationComponentSmall {...locationComponentProps} /> :
            <EditLocationComponentLarge {...locationComponentProps} />
        }
        qrcode={<QRCodeImage isGiftQRCode={false} />}
        message={belowMedium ?
            <EditMessageComponentSmall {...messageComponentProps} /> :
            <EditMessageComponentLarge {...messageComponentProps} />
        }
        address={<></>}
        stamp={<StampImage envelope={envelope} />}
        showStampBox={!envelope}
    />
}

export default MessageAndLocationPostcardBack