import {
    updateImage as updateAppImage,
    updateLayout as updateAppLayout,
    updateFont as updateAppFont,
    setFontIfNotPresent as setAppFontIfNotPresent,
    updateMessage as updateAppMessage,
    updateLocation as updateAppLocation,
    processGoToStepRequest as processAppGoToStepRequest,
    processNextStepRequest as processAppNextStepRequest,
    processPreviousStepRequest as processAppPreviousStepRequest,
    closeErrorDialog as closeAppErrorDialog,
    addErrorAlert as addAppErrorAlert,
    removeAlert as removeAppAlert,
} from "../../postcard-app/utils/postcardSlice";
import {
    updateImage as updateGreetingsImage,
    updateLayout as updateGreetingsLayout,
    updateFont as updateGreetingsFont,
    setFontIfNotPresent as setGreetingsFontIfNotPresent,
    updateMessage as updateGreetingsMessage,
    updateLocation as updateGreetingsLocation,
    processGoToStepRequest as processGreetingsGoToStepRequest,
    processNextStepRequest as processGreetingsNextStepRequest,
    processPreviousStepRequest as processGreetingsPreviousStepRequest,
    closeErrorDialog as closeGreetingsErrorDialog,
    addErrorAlert as addGreetingsErrorAlert,
    removeAlert as removeGreetingsAlert,
} from "../../greetings-postcard-app/utils/greetingsSlice";

const useActions = (isGreetings?: boolean) => {
    const [
        updateImage,
        updateLayout,
        updateFont,
        setFontIfNotPresent,
        updateMessage,
        updateLocation,
        processGoToStepRequest,
        processNextStepRequest,
        processPreviousStepRequest,
        closeErrorDialog,
        addErrorAlert,
        removeAlert
    ] = isGreetings ? [
        updateGreetingsImage,
        updateGreetingsLayout,
        updateGreetingsFont,
        setGreetingsFontIfNotPresent,
        updateGreetingsMessage,
        updateGreetingsLocation,
        processGreetingsGoToStepRequest,
        processGreetingsNextStepRequest,
        processGreetingsPreviousStepRequest,
        closeGreetingsErrorDialog,
        addGreetingsErrorAlert,
        removeGreetingsAlert
    ] : [
        updateAppImage,
        updateAppLayout,
        updateAppFont,
        setAppFontIfNotPresent,
        updateAppMessage,
        updateAppLocation,
        processAppGoToStepRequest,
        processAppNextStepRequest,
        processAppPreviousStepRequest,
        closeAppErrorDialog,
        addAppErrorAlert,
        removeAppAlert
    ]

    return {
        updateImage,
        updateLayout,
        updateFont,
        setFontIfNotPresent,
        updateMessage,
        updateLocation,
        processGoToStepRequest,
        processNextStepRequest,
        processPreviousStepRequest,
        closeErrorDialog,
        addErrorAlert,
        removeAlert
    }
}

export default useActions