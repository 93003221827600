import PageWrapper from "../components/PageWrapper";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getBackendEnvironment, invoke} from "../utils/utils";
import useUserAttributes from "../utils/useUserAttributes";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {processOrderSubmission} from "../components/app/common/postcardAppUtils";
import {emptyUserCart} from "../api/api";

const PaymentSuccess = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [isLoadingSubmission, setIsLoadingSubmission] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { user } = useAuthenticator()
    const { email, name } = useUserAttributes(user?.userId)

    let time = 0

    const processOrder = async (ids: string[], promoCodeId: string | null): Promise<boolean> => {
        console.log("Processing order with ids: ", ids)
        return await processOrderSubmission(ids, promoCodeId)
    }

    useEffect(() => {
        if (time === 0 && email) {
            // ^^^ Bypass duplicate execution bug after stripe redirect
            // eslint-disable-next-line
            time = Date.now()
            const ids: string[] = (searchParams.get('ids') as string)?.split('$') ?? []
            const promoCodeId: string | null = searchParams.get('promoCodeId')
            ids.length ? processOrder(ids, promoCodeId)
                .then(result => {
                    if (!result) {
                        setErrorMessage('Error during order submission')
                        // TODO notify error to devs via email (critical customer case)
                        return false
                    } else {
                        emptyUserCart(user.userId)

                        const environment = getBackendEnvironment()
                        return invoke(`stampyemailsender-${environment}`, {
                            subject: 'Order confirmation',
                            message: `
Hey ${name}, your Memento order is confirmed

We will print and ship the postcard for you, It will be soon in the hands of your loved ones. 

Order details:
- Postcard ids: ${ids.join(', ')}
- Postcard sent: ${ids.length}

Looking forward to helping you share your next adventure! 

Memento Team 
                            `,
                            recipient: email
                        })
                    }
                })
                .then(_ => setIsLoadingSubmission(false)) : (() => {
                    setErrorMessage('Bad request')
                    setIsLoadingSubmission(false)
                })()
        }
        // eslint-disable-next-line
    }, [email])

    return <PageWrapper>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column', px: 2 }}>
            {isLoadingSubmission ? <CircularProgress sx={{ '& svg': { transform: 'scale(3) !important' }, marginBottom: 16 }} /> : errorMessage === '' && <img src={'icons/app/order_confirmation.svg'} alt={'Success!'} style={{marginBottom: 16}}/>}
            {isLoadingSubmission ?
                <Typography variant={'h4'}>Your order is being processed...</Typography> :
                (errorMessage === '' ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, textAlign: 'center' }}>
                        <Typography variant={'h4'}>Order successful!</Typography>
                        {/* @ts-ignore */}
                        <Typography variant={'order-successful'}>Please check your email for order confirmation</Typography>
                    </Box> :
                    <Typography variant={'h4'}>{errorMessage}</Typography>)
            }
            <Box sx={{ mt: 2, gap: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button variant={'contained'} onClick={() => navigate('/app')}>
                    Send a new postcard
                </Button>
                {/* TODO setup actual redirection to my postcards section in profile page */}
                <Button variant={'contained'} onClick={() => navigate('/profile')}>
                    See your postcards
                </Button>
            </Box>
        </Box>
    </PageWrapper>
}

export default PaymentSuccess