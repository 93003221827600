import PageWrapper from "../PageWrapper"
import {useAuthenticator} from "@aws-amplify/ui-react";
import useUserAttributes from "../../utils/useUserAttributes";
import useIsAmbassador from "../../utils/useIsAmbassador";
import React, {useEffect, useState} from "react";
import {getCurrentUser} from "aws-amplify/auth";
import {getPromoCode} from "../../api/api";
import {
    Typography,
    Box,
    IconButton,
    useTheme,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery
} from "@mui/material";
import {useNavigate} from "react-router-dom";

const AmbassadorDashboard = () => {
    const navigate = useNavigate()
    const {user} = useAuthenticator()
    const { name } = useUserAttributes(user?.userId)
    const { isAmbassador, isLoadingAmbassador } = useIsAmbassador(user?.userId)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [promoCodeName, setPromoCodeName] = useState<string>('')
    const [postcardsToRedeemCount, setPostcardsToRedeemCount] = useState<number>(-1)

    const [templatesAccordionOpen, setTemplatesAccordionOpen] = useState<boolean>(false)
    const [howDoesItWorkAccordionOpen, setHowDoesItWorkAccordionOpen] = useState<boolean>(false)

    const theme = useTheme()
    const belowMd = useMediaQuery(theme.breakpoints.down('md'));

    const fetchPromoCode = async () => {
        const { userId } = await getCurrentUser()
        if (userId) {
            return await getPromoCode(userId)
        }
        return false
    }

    useEffect(() => {
        if (!isLoadingAmbassador && isAmbassador) {
            fetchPromoCode()
                .then(result => {
                    if (result) {
                        setPromoCodeName(result.name)
                        setPostcardsToRedeemCount(result.postcardsToRedeemCount)
                    } else {
                        setErrorMessage('Unable to fetch promo code')
                    }
                })
        }
    }, [isAmbassador, isLoadingAmbassador])

    const promoCodeLink = '/app'
    const fullUrl = 'https://' + window.location.hostname + promoCodeLink

    return isAmbassador ? <Box sx={{display: "flex", flexDirection: "column", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "10px",
        marginBottom: "30px", py: 3, px: belowMd ? 2 : 4, border: "1px solid #fa7e48"}}>

        <Typography sx={{fontWeight: 500, fontSize: { xs: 32, md: 40 }, color: "#666666",
            marginBottom: "15px"}}>Ambassador</Typography>

        <Box sx={{display: "flex", flexDirection: "column", border: '2px solid rgba(102, 102, 102, 0.08)', borderRadius: "9px",
            marginBottom: "25px", padding: "15px"}}>
            <Typography sx={{fontWeight: 550, fontSize: { xs: 18, md: 23 }, color: "#000000", marginBottom:"15px"}}>
                Help others share their adventures and earn money from it!
            </Typography>
            <Typography sx={{fontWeight: 700, fontSize: { xs: 14, md: 18 }, color: "#666666", marginBottom:"10px"}}>
                Promo Code: {promoCodeName === '' ? 'No promo code' : promoCodeName}
            </Typography>

            <Box sx ={{display:"flex", width:"100%", marginBottom: "15px", flexDirection: 'row'}}>
                <Box sx ={{display:"flex", justifyContent:"center", alignItems: 'center', border:"1px solid black",
                    borderRadius: "15px", flex:"2", width:"100%", paddingTop: "5px",
                    paddingBottom: "5px"}}>
                    <Typography sx={{color: "#000000", fontSize: { xs: 14, md: 'auto' } }}>
                        {fullUrl}
                    </Typography>
                </Box>
                <IconButton onClick={() => {navigator.clipboard.writeText(fullUrl)}} sx={{ px: 1, py: 0 }}>
                    <img alt={'copy'} src={'icons/profile/copy.svg'} draggable={false} />
                </IconButton>
            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", marginBottom: "15px", flexDirection: 'row'}}>
                <Typography sx={{fontWeight: 400, color: "#000000",
                    fontSize: { xs: 18, md: 24 }}}>Postcards sent with your link:</Typography>
                <Typography sx={{fontWeight: 400, color: "#000000",
                    fontSize: { xs: 18, md: 24 }}}>{postcardsToRedeemCount !== -1 ? postcardsToRedeemCount : 'None'}</Typography>
            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", marginBottom: "5px", flexDirection: 'row'}}>
                <Typography sx={{fontWeight: 400, color: "#000000",
                    fontSize: { xs: 18, md: 24 }}}>Your revenues:</Typography>
                <Typography sx={{fontWeight: 400, color: "#000000",
                    fontSize: { xs: 18, md: 24 }}}>{postcardsToRedeemCount !== -1 ? postcardsToRedeemCount * 0.5 : 0}€</Typography>
            </Box>
        </Box>

        <Accordion sx={{ borderRadius: "9px", '&::before': { opacity: '0!important' }, border: '2px solid rgba(102, 102, 102, 0.08)',
            marginBottom: "20px", padding: "15px", boxShadow: 'none' }} onChange={() => setTemplatesAccordionOpen(!templatesAccordionOpen)}>
            <AccordionSummary expandIcon={<img alt={'Expand'} src={templatesAccordionOpen ? 'icons/profile/open_accordion_arrow.svg' : 'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                <Typography sx={{color: templatesAccordionOpen ? theme.palette.primary.main : "#666666", fontSize: "24px", fontWeight: 500, pr: 2}}>Standard templates to share</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: belowMd ? 'column' : 'row', width: '100%', justifyContent: 'space-between' }}>
                    <Box sx={{ p: 1, width: belowMd ? '100%' : '44.38%' }}>
                        <Typography variant={'body1'} sx={{ py: 1 }}>
                            On your stories:
                        </Typography>
                        <img alt={'Instagram template'} src={'images/profile/ig_template_example.jpeg'} style={{ width: '100%', aspectRatio: 'preserve' }}/>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant={'body1'}>
                                Download template:
                            </Typography>
                            <IconButton component={'a'} download href={'images/profile/ig_template_empty.jpeg'}>
                                <img alt={'Download'} src={'icons/profile/save.svg'} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ p: 1, width: belowMd ? '100%' : '55.62%' }}>
                        <Typography variant={'body1'} sx={{ py: 1 }}>
                            In your hostel:
                        </Typography>
                        <img  alt={'Hostel template'} src={'images/profile/hostel_template.jpeg'} style={{ width: '100%', aspectRatio: 'preserve' }}/>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant={'body1'}>
                                Download template:
                            </Typography>
                            <IconButton component={'a'} download href={'images/profile/hostel_template.jpeg'}>
                                <img alt={'Download'} src={'icons/profile/save.svg'} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>

        <Accordion sx={{ borderRadius: "9px", '&::before': { opacity: '0!important' }, border: '2px solid rgba(102, 102, 102, 0.08)',
            marginBottom: "20px", padding: "15px", boxShadow: 'none' }} onChange={() => setHowDoesItWorkAccordionOpen(!howDoesItWorkAccordionOpen)}>
            <AccordionSummary expandIcon={<img alt={'Expand'} src={howDoesItWorkAccordionOpen ? 'icons/profile/open_accordion_arrow.svg' : 'icons/profile/accordion_arrow.svg'} draggable={false} />}>
                <Typography sx={{color: howDoesItWorkAccordionOpen ? theme.palette.primary.main : "#666666", fontSize: "24px", fontWeight: 500}}>How does it work?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ul>
                    <li>
                        <Typography variant={'body1'}>
                            Earn 0.5€ for each postcard sent through your promo code
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'body1'}>
                            15% discount for those who use your promo code
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'body1'}>
                            Reach 50 postcards sent to unlock the “redeem your money” request
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'body1'}>
                            Get your money with Amazon Gift Card or Invoice
                        </Typography>
                    </li>
                </ul>
            </AccordionDetails>
        </Accordion>

        <Box sx={{display: "flex", marginBottom: "10px", gap: 1}}>
            <Typography sx={{color: "#000000", fontWeight: 400, fontSize: { xs: 16, md: 18 }, marginBottom: "15px"}}>
                Do you want to redeem your money? <a style={{ color: 'black', textDecoration: "underline" }} href={'mailto:admin@memento-card.it'}>CLICK HERE</a>
            </Typography>
        </Box>

        <Box sx={{display: "flex", marginBottom: "10px", gap: 1}}>
            <Typography sx={{color: "#000000", fontWeight: 400, fontSize: { xs: 16, md: 18 }}}>
                Do you want to suspend your ambassador program? <a style={{ color: 'black', textDecoration: "underline" }} href={'mailto:admin@memento-card.it'}>CONTACT US</a>
            </Typography>
        </Box>
    </Box> : null
}

export default AmbassadorDashboard