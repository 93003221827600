import React, {createContext, Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Box} from "@mui/material";
import LogoBox from "../body/LogoBox";
import StampBox from "../body/StampBox";
import StampImage from "../header/stamps/StampImage";

type PostcardTemplateProps = {
    location: React.ReactNode
    qrcode: React.ReactNode
    message: React.ReactNode
    address: React.ReactNode
    stamp: React.ReactNode
    showStampBox: boolean
}

const PostcardWidthContext = createContext<{ width: number, setWidth: Dispatch<SetStateAction<number>> }>({ width: 0, setWidth: () => {} })

const PostcardTemplate = ({ location, qrcode, message, address, stamp, showStampBox }: PostcardTemplateProps) => {
    const [width, setWidth] = useState<number>(0)
    const container = useRef<HTMLDivElement>()

    useEffect(() => {
        if (container.current)
            setWidth(container.current?.offsetWidth)
    }, [container])

    return <PostcardWidthContext.Provider value={{ width, setWidth }}>
        <Box sx={{ width: '100%', aspectRatio: 15/10, display: 'flex', flexDirection: 'column', pt: '2.82%', backgroundColor: 'white', boxShadow: '0px 0px 4.2963px rgba(0, 0, 0, 0.25)', boxSizing: 'border-box' }} ref={container}>
            <Box sx={{ width: '100%', height: '18.78%', position: 'relative' }}>
                {/* Postcard header */}
                {location}
                {qrcode}
                {stamp}
            </Box>
            <Box sx={{ width: '100%', height: '3.22%' }} />
            <Box sx={{ width: '100%', height: 'calc(62.25% - 10.50%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {/* Postcard body */}
                {message}
                <LogoBox postcardWidth={width}/>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '47.27%' }}>
                    <StampBox postcardWidth={width} show={showStampBox} />
                    <Box sx={{ pl: '22.01%', pt: '7.83%', pr: '21.27%', pb: '3.36%' }}>
                        <Box sx={{ width: '100%' }}>
                            {address}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ width: '100%', height: 'calc(15.75% + 10.50%)' }} />
        </Box>
    </PostcardWidthContext.Provider>
}

export default PostcardTemplate
export { PostcardWidthContext }