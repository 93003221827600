import React, {useEffect, useState} from "react";
import PostcardRecap from "../PostcardRecap";
import {CHAIN_SETTING, Font, Postcard, REPLY_BACK_SETTING} from "../../utils/postcard";
import {Box, Button, CircularProgress, Divider, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {getFonts} from "../../api/api";

type MyPostcardsProps = {
    sentPostcards: Postcard[]
    receivedPostcards: Postcard[]
    isLoadingSentPostcards: boolean
    isLoadingReceivedPostcards: boolean
    errorMessageSent: string
    errorMessageReceived: string
}

const MyPostcards = ({ sentPostcards, receivedPostcards, isLoadingSentPostcards, isLoadingReceivedPostcards, errorMessageSent, errorMessageReceived }: MyPostcardsProps) => {
    const [showSentPostcards, setShowSentPostcards] = useState<boolean>(true)
    const [filterPendingReplyBack, setFilterPendingReplyBack] = useState<boolean>(false)
    const [filterPendingChains, setFilterPendingChains] = useState<boolean>(false)

    const [fonts, setFonts] = useState<Font[]>([])

    const navigate = useNavigate()
    const theme = useTheme()
    const belowMd = useMediaQuery(theme.breakpoints.down('md'));

    const fetchFonts = async () => {
        const _fonts = await getFonts()
        if (_fonts) {
            setFonts(_fonts)
        }
        return _fonts
    }

    const loadFonts = async (fonts: Font[]) => {
        let fontFaces = []
        let i = 0, actualFonts = []
        for (const font of fonts) {
            try {
                const fontFace = await new FontFace(font.name, `url(${font.file})`);// @ts-ignore
                (document as any).fonts.add(fontFace)
                fontFaces.push(fontFace)
            } catch (e) { }
        }
        for (const fontFace of fontFaces) {
            try {
                await fontFace.load()
                actualFonts.push(fonts[i])
            } catch (e) { }
            i++
        }
        // Ensure unloaded fonts do not make it into the UI
        setFonts(actualFonts)
    }

    useEffect(() => {
        fetchFonts()
            .then((fonts) => {
                if (fonts) return loadFonts(fonts)
            })
    }, [])

    return <Box sx={{display: "flex", flexDirection: "column", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "10px",
        marginBottom: "10px", py: 3, px: belowMd ? 2 : 4}}>

        <Typography sx={{color: "#666666", fontWeight: 500, fontSize: { xs: 32, md: 40 }, marginBottom: "15px"}}>My postcards</Typography>

        <Box sx={{display: "flex", justifyContent: "space-between", padding: "5px", marginBottom: "15px"}}>
            <Box sx={{display: "flex"}}>
                <Typography
                    sx={showSentPostcards ?
                        {color: "#fa7e48", fontSize: { xs: 24, md: 30 }, fontWeight:400, cursor: "pointer",
                            marginRight: "25px", textDecoration: "underline", transition: "color 0.5s",
                            "&:hover": {
                                color: "#b44e13",
                            }
                        }
                        :
                        {color: "#fa7e48", fontSize: { xs: 24, md: 30 }, fontWeight:400, cursor: "pointer",
                            marginRight: "25px", transition: "color 0.5s",
                            "&:hover": {
                                color: "#b44e13",
                            }
                        }
                    }
                    onClick={() => setShowSentPostcards(true)}
                >
                    Sent
                </Typography>
                <Typography
                    sx={!showSentPostcards ?
                        {
                            color: "#fa7e48", fontSize: { xs: 24, md: 30 }, fontWeight: 400, cursor: "pointer",
                            marginRight: "25px", textDecoration: "underline", transition: "color 0.5s",
                            "&:hover": {
                                color: "#b44e13",
                            }
                        }
                        :
                        {color: "#fa7e48", fontSize: { xs: 24, md: 30 }, fontWeight:400, cursor: "pointer",
                            marginRight: "25px", transition: "color 0.5s",
                            "&:hover": {
                                color: "#b44e13",
                            }
                        }
                    }
                    onClick={() => setShowSentPostcards(false)}
                >
                    Received
                </Typography>
            </Box>

            {/*<Box sx={{display: "flex", border: "1px solid #fa7e48", borderRadius: "10px"}}>
                            <Button sx={{textTransform: "none"}}>Show on a List</Button>
                        </Box>*/}
        </Box>

        <Divider sx={{ mb: 3 }} />

        {showSentPostcards ? (isLoadingSentPostcards ?
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}>
                    <CircularProgress sx={{ '& svg': { transform: 'scale(3) !important' }, marginBottom: 16 }} />
                </Box> : (errorMessageSent === '' ?
                    <Box>
                        {sentPostcards.length !== 0 ? sentPostcards.map(postcard =>
                            <Box key={postcard.id}>
                                <PostcardRecap postcard={postcard} isMyPostcardPage={true} />
                            </Box>) : <Box>
                            <Typography variant={'body1'} sx={{ pb: 2 }}>Your haven't sent any postcards yet</Typography>
                            <Button variant={'contained'} onClick={() => navigate('/app')}>Send your first postcard</Button>
                        </Box>}
                    </Box> :
                    <Typography>{errorMessageSent}</Typography>)
            ) : (isLoadingReceivedPostcards ?
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}>
                    <CircularProgress sx={{ '& svg': { transform: 'scale(3) !important' }, marginBottom: 16 }} />
                </Box> :
                (errorMessageReceived === '' ?
                    <Box>
                        {receivedPostcards.length !== 0 ? receivedPostcards.filter(postcard => {
                            if (filterPendingReplyBack) {
                                return postcard.settings.replyBackSetting === REPLY_BACK_SETTING.REPLY_PAID && !postcard.settings.postcardSettingsReplyId;
                            } else if (filterPendingChains) {
                                return (postcard.settings.chainSetting === CHAIN_SETTING.CHAIN_PAID || postcard.settings.chainSetting === CHAIN_SETTING.CHAIN) && !postcard.settings.postcardSettingsReplyId;
                            } else return true
                        }).map(postcard =>
                            <Box key={postcard.id}>
                                <PostcardRecap postcard={postcard} isMyPostcardPage={true} />
                            </Box>) : <Box>
                                <Typography variant={'body1'}>Your haven't received any postcards yet</Typography>
                            </Box>
                        }
                    </Box> :
                    <Typography>{errorMessageReceived}</Typography>)
            )
        }
    </Box>
}

export default MyPostcards