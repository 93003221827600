import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Divider, Typography, useMediaQuery, useTheme} from "@mui/material";
import AuthenticatorWrapper from "../common/AuthenticatorWrapper";
import AddressTextField from "../../AddressTextField";
import {useAppDispatch, useAppSelector} from "../../../utils/store";
import {fetchUserAddress, selectApp, updateReplyAddress} from "./utils/postcardSlice";
import {withReplyAddress} from "../common/postcardAppUtils";
import {Address} from "../../../utils/postcard";
import {isFulfilled} from "../../../utils/utils";

const InnerPersonalAddress = () => {
    const [userAddress, setUserAddress] = useState<Address | null>(null)

    const { replyAddress } = withReplyAddress(useAppSelector(selectApp))

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchUserAddress())
            .then(data => {
                if (isFulfilled(data.type) && data.payload) {
                    console.log("PL: ", data.payload)
                    setUserAddress(data.payload as Address)
                }
            })
    }, [])

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{ display: 'flex' }}>
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <Card sx={{ width: belowMedium ? '100%' : 600, borderRadius: 3, borderWidth: 1, p: 5 }}>
            {!userAddress ? <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', mb: 1 }}>
                <Typography variant={'h6'}>Write your address for the reply back</Typography>
                <Divider />
            </Box> : <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', mb: 1 }}>
                <Typography variant={'h6'}>Do you want to modify your address for the reply back?</Typography>
                <Divider />
            </Box>}
            <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 0, mb: 2 }}>
                <AddressTextField
                    placeholder={'FULL NAME'}
                    value={replyAddress.fullName}
                    onChange={e => dispatch(updateReplyAddress({ ...replyAddress, fullName: e.target.value }))}
                />
                <AddressTextField
                    placeholder={'STREET ADDRESS'}
                    value={replyAddress.streetAddress}
                    onChange={e => dispatch(updateReplyAddress({ ...replyAddress, streetAddress: e.target.value }))}
                />
                <AddressTextField
                    placeholder={'CITY'}
                    value={replyAddress.city}
                    onChange={e => dispatch(updateReplyAddress({ ...replyAddress, city: e.target.value }))}
                />
                <AddressTextField
                    placeholder={'ZIP CODE'}
                    value={replyAddress.zipCode}
                    onChange={e => dispatch(updateReplyAddress({ ...replyAddress, zipCode: e.target.value }))}
                />
            </CardContent>
            {userAddress && <Button
                variant={'contained'}
                onClick={() => dispatch(updateReplyAddress(userAddress))}
                disabled={userAddress === replyAddress}
            >Reset</Button>}
        </Card>

        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}
    </Box>
}

const PersonalAddress = () => {
    return <AuthenticatorWrapper>
        <InnerPersonalAddress />
    </AuthenticatorWrapper>
}

export default PersonalAddress
