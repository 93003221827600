import {Font} from "../../../../utils/postcard";
import {getFonts, loadFonts} from "../../../../api/api";
import {defer} from "react-router-dom";
import {Amplify} from "aws-amplify";
import awsExports from "../../../../aws-exports-copy";
import useActions from "./useActions";
import {store} from "../../../../utils/store";
import {sortFonts} from "../postcardAppUtils";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";

export type Fonts = { fonts: Font[] }

export const fetchFonts = async (setFontIfNotPresent: ActionCreatorWithPayload<Font, "greetings/setFontIfNotPresent"> | ActionCreatorWithPayload<Font, "app/setFontIfNotPresent">) => {
    Amplify.configure(awsExports)
    const fonts = await getFonts()
    if (fonts)
        await loadFonts(fonts)
    else
        throw new Error('Failed to fetch fonts')

    // Set first displayed font as default font
    const sortedFonts = sortFonts(fonts)
    // NOTE: This MAY cause issues if we were to implement SSR in the future
    if (sortedFonts.length) store.dispatch(setFontIfNotPresent(sortedFonts[0]))
    return sortedFonts
}

export const getFontsFetcher = (isGreetings: boolean) => () => {
    const { setFontIfNotPresent } = useActions(isGreetings)
    return defer({ fonts: fetchFonts(setFontIfNotPresent) })
}
