import {Box, Typography} from "@mui/material";
import {useContext} from "react";
import {PostcardWidthContext} from "../templates/PostcardTemplate";

const QRCodeImage = ({ isGiftQRCode, postcardWidth }: { isGiftQRCode: boolean, postcardWidth?: number }) => {
    const { width } = useContext(PostcardWidthContext)
    if (!postcardWidth) postcardWidth = width

    return <Box sx={{ width: '20.10%', left: 'calc(50% - 10.05%)', position: 'absolute', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img width={'50%'} style={{ aspectRatio: 1 }} alt={'QR code'} src={'images/postcard/qrcode.jpg'} />
        <Typography sx={{ fontSize: postcardWidth * 0.0120, paddingTop: postcardWidth * 0.01 / 8, width: '100%', textAlign: 'center' }}>{ isGiftQRCode ? 'SCAN ME... THERE IS AN EXTRA GIFT FOR YOU!' : 'SCAN ME!' }</Typography>
    </Box>
}

export default QRCodeImage