import React from "react";
import CustomAuthenticator from "../../CustomAuthenticator";
import {useAppSelector} from "../../../utils/store";
import {selectAuth} from "../../auth/authSlice";

function AuthenticatorWrapper({ children }: { children: JSX.Element }) {
    const {
        isLoggedIn
    } = useAppSelector(selectAuth)

    return isLoggedIn ? children : <CustomAuthenticator />
}

export default AuthenticatorWrapper