import {getState} from "../common/postcardAppUtils";
import {PostcardAppState} from "./utils/PostcardAppInitialState";
import React, {useEffect} from "react";
import ImageStep from "../common/imagesselection/ImageStep";
import MessageAndLocationStep from "../common/MessageAndLocationStep";
import RecipientStep from "./RecipientStep";
import PersonalAddress from "./PersonalAddress";
import CartStep from "./CartStep";
import {useLocationState} from "../../../utils/useLocationState";
import IsGreetingsContext from "../common/imagesselection/isGreetingsContext";
import {RootState, useAppDispatch, useAppSelector} from "../../../utils/store";
import {processInitialState} from "./utils/postcardSlice";
import AppPage from "../common/AppPage";

export const InnerPostcardAppPage = () => {
    const state = getState(useLocationState<PostcardAppState>())

    useEffect(() => {
        dispatch(processInitialState(state))
    }, []);

    const stateSelector = (state: RootState) => state.app

    const {
        step
    } = useAppSelector(stateSelector)

    const dispatch = useAppDispatch()

    const stepsComponents = [
        <ImageStep/>,
        <MessageAndLocationStep/>,
        <RecipientStep />,
        <PersonalAddress />,
        <CartStep />
    ]

    return <IsGreetingsContext.Provider value={{ isGreetings: false }}>
        <AppPage steps={stepsComponents} step={step} />
    </IsGreetingsContext.Provider>
}
