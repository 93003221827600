import {Button, Card, Box, CardContent, CardHeader, Typography, CircularProgress} from "@mui/material";
import AddressTextField from "../AddressTextField";
import React, {useState} from "react";
import useUserAttributes from "../../utils/useUserAttributes";
import {useAuthenticator} from "@aws-amplify/ui-react";

const AddressWidget = () => {
    const {user} = useAuthenticator()
    const { address, updateAddress, setAddress } = useUserAttributes(user?.userId)
    const [isUpdatingAddress, setIsUpdatingAddress] = useState(false)

    const handleAddressUpdate = async () => {
        setIsUpdatingAddress(true)
        if (address)
            await updateAddress(address)
        setIsUpdatingAddress(false)
    }

    return <Card sx={{ p: 3, borderRadius: 3, boxShadow: 'none!important' }}>
        <Box>
            <Typography sx={{color: "#666666", fontSize: { xs: 32, md: 40 }, fontWeight: 500}}>Personal info</Typography>
        </Box>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 0, mb: 2 }}>
            {/* @ts-ignore */}
            <AddressTextField placeholder={'FULL NAME'} value={address?.fullName} onChange={e => setAddress({ ...address, fullName: e.target.value })} />
            {/* @ts-ignore */}
            <AddressTextField placeholder={'STREET ADDRESS'} value={address?.streetAddress} onChange={e => setAddress({ ...address, streetAddress: e.target.value })} />
            {/* @ts-ignore */}
            <AddressTextField placeholder={'CITY'} value={address?.city} onChange={e => setAddress({ ...address, city: e.target.value })} />
            {/* @ts-ignore */}
            <AddressTextField placeholder={'ZIP CODE'} value={address?.zipCode} onChange={e => setAddress({ ...address, zipCode: e.target.value })} />
        </CardContent>
        <Box>
            <Button variant={'contained'} sx={{ width: 98, textTransform: 'capitalize', fontWeight: 'bold' }} onClick={() => address && handleAddressUpdate()} disabled={!address || !address.streetAddress || !address.city || !address.fullName || !address.zipCode}>
                { isUpdatingAddress ? <CircularProgress sx={{ width: '28px !important' }} /> : 'Update' }
            </Button>
        </Box>
    </Card>
}

export default AddressWidget