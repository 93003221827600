import {useEffect, useState} from "react"
import {getUserGroups} from "../api/api";

const useIsAmbassador = (userId: string | undefined) => {
    const [isAmbassador, setIsAmbassador] = useState<boolean | undefined>(undefined)
    const [isLoadingAmbassador, setIsLoadingAmbassador] = useState<boolean>(true)

    const fetchUserGroups = async () => {
        if (userId) {
            const result = await getUserGroups(userId)
            setIsLoadingAmbassador(false)
            if (result) {
                if (result.includes("Ambassadors"))
                    setIsAmbassador(true)
                else
                    setIsAmbassador(false)
            } else {
                setIsAmbassador(false)
                return false
            }
        }
        return true
    }

    useEffect(() => {
        if (userId)
            fetchUserGroups()
                .then(ignored => { })
        // eslint-disable-next-line
    }, [userId])

    return {isAmbassador, isLoadingAmbassador}
}

export default useIsAmbassador