import {
    ChainProgressionPostcard,
    checkIfPostcardsHaveOrderLineItemAlready,
    createAddressInDB,
    createCartItem,
    createChain,
    createGreetingsAddressInDB,
    createGreetingsPostcardInDB,
    createImage,
    createOrder,
    createOrderLineItem,
    createPostcardInDB,
    createPostcardSettings,
    createQRCodeScan,
    DBAddress,
    DBFont,
    DBFrame,
    DBGreetingsAddress,
    DBGreetingsPostcard,
    DBImage,
    DBPostcard,
    DBPostcardSettings, deleteCartItem,
    fetchImageFromUri,
    fromGreetingsPostcardsToPlain,
    getActivePromoCodeFromName,
    getAutocompleteLocations as _getAutocompleteLocations,
    getCartOrCreate,
    getChain,
    getColorsInDB,
    getFontFile,
    getFontsInDB,
    getFrameImage,
    getFramesInDB, getGreetingsRawPostcard,
    getPostcardPricingData,
    getRawPostcard,
    getRawPromoCode,
    getUnprocessedGreetingsPostcards,
    getUnprocessedPostcards,
    getUser, getUserCartItems, getUserCartWithPostcards,
    getUserData as _getUserData,
    getUserGroups as _getUserGroups,
    getUserOrderWithPromoCodeIfExists,
    getUserReceivedPostcards as _getUserReceivedPostcards,
    getUserSentPostcards as _getUserSentPostcards,
    GREETINGS_20_PROMO_CODE,
    IDObject,
    LAUNCH_PROMO_CODE,
    PostcardPricingInformation,
    processGreetingsPostcardRawData,
    processPostcardRawData, removePostcardFromCart,
    setPostcardRecipientId as _setPostcardRecipientId,
    setPostcardSettingsReply,
    SuggestedLocation
} from "./apiUtils";
import {
    Chain,
    CHAIN_SETTING,
    Font,
    Frame,
    GreetingsPostcard,
    MessageColor,
    Postcard,
    REPLY_BACK_SETTING
} from "../utils/postcard";
import {LAYOUT} from "../utils/layout";
import {PostcardAppInitialState} from "../components/app/postcard-app/utils/PostcardAppInitialState";
import {getPrice} from "../components/app/common/postcardAppUtils";

export type { IDObject }

export type OrderPostcard = {
    id: string,
    price: number
}

export type PromoCode = {
    id: string
    active: boolean
    name: string
    postcardsCount: number
    postcardsToRedeemCount: number
}

export type CartData = {
    id: string
    postcards: {
        postcard: Postcard
        cartItemId: string
    }[]
    // greetings: {
    //     postcard: GreetingsPostcard
    //     greetingsCartItemId: string
    // }[]
}

export type Cart = {
    id: string
    postcards: {
        postcard: Postcard
        cartItemId: string
        price: number
    }[]
    // greetings: {
    //     postcard: GreetingsPostcard
    //     greetingsCartItemId: string
    //     price: number
    // }[]
}

export { LAYOUT }

const createPostcard = async (
    postcard: DBPostcard,
    address: DBAddress,
    images: DBImage[],
    settings: DBPostcardSettings | undefined,
    chain: Chain | undefined,
    replyToAddress: DBAddress | undefined
): Promise<false | IDObject> => {
    const imagePromises = []
    let settingsId
    let result
    for (const image of images)
        imagePromises.push(createImage(image))
    result = await createAddressInDB(address)
    if (!result) return false
    for (const imagePromise of imagePromises) {
        result = await imagePromise
        if (!result) return false
    }
    if (chain) {
        result = await createChain(chain)
        if (!result) return false
    }
    if (settings) {
        result = await createPostcardSettings(settings)
        if (!result) return false
        settingsId = result.id
        if (replyToAddress) {
            result = await createAddressInDB(replyToAddress)
            if (!result) return false
        }
        return await createPostcardInDB({ postcardSettingsId: settingsId, ...postcard })
    }
    return await createPostcardInDB(postcard)
}

const createGreetingsPostcard = async (
    postcard: DBGreetingsPostcard,
    addresses: DBGreetingsAddress[],
    images: DBImage[]
): Promise<false | IDObject> => {
    const imagePromises = []
    const addressPromises = []
    let result
    for (const image of images)
        imagePromises.push(createImage(image))
    for (const address of addresses)
        addressPromises.push(createGreetingsAddressInDB(address))

    for (const imagePromise of imagePromises) {
        result = await imagePromise
        if (!result) return false
    }
    for (const addressPromise of addressPromises) {
        result = await addressPromise
        if (!result) return false
    }

    return await createGreetingsPostcardInDB(postcard)
}

const computePostcardPrice = (postcardPricingInformation: PostcardPricingInformation): number => {
    let price = 0
    if (postcardPricingInformation.initialState !== PostcardAppInitialState.REPLY_FREE
        && postcardPricingInformation.initialState !== PostcardAppInitialState.CHAIN_CONTINUATION_FREE)
        price += 3.59
    if (postcardPricingInformation.chainSetting == CHAIN_SETTING.CHAIN_PAID)
        price += 2.49
    if (postcardPricingInformation.replyBackSetting == REPLY_BACK_SETTING.REPLY_PAID)
        price += 2.49
    if (postcardPricingInformation.envelope)
        price += 0.99
    return price
}

const createOrderWithPostcardsAndOptionalPromoCode = async (postcardsIds: string[], promoCodeId: string | null): Promise<false | IDObject[]> => {
    const arePostcardAlreadyProcessed = await checkIfPostcardsHaveOrderLineItemAlready(postcardsIds, false)
    if (!arePostcardAlreadyProcessed) {
        const order = await createOrder(promoCodeId)
        const results = []
        if (!order)
            return false

        // for each id, retrieve postcard settings info and compute price (chain / reply options and initial state)
        const responses = postcardsIds.map(async id => {
            const postcardPricingInformation = await getPostcardPricingData(id)
            if (postcardPricingInformation) {
                const price = computePostcardPrice(postcardPricingInformation)
                return await createOrderLineItem(id, order.id, price, false)
            } return false
        })

        for (const response of responses) {
            const result = await response
            if (!result)
                return false
            results.push(result)
        }
        return results
    }
    return false
}

const createGreetingsOrder = async (postcardId: string): Promise<false | IDObject> => {
    const arePostcardAlreadyProcessed = await checkIfPostcardsHaveOrderLineItemAlready([postcardId], true)
    if (!arePostcardAlreadyProcessed) {
        const order = await createOrder(null)
        if (!order)
            return false

        return await createOrderLineItem(postcardId, order.id, getPrice(PostcardAppInitialState.DEFAULT), true)
    }
    return false
}

const getFrames = async (): Promise<false | Frame[]> => {
    const framesInDB = await getFramesInDB()
    if (!framesInDB)
        return false

    const frameImages: Promise<string | false>[] = framesInDB.map(frame => getFrameImage(frame.id))

    const frames: Frame[] = []
    let frameImage: string | false, frame: DBFrame
    for (let i in frameImages) {
        frameImage = await frameImages[i]
        frame = framesInDB[i]
        if (frameImage)
            frames.push({ ...frame, image: frameImage })
    }
    return frames
}

const getFonts = async (): Promise<false | Font[]> => {
    const fontsInDB = await getFontsInDB()
    if (!fontsInDB)
        return false

    const fontFiles: Promise<string | false>[] = fontsInDB.map(font => getFontFile(font.id, font.extension))

    const fonts: Font[] = []
    let fontFile: string | false, font: DBFont
    for (let i in fontFiles) {
        fontFile = await fontFiles[i]
        font = fontsInDB[i]
        if (fontFile)
            fonts.push({ ...font, file: fontFile })
    }
    return fonts
}

const loadFonts = async (fonts: Font[]): Promise<Font[]> => {
    let fontFaces = []
    let i = 0, actualFonts = []
    for (const font of fonts) {
        try {
            const fontFace = await new FontFace(font.name, `url(${font.file})`);// @ts-ignore
            (document as any).fonts.add(fontFace)
            fontFaces.push(fontFace)
        } catch (e) { }
    }
    for (const fontFace of fontFaces) {
        try {
            await fontFace.load()
            actualFonts.push(fonts[i])
        } catch (e) { }
        i++
    }
    return actualFonts
}

const getMessageColors = async (): Promise<false | MessageColor[]> => {
    const colors = await getColorsInDB()
    if (colors)
        return colors.sort((colorA, colorB) => colorA.hex > colorB.hex ? 1 : -1)
    return false
}

const getLayouts = (): LAYOUT[] => {
    return [
        LAYOUT.SINGLE_IMAGE,
        // LAYOUT.TWO_IMAGES_SPLIT_HORIZONTALLY,
        LAYOUT.TWO_IMAGES_SPLIT_VERTICALLY,
        LAYOUT.FOUR_IMAGES_EVEN_GRID
    ];
}

const getUserReceivedPostcards = async (userId: string): Promise<Postcard[]> => {
    const rawPostcards = await _getUserReceivedPostcards(userId)
    if (!rawPostcards)
        return []

    const postcardPromises = rawPostcards.map(postcard => processPostcardRawData(postcard))

    const postcards = []
    for (const postcardPromise of postcardPromises)
        postcards.push(await postcardPromise)

    return postcards
}

const getUserSentPostcards = async (userId: string): Promise<Postcard[]> => {
    const rawPostcards = await _getUserSentPostcards(userId)
    if (!rawPostcards)
        return []

    const postcardPromises = rawPostcards.map(postcard => processPostcardRawData(postcard))

    const postcards = []
    for (const postcardPromise of postcardPromises)
        postcards.push(await postcardPromise)

    return postcards
}

const getPostcard = async (id: string): Promise<false | Postcard> => {
    const result = await getRawPostcard(id)
    if (!result) return false
    return await processPostcardRawData(result)
}

const getGreetingsPostcard = async (id: string): Promise<false | GreetingsPostcard> => {
    const result = await getGreetingsRawPostcard(id)
    if (!result) return false
    return await processGreetingsPostcardRawData(result)
}

const addQRCodeScan = async (postcardId: string): Promise<boolean> => {
    return await createQRCodeScan(postcardId);
}

const setPostcardRecipientId = async (postcardId: string, recipientId: string): Promise<boolean> => {
    return await _setPostcardRecipientId(postcardId, recipientId)
}

const _getNameFromUserAttributes = (userAttributes: { Name: string, Value: string }[]): string | false => {
    for (const attribute of userAttributes) {
        if (attribute.Name === 'name') return attribute.Value
    }
    return false
}

const getUserName = async (userId: string): Promise<false | string> => {
    const userData = await getUser(userId)
    if (!userData) return false
    return _getNameFromUserAttributes(userData.UserAttributes)
}

const getUserData = async (): Promise<false | { name: string, email: string, address: string }> => {
    return await _getUserData()
}

const setPostcardReply = async (settingsId: string, replyPostcardId: string): Promise<boolean> => {
    return await setPostcardSettingsReply(settingsId, replyPostcardId)
}

const _getOrderedPostcards = (postcards: ChainProgressionPostcard[]): ChainProgressionPostcard[] | false => {
    try {
        let lastPostcard = postcards.filter(postcard => !postcard.settings.postcardSettingsReplyId)[0]
        let id = lastPostcard.id
        const orderedPostcards: ChainProgressionPostcard[] = [lastPostcard]
        for (let i = 0; i < postcards.length - 1; i++) {
            // eslint-disable-next-line
            lastPostcard = postcards.filter(postcard => postcard.settings.postcardSettingsReplyId === id)[0]
            orderedPostcards.unshift(lastPostcard)
            id = lastPostcard.id
        }
        return orderedPostcards
    } catch (e) {
        console.warn("Error while ordering chain postcard array: ", e)
        return false
    }
}

const getChainProgression = async (id: string): Promise<false | ChainProgressionPostcard[]> => {
    const result = await getChain(id)
    if (!result) return false
    else return _getOrderedPostcards(result.postcards)
}

const getAutocompleteLocations = async (partialLocation: string): Promise<SuggestedLocation[] | false> => {
    return await _getAutocompleteLocations(partialLocation)
}

const getUserGroups = async (userId: string): Promise<false | string[]> => {
    return await _getUserGroups(userId)
}

const getPromoCode = async (userId: string): Promise<false | PromoCode> => {
    const result = await getRawPromoCode(userId)
    if (result) {
        let postcardsCount = 0
        let postcardsToRedeemCount = 0
        for (const order of result.orderPromoCode) {
            postcardsCount += order.order.orderLineItems.length
            if (!order.redeemed)
                postcardsToRedeemCount += order.order.orderLineItems.length
        }

        return {
            id: result.id,
            name: result.name,
            active: result.active,
            postcardsCount: postcardsCount,
            postcardsToRedeemCount: postcardsToRedeemCount
        }
    } else {
        return false
    }
}

const isPromoCodeValid = async (name: string, userId: string): Promise<false | IDObject | null> => {
    const result = await getActivePromoCodeFromName(name)
    if (result !== false) {
        if (result !== null) {
            if (name === LAUNCH_PROMO_CODE || name === GREETINGS_20_PROMO_CODE)
                // Multi-use promo code
                return { id: result.id }
            const orderWithPromoCode = await getUserOrderWithPromoCodeIfExists(userId, result.id)
            if (orderWithPromoCode !== false) {
                if (orderWithPromoCode === null)
                    return { id: result.id }
                else return false
            }
            else return null
        } else return false
    } else return null
}

const getGreetingsPostcardsToExport = async (dateFrom: string): Promise<Postcard[] | false> => {
    const rawPostcards = await getUnprocessedGreetingsPostcards(dateFrom)
    if (!rawPostcards)
        return []

    const postcardPromises = rawPostcards.map(postcard => processGreetingsPostcardRawData(postcard))

    const postcards = []
    for (const postcardPromise of postcardPromises)
        postcards.push(await postcardPromise)

    return fromGreetingsPostcardsToPlain(postcards)
}

const getPostcardsToExport = async (dateFrom: string): Promise<Postcard[] | false> => {
    const rawPostcards = await getUnprocessedPostcards(dateFrom)
    if (!rawPostcards)
        return []

    const postcardPromises = rawPostcards.map(postcard => processPostcardRawData(postcard))

    const postcards = []
    for (const postcardPromise of postcardPromises)
        postcards.push(await postcardPromise)

    return postcards
}

const getUserCart = async (userId: string): Promise<string | false> => {
    return await getCartOrCreate(userId)
}

const createCartItems = async (cartId: string, postcardIds: string[]): Promise<boolean> => {
    const promises = []
    for (const id of postcardIds)
        promises.push(createCartItem(cartId, id))
    let result
    for (const promise of promises) {
        result = await promise
        if (!result) return false
    }
    return true
}

// const createGreetingsCartItems = async (cartId: string, greetingsPostcardIds: string[]): Promise<boolean> => {
//     const promises = []
//     for (const id of greetingsPostcardIds)
//         promises.push(createGreetingsCartItem(cartId, id))
//     let result
//     for (const promise of promises) {
//         result = await promise
//         if (!result) return false
//     }
//     return true
// }

const getFullUserCart = async (
    userId: string
): Promise<CartData | false> => {
    const rawCart = await getUserCartWithPostcards(userId)

    if (!rawCart)
        return false

    const postcardPromises = rawCart.postcards.map(
        async (postcardData) => {
            const postcard = await processPostcardRawData(postcardData.postcard)
            return {
                postcard,
                cartItemId: postcardData.cartItemId
            }
        }
    )

    // const greetingsPromises = rawCart.greetings.map(
    //     async (greetingsData) => {
    //         const postcard = await processGreetingsPostcardRawData(greetingsData.postcard)
    //         return {
    //             postcard,
    //             greetingsCartItemId: greetingsData.greetingsCartItemId
    //         }
    //     }
    // )

    const postcards = []
    for (const postcardPromise of postcardPromises)
        postcards.push(await postcardPromise)

    // const greetings = []
    // for (const greetingsPromise of greetingsPromises)
    //     greetings.push(await greetingsPromise)

    return {
        id: rawCart.id,
        postcards,
        // greetings
    }
}

const emptyUserCart = async (userId: string): Promise<boolean> => {
    const cartItems = await getUserCartItems(userId)

    if (!cartItems)
        return false

    const promises = []
    for (const idData of cartItems)
        promises.push(deleteCartItem(idData.id))

    let result
    for (const promise of promises) {
        result = await promise
        if (!result) return false
    }

    return true
}

const deletePostcardFromCart = async (postcardId: string, cartItemId: string): Promise<boolean> => {
    return await removePostcardFromCart(postcardId, cartItemId)
}

export {
    fetchImageFromUri,
    createPostcard,
    createOrderWithPostcardsAndOptionalPromoCode,
    createGreetingsOrder,
    getFrames,
    getFonts,
    loadFonts,
    getMessageColors,
    getLayouts,
    getUserReceivedPostcards,
    getUserSentPostcards,
    getPostcard,
    getGreetingsPostcard,
    addQRCodeScan,
    setPostcardRecipientId,
    getUserName,
    getUserData,
    setPostcardReply,
    getChainProgression,
    getAutocompleteLocations,
    getUserGroups,
    getPromoCode,
    isPromoCodeValid,
    computePostcardPrice,
    getPostcardsToExport,
    getGreetingsPostcardsToExport,
    createGreetingsPostcard,
    getUserCart,
    createCartItems,
    getFullUserCart,
    emptyUserCart,
    deletePostcardFromCart
}