import React from "react";
import ImageStep from "../common/imagesselection/ImageStep";
import MessageAndLocationStep from "../common/MessageAndLocationStep";
import IsGreetingsContext from "../common/imagesselection/isGreetingsContext";
import RecipientStep from "./RecipientStep";
import CartStep from "./cartstep/CartStep"
import {RootState, useAppSelector} from "../../../utils/store";
import AppPage from "../common/AppPage";

const InnerGreetingsPostcardApp = () => {
    const stateSelector = (state: RootState) => state.greetings

    const {
        step
    } = useAppSelector(stateSelector)

    const stepsComponents = [
        <ImageStep />,
        <MessageAndLocationStep />,
        <RecipientStep />,
        <></>,
        <CartStep />
    ]

    return <IsGreetingsContext.Provider value={{ isGreetings: true }}>
        <AppPage steps={stepsComponents} step={step} />
    </IsGreetingsContext.Provider>
}

export default InnerGreetingsPostcardApp