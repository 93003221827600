import PostcardTemplate from "./PostcardTemplate";
import {Address as AddressType, Font} from "../../../../utils/postcard";
import LocationBox from "../header/location/LocationBox";
import QRCodeImage from "../header/QRCodeImage";
import MessageBox from "../body/message/MessageBox";
import AddressBox from "../body/address/AddressBox";
import {Location} from "../../../app/common/postcardAppUtils";
import StampImage from "../header/stamps/StampImage";

type StaticPostcardProps = {
    message: string
    font?: Font
    color?: string

    location: Location

    address: AddressType

    envelope: boolean

    isGiftQRCode: boolean
}

const StaticPostcardBack = (props: StaticPostcardProps) => {
    const {
        message, font, color,
        location,
        address, envelope, isGiftQRCode
    } = props

    return <PostcardTemplate
        location={<LocationBox
            location={location}
        />}
        qrcode={<QRCodeImage isGiftQRCode={isGiftQRCode} />}
        message={<MessageBox
            font={font}
            color={color}
            message={message}
        />}
        address={<AddressBox address={address} />}
        stamp={<StampImage envelope={envelope} />}
        showStampBox={!envelope}
    />
}

export default StaticPostcardBack