import {useSearchParams} from "react-router-dom";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import {useCallback, useEffect, useState} from "react";
import PageWrapper from "../components/PageWrapper";
import {getFonts, getGreetingsPostcard, getUserName} from "../api/api";
import PostcardRecap from "../components/PostcardRecap";
import {Font, GreetingsPostcard} from "../utils/postcard";
// @ts-ignore
import {ComposableMap, Geographies, Geography, Marker} from "react-simple-maps";
import {Box, CircularProgress, Typography} from "@mui/material";

const GreetingsPostcardScan = () => {
    const [searchParams] = useSearchParams()
    const [postcard, setPostcard] = useState<undefined | GreetingsPostcard>(undefined)
    const [senderName, setSenderName] = useState<string>('')
    const [isLoadingPostcard, setIsLoadingPostcard] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const {user} = useAuthenticator()
    const id: string = searchParams.get('id') as string

    const fetchPostcard = useCallback(async () => {
        return getGreetingsPostcard(id)
    }, [id])

    const getSenderName = async (userId: string) => {
        const sender = await getUserName(userId)
        if (sender) setSenderName(sender)
    }

    const fetchFonts = async () => {
        return await getFonts()
    }

    const loadFonts = async (fonts: Font[]) => {
        let fontFaces = []
        let i = 0, actualFonts = []
        for (const font of fonts) {
            try {
                const fontFace = await new FontFace(font.name, `url(${font.file})`);// @ts-ignore
                (document as any).fonts.add(fontFace)
                fontFaces.push(fontFace)
            } catch (e) { }
        }
        for (const fontFace of fontFaces) {
            try {
                await fontFace.load()
                actualFonts.push(fonts[i])
            } catch (e) { }
            i++
        }
    }

    useEffect(() => {
        fetchFonts()
            .then((fonts) => {
                if (fonts) return loadFonts(fonts)
            })
        fetchPostcard()
            .then(result => {
                if (!result) {
                    setErrorMessage('Unable to fetch postcard')
                }
                else {
                    if (result.owner) getSenderName(result.owner).then(ignored => { })
                    setPostcard(result)
                }
                setIsLoadingPostcard(false)
            })
    }, [fetchPostcard, user])

    return <PageWrapper>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column', px: 1 }}>
            {isLoadingPostcard && <CircularProgress sx={{ '& svg': { transform: 'scale(3) !important' }, marginBottom: 16 }} />}
            {isLoadingPostcard ?
                <Typography variant={'h4'}>Loading...</Typography> :
                (errorMessage === '' && postcard !== undefined ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, width: '100%', py: 3 }}>
                        <Box sx={{ px: 2, textAlign: 'center' }}>
                            {user ? (senderName && <Typography variant={'h4'} sx={{ pb: 1 }}>{senderName} sent you a postcard</Typography>) : <Typography variant={'h4'}>Someone sent you a postcard</Typography>}
                        </Box>

                        <Box sx={{ pt: 2 }} />
                        <Authenticator>
                            <PostcardRecap
                                postcard={postcard}
                                isGreetings={true}
                            />
                        </Authenticator>
                    </Box> :
                    <Typography variant={'h4'}>{errorMessage}</Typography>)
            }
        </Box>
    </PageWrapper>
}

export default GreetingsPostcardScan
