import React, {useContext} from "react";
import IsGreetingsContext from "./imagesselection/isGreetingsContext";
import {RootState, useAppDispatch, useAppSelector} from "../../../utils/store";
import AppSnackbar from "./AppSnackbar";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import useActions from "./utils/useActions";

const Exceptions = () => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const stateSelector = (state: RootState) =>
        isGreetings ? state.greetings : state.app

    const {
        alerts, errorDialog
    } = useAppSelector(stateSelector)

    const dispatch = useAppDispatch()
    const { closeErrorDialog, removeAlert } = useActions(isGreetings)

    return <>
        <AppSnackbar alerts={alerts} removeAlert={id => dispatch(removeAlert(id))} />
        <Dialog open={errorDialog.open} onClose={() => dispatch(closeErrorDialog())} sx={{ '& .MuiPaper-root': { padding: 2, borderRadius: 4 } }}>
            <DialogTitle>
                Something is missing
            </DialogTitle>
            <DialogContent>
                {errorDialog.message}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closeErrorDialog())} variant={'contained'}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default Exceptions