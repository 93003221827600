import {ChangeEvent, useContext, useState} from "react";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import AddAddressesBox from "./AddAddressesBox";
import * as XLSX from "xlsx";
import {Address} from "../../../../../utils/postcard";
import {v4 as uuid} from "uuid";
import AddressesBox from "./AddressesBox";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme} from "@mui/material";

type AddGreetingsAddressesProps = {
    addresses: Address[]
    setAddresses: ({ addresses, fileName }: { addresses: Address[], fileName: string }) => void
    fileName: string
}

enum EXCEL_FILE_ERROR_MESSAGES {
    INVALID_HEADERS_LENGTH,
    INVALID_HEADERS_FORMAT,
    INVALID_ROW_LENGTH,
    INVALID_ROW_VALUE
}

const isInvalidFormat = (data: any[][], expectedFormat: any[][]): EXCEL_FILE_ERROR_MESSAGES | null => {
    if (data.length < expectedFormat.length) {
        return EXCEL_FILE_ERROR_MESSAGES.INVALID_HEADERS_LENGTH;
    }
    for (let i = 0; i < expectedFormat.length; i++) {
        for (let j = 0; j < expectedFormat[i].length; j++) {
            if (data[i][j] !== expectedFormat[i][j]) {
                return EXCEL_FILE_ERROR_MESSAGES.INVALID_HEADERS_FORMAT;
            }
        }
    }
    return null;
};

const isInvalidRow = (row: any[]): EXCEL_FILE_ERROR_MESSAGES | null => {
    if (row.length < 6) return EXCEL_FILE_ERROR_MESSAGES.INVALID_ROW_LENGTH
    for (let i = 0; i < row.length; i++)
        if (row[i] === undefined || row[i] === null || row[i] === '') return EXCEL_FILE_ERROR_MESSAGES.INVALID_ROW_VALUE
    return null
}

const getErrorMessageFromEnum = (value: EXCEL_FILE_ERROR_MESSAGES, rowIndex: number): string => {
    switch (value) {
        case EXCEL_FILE_ERROR_MESSAGES.INVALID_ROW_LENGTH:
            return `Row ${rowIndex} has unexpected length`
        case EXCEL_FILE_ERROR_MESSAGES.INVALID_ROW_VALUE:
            return `Invalid empty cell at row ${rowIndex}`
        case EXCEL_FILE_ERROR_MESSAGES.INVALID_HEADERS_LENGTH:
            return 'Invalid file headers length'
        case EXCEL_FILE_ERROR_MESSAGES.INVALID_HEADERS_FORMAT:
            return 'Invalid file headers format'
        default:
            return ''
    }
}

const AddGreetingsAddressesComponent = (props: AddGreetingsAddressesProps) => {
    const { addresses, setAddresses, fileName } = props
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean>(false)

    const postcardWidth = useContext(PostcardWidthContext).width

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (event: ProgressEvent<FileReader>) => {
                if (event.target?.result) {
                    const data = new Uint8Array(event.target.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });

                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    const isValid = validateSpreadsheetFormat(jsonData)
                    if (isValid) {
                        const _addresses = processSpreadSheetData(jsonData)
                        setAddresses({
                            addresses: _addresses,
                            fileName: file.name
                        })
                    }
                }
            };

            reader.readAsArrayBuffer(file);
        }
    }

    const validateSpreadsheetFormat = (jsonData: any[][]): boolean => {
        const expectedFormat: any[][] = [
            ['Name', 'Surname', 'Address', 'City', 'Country', 'Zip Code'],
        ];

        const formatError = isInvalidFormat(jsonData, expectedFormat);

        if (!formatError) {
            return true
        } else {
            setErrorMessage(getErrorMessageFromEnum(formatError, 0))
            setIsErrorPopupOpen(true)
            return false
        }
    }

    const processSpreadSheetData = (jsonData: any[][]): Address[] => {
        const _addresses: Address[] = []
        let row: any[]
        for (let i = 1; i < jsonData.length; i++) {
            row = jsonData[i]
            if (row && row.length) {
                const formatError = isInvalidRow(row)
                if (formatError === null) {
                    _addresses.push({
                        id: uuid(),
                        fullName: row[0] + ' ' + row[1],
                        streetAddress: row[2],
                        city: row[3] + ', ' + row[4],
                        zipCode: row[5]
                    })
                } else {
                    setErrorMessage(getErrorMessageFromEnum(formatError, i + 1))
                    setIsErrorPopupOpen(true)
                    break
                }
            }
        }

        return _addresses
    }

    const onDelete = () => {
        setAddresses({
            addresses: [],
            fileName: ''
        })
    }

    const theme = useTheme()
    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    return <>
        {addresses.length === 0 ?
            <AddAddressesBox
                handleFileUpload={handleFileUpload}
                postcardWidth={postcardWidth}
            /> : <AddressesBox
                addresses={addresses}
                fileName={fileName}
                handleFileUpload={handleFileUpload}
                onDelete={onDelete}
            />}
        <Dialog open={isErrorPopupOpen} sx={{ '& .MuiDialog-paper': { borderRadius: 3 } }}>
            <DialogTitle sx={{ px: 3, pt: 3 }}>
                <Typography variant={'h6'}>There was an error in the file you uploaded</Typography>
            </DialogTitle>
            <DialogContent sx={{ px: 3 }}>
                <Typography variant={'body1'}>{errorMessage}</Typography>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3 }}>
                <Button variant={"contained"} onClick={() => {
                    setIsErrorPopupOpen(false)
                    setErrorMessage('')
                }} sx={{ width: '50%', ...buttonDefaults }}> Ok </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default AddGreetingsAddressesComponent