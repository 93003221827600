import PageWrapper from "../components/PageWrapper";
import {Box, Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getBackendEnvironment, invoke} from "../utils/utils";
import DoneIcon from "@mui/icons-material/Done";
import {useAuthenticator} from "@aws-amplify/ui-react";
import useUserAttributes from "../utils/useUserAttributes";
import CustomAuthenticator from "../components/CustomAuthenticator";
import {Hub} from "aws-amplify/utils";

enum EmailState {
    INITIAL, SENDING, SENT
}

const InnerAmbassadorContact = () => {
    const [fullName, setFullName] = useState<string>('')
    const [_email, setEmail] = useState<string>('')
    const [social, setSocial] = useState<string>('')
    const [promoCode, setPromoCode] = useState<string>('')
    const [bio, setBio] = useState<string>('')
    const [isLoadingEmail, setIsLoadingEmail] = useState<EmailState>(EmailState.INITIAL)

    const { user } = useAuthenticator()
    const { name, email } = useUserAttributes(user?.userId)

    const handleSubmit = async () => {
        setIsLoadingEmail(EmailState.SENDING)
        try {
            const environment = getBackendEnvironment()
            await invoke(`stampyemailsender-${environment}`, {
                message: `
Name: ${fullName}
Email: ${_email}
Social / Website: ${social}
Promo code: ${promoCode}
Bio: ${bio}
                `,
                subject: '[AUTO] New ambassador request',
                recipient: 'admin@memento-card.it'
            })
        } catch (e) {
            console.warn('Error while trying to send internal email')
        }
        setIsLoadingEmail(EmailState.SENT)
        setEmail('')
        setFullName('')
        setBio('')
        setSocial('')
    }

    useEffect(() => {
        if (fullName === '' && name !== '') setFullName(name)
        // @ts-ignore
        if (_email === '' && email !== '') setEmail(email)
    }, [name, email])

    return <Box sx={{ display: 'flex', width: 'calc(100% - 48px)', flexDirection: 'column', m: 3, my: 6,  alignItems: 'center' }}>
            <Typography variant={'h4'} sx={{ mb: 1, textAlign: 'center', fontSize: { xs: 30, md: 40 } }}>
                Become a Memento Ambassador
            </Typography>
            <Typography variant={'h6'} sx={{ color: '#666', fontFamily: 'Roboto', textAlign: 'center', fontSize: { xs: 18, md: 24 } }}>
                Tell us more about yourself
            </Typography>
            <Card sx={{ width: '100%', maxWidth: 800, p: { xs: 2, md: 4 }, mt: 4, borderRadius: 3, boxShadow: 'none', border: '1px solid rgba(14, 14, 44, 0.05)', filter: 'drop-shadow(0px 0px 4.2963px rgba(0, 0, 0, 0.25))' }}>
                <CardContent>
                    <TextField
                        label={'Full Name'}
                        style={{ width: '100%', paddingBottom: 24 }}
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                    />
                    <TextField
                        label={'Memento Account Email'}
                        style={{ width: '100%', paddingBottom: 24 }}
                        value={_email}
                        type={'email'}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        label={'Link Socials / Website'}
                        style={{ width: '100%', paddingBottom: 24 }}
                        value={social}
                        onChange={e => setSocial(e.target.value)}
                    />
                    <TextField
                        label={'Create your personal promo code (ex: MEMENTO01)'}
                        style={{ width: '100%', paddingBottom: 24 }}
                        value={promoCode}
                        onChange={e => setPromoCode(e.target.value)}
                    />
                    <TextField
                        multiline
                        label={'Tell us more about yourself'}
                        sx={{
                            width: '100%',
                            height: 124,
                            '& .MuiInputBase-root': {
                                height: '100% !important',
                                alignItems: 'start',
                                borderRadius: 2
                            }
                        }}
                        value={bio}
                        onChange={e => setBio(e.target.value)}
                    />
                </CardContent>
                <CardActions>
                    <Button variant={'contained'} onClick={handleSubmit} disabled={isLoadingEmail === EmailState.SENDING}>
                        {isLoadingEmail === EmailState.SENT ? <DoneIcon sx={{ color: 'white', '& path': { fill: 'white' } }} /> :
                            (isLoadingEmail === EmailState.SENDING ? <CircularProgress sx={{ width: '28px !important' }} />
                                : 'Send request')
                        }
                    </Button>
                </CardActions>
            </Card>
        </Box>
}

const AmbassadorContact = () => {
    const { user } = useAuthenticator()
    const [authState, setAuthState] = useState<boolean>(!!user);

    useEffect(() => {
        const cancelSubscription = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signedIn':
                    console.log('user have been signedIn successfully.');
                    setAuthState(true)
                    break;
                case 'signedOut':
                    console.log('user have been signedOut successfully.');
                    break;
                case 'tokenRefresh':
                    console.log('auth tokens have been refreshed.');
                    break;
                case 'tokenRefresh_failure':
                    console.log('failure while refreshing auth tokens.');
                    break;
                case 'signInWithRedirect':
                    console.log('signInWithRedirect API has successfully been resolved.');
                    break;
                case 'signInWithRedirect_failure':
                    console.log('failure while trying to resolve signInWithRedirect API.');
                    break;
            }
        })
        return () => cancelSubscription()
    }, []);

    return <PageWrapper>
        { authState ? <InnerAmbassadorContact /> : <Box sx={{ pt: '40px', px: 2 }}><CustomAuthenticator /></Box> }
    </PageWrapper>
}

export default AmbassadorContact

