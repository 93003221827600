export class CacheManager {
    static itemKeys: string[] = []

    static addKey(key: string): void {
        if (!this.itemKeys.includes(key)) CacheManager.itemKeys.push(key)
    }

    static removeKey(key: string): void {
        localStorage.removeItem(key)
        if (CacheManager.itemKeys.includes(key)) {
            const index = CacheManager.itemKeys.indexOf(key)
            CacheManager.itemKeys.splice(index, 1)
        }
    }

    static async clear(): Promise<void> {
        CacheManager.removeKey('_images_metadata')
        CacheManager.removeKey('user-images-cache')
        CacheManager.removeKey('id')
        CacheManager.removeKey('message')
        CacheManager.removeKey('location')
        CacheManager.removeKey('latitude')
        CacheManager.removeKey('longitude')

        CacheManager.removeKey('address')
        CacheManager.removeKey('replyBack')
        CacheManager.removeKey('chainStartOrContinuation')
        CacheManager.removeKey('replyAddress')
        CacheManager.removeKey('chainName')

        CacheManager.removeKey('frame')
        CacheManager.removeKey('font')
        CacheManager.removeKey('messageColor')
        CacheManager.removeKey('promotion')
        CacheManager.removeKey('promoCodeId')

        CacheManager.removeKey('step')
        CacheManager.removeKey('_postcard_metadata')
        CacheManager.removeKey('currentIndex')
        CacheManager.removeKey('selectedLayout')
        CacheManager.removeKey('colorId')

        CacheManager.removeKey('fontId')
        CacheManager.removeKey('layout')
    }

    static async clearGreetings(): Promise<void> {
        CacheManager.removeKey('_greetings_images_metadata')
        CacheManager.removeKey('greetings-images-cache')
        CacheManager.removeKey('greetings_id')
        CacheManager.removeKey('greetings_message')
        CacheManager.removeKey('greetings_location')
        CacheManager.removeKey('greetings_latitude')
        CacheManager.removeKey('greetings_longitude')

        CacheManager.removeKey('greetings_addresses')
        CacheManager.removeKey('greetings_file_name')

        CacheManager.removeKey('greetings_frame')
        CacheManager.removeKey('greetings_font')
        CacheManager.removeKey('greetings_messageColor')
        CacheManager.removeKey('greetings_promotion')
        CacheManager.removeKey('greetings_promoCodeId')

        CacheManager.removeKey('greetings_step')
        CacheManager.removeKey('greetings_selectedLayout')
        CacheManager.removeKey('greetings_colorId')

        CacheManager.removeKey('greetings_fontId')
        CacheManager.removeKey('greetings_layout')
    }
}