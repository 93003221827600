import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {v4 as uuid} from "uuid";
import {useDropzone} from "react-dropzone";
import {Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Typography} from "@mui/material";
import '../../../../App.css'

import Cropper, {ReactCropperElement} from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import imageCompression from "browser-image-compression";
import {RotateLeft, RotateRight, ZoomIn, ZoomOut} from "@mui/icons-material";
import {getImageDimensionsForLayout, LAYOUT} from "../../../../utils/layout";
import {Image} from "../../../../utils/postcard";
import IsGreetingsContext from "./isGreetingsContext";
import {RootState, useAppDispatch, useAppSelector} from "../../../../utils/store";
import useActions from "../utils/useActions";

type ImagesSelectionProps = {
    layout: LAYOUT | undefined
}

const ImagesSelection = ({ layout }: ImagesSelectionProps) => {
    return <Box>
        <Box sx={{ mb: 2 }}>
            <Typography sx={{ textAlign: 'center' }} variant={'h6'}>Front</Typography>
        </Box>
        {(layout === LAYOUT.SINGLE_IMAGE) &&
            <SingleImageSelection/>}
        {layout === LAYOUT.TWO_IMAGES_SPLIT_HORIZONTALLY &&
            <TwoImagesSplitHorizontallyImageSelection/>}
        {layout === LAYOUT.TWO_IMAGES_SPLIT_VERTICALLY &&
            <TwoImagesSplitVerticallyImageSelection/>}
        {layout === LAYOUT.FOUR_IMAGES_EVEN_GRID &&
            <FourImageEvenGridImageSelection/>}
    </Box>
}

const SingleImageSelection = () => {
    const dimensions = getImageDimensionsForLayout(LAYOUT.SINGLE_IMAGE)
    const maxWidth = window.outerWidth - 24 * 2
    const screenIsTooSmall = dimensions.width > maxWidth
    const width = screenIsTooSmall ? maxWidth : dimensions.width
    const height = screenIsTooSmall ? maxWidth * (dimensions.height / dimensions.width) : dimensions.height
    return <Box sx={{ boxShadow: 2, width: width, height: height }}>
        <ImageSelection index={0} width={width} height={height} mt={0} mb={0} ml={0} mr={0} showText={true} />
    </Box>
}

const TwoImagesSplitHorizontallyImageSelection = () => {
    const dimensions = getImageDimensionsForLayout(LAYOUT.TWO_IMAGES_SPLIT_HORIZONTALLY)
    const maxWidth = window.outerWidth - 24 * 2
    const screenIsTooSmall = dimensions.width > maxWidth
    const width = screenIsTooSmall ? maxWidth : dimensions.width
    const height = screenIsTooSmall ? maxWidth * (dimensions.height / dimensions.width) : dimensions.height
    return <Box sx={{ boxShadow: 2, width: width, height: height * 2 }}>
        <ImageSelection index={0} width={width} height={height} mt={0} mb={4} ml={0} mr={0} />
        <ImageSelection index={1} width={width} height={height} mt={4} mb={0} ml={0} mr={0} />
    </Box>
}

const TwoImagesSplitVerticallyImageSelection = () => {
    const dimensions = getImageDimensionsForLayout(LAYOUT.TWO_IMAGES_SPLIT_VERTICALLY)
    const maxWidth = window.outerWidth - 24 * 2
    const screenIsTooSmall = dimensions.width > (maxWidth / 2)
    const width = screenIsTooSmall ? (maxWidth / 2) : dimensions.width
    const height = screenIsTooSmall ? (maxWidth / 2) * (dimensions.height / dimensions.width) : dimensions.height
    return <Box sx={{ flexDirection: 'row', display: 'flex', boxShadow: 2, width: width * 2, height: height }}>
        <ImageSelection index={0} width={width} height={height} mt={0} mb={0} ml={0} mr={4} />
        <ImageSelection index={1} width={width} height={height} mt={0} mb={0} ml={4} mr={0} />
    </Box>
}

const FourImageEvenGridImageSelection = () => {
    const dimensions = getImageDimensionsForLayout(LAYOUT.FOUR_IMAGES_EVEN_GRID)
    const maxWidth = window.outerWidth - 24 * 2
    const screenIsTooSmall = dimensions.width > (maxWidth / 2)
    const width = screenIsTooSmall ? (maxWidth / 2) : dimensions.width
    const height = screenIsTooSmall ? (maxWidth / 2) * (dimensions.height / dimensions.width) : dimensions.height
    return <Box sx={{ boxShadow: 2, width: width * 2, height: height * 2 }}>
        <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <ImageSelection index={0} width={width} height={height} mt={0} mb={4} ml={0} mr={4} />
            <ImageSelection index={1} width={width} height={height} mt={0} mb={4} ml={4} mr={0} />
        </Box>
        <Box style={{ flexDirection: 'row', display: 'flex' }}>
            <ImageSelection index={2} width={width} height={height} mt={4} mb={0} ml={0} mr={4} />
            <ImageSelection index={3} width={width} height={height} mt={4} mb={0} ml={4} mr={0} />
        </Box>
    </Box>
}

type ImageSelectionComponentProps = { index: number, width: number, height: number, mt: number, mb: number, ml: number, mr: number, showText?: boolean }

const ImageSelection = ({ index, width, height, mt, mb, ml, mr, showText }: ImageSelectionComponentProps) => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const [selectedImage, _setSelectedImage] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [editedImage, _setEditedImage] = useState<string | null>(null);
    const [isCropEditorOpen, _setIsCropEditorOpen] = useState<boolean>(false)

    const stateSelector = (state: RootState) =>
        isGreetings ? state.greetings.postcard : state.app.postcard

    const {
        images
    } = useAppSelector(stateSelector)

    const dispatch = useAppDispatch()

    const {
        updateImage
    } = useActions(isGreetings)

    const setSelectedImage = (image: File) => {
        _setSelectedImage(image)
        _setIsCropEditorOpen(true)
    }

    const closeCropEditor = () => {
        _setSelectedImage(null)
        _setIsCropEditorOpen(false)
    }

    const setEditedImage = async (value: File | null) => {
        if (value) {
            setIsLoading(true)
            // Compress image
            const compressedImageFile = await imageCompression(value, { maxSizeMB: 1 })
            const reader = new FileReader()

            reader.onloadend = function () {
                const path = reader.result as string;
                const image: Image = {id: uuid(), index: index, path: path}
                _setEditedImage(path)
                dispatch(updateImage(image))
                setIsLoading(false)
            };

            reader.readAsDataURL(compressedImageFile)
        }
    }

    useEffect(() => {
        const filteredImages = images.filter(image => image.index === index)
        if (filteredImages.length !== 0) {
            _setEditedImage(filteredImages[0].path)
        }
    }, [images])

    return (
        <div className="App">
            <ImagePicker
                onImageSelected={setSelectedImage}
                isLoading={isLoading}
                editedImage={editedImage}
                index={index}
                width={width}
                height={height}
                mt={mt}
                mb={mb}
                ml={ml}
                mr={mr}
                showText={showText}
            />
            <Dialog open={isCropEditorOpen} fullScreen={true} sx={{ maxWidth: '100vw' }}>
                <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {selectedImage ? <ImageEditor image={selectedImage} onSave={setEditedImage} closeCropEditor={closeCropEditor} width={width} height={height} /> : 'Error'}
                </DialogContent>
            </Dialog>
        </div>
    );
}

const ImagePicker = ({ onImageSelected, editedImage, width, height, mt, mb, ml, mr, showText, isLoading }: ImageSelectionComponentProps & {
    onImageSelected: (image: File) => void,
    editedImage: string | null,
    isLoading: boolean
}) => {
    const [image, setImage] = useState<Blob | null>(null)

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        onImageSelected(file)
    }, [onImageSelected]);

    useEffect(() => {
        if (editedImage !== null && editedImage !== undefined) {
            fetch(editedImage)
                .then(result => result.blob())
                .then(blob => setImage(blob))
                .catch(error => {
                    console.warn("Error while fetching existing edited image: " + error)
                    setImage(null)
                })
        }
    }, [editedImage])

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: {'image/*': []}, multiple: false });

    return (
        image === null ? <div {...getRootProps()} style={{ backgroundColor: 'white', width: width, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div>
                <div style={{
                    backgroundColor: '#EDEDED',
                    width: width - mr - ml,
                    height: height - mt - mb,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    marginTop: mt,
                    marginBottom: mb,
                    marginLeft: ml,
                    marginRight: mr
                }}>
                    {isLoading ? <CircularProgress sx={{ transform: 'scale(1.5)' }} /> : <img src={'icons/app/imagestep/add_image.svg'} alt={'camera'}
                          style={{width: height * 0.3, height: height * 0.3}}/>}
                    {showText && <Typography sx={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        letterSpacing: '0.03em',
                        textTransform: 'uppercase',
                        color: '#000000',
                        pt: 2
                    }}>{isLoading ? 'Loading image' : 'Upload your image'}</Typography>}
                    <input {...getInputProps()} />
                </div>
            </div>
        </div> : <div {...getRootProps()} style={{ backgroundColor: 'white', width: width, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
             <input {...getInputProps()} />
            {isLoading ? <div style={{
                backgroundColor: '#EDEDED',
                width: width - mr - ml,
                height: height - mt - mb,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                marginTop: mt,
                marginBottom: mb,
                marginLeft: ml,
                marginRight: mr
            }}>
                <CircularProgress sx={{transform: 'scale(1.5)'}}/>
                {showText && <Typography sx={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    letterSpacing: '0.03em',
                    textTransform: 'uppercase',
                    color: '#000000',
                    pt: 2
                }}>Loading image'</Typography>}
                <input {...getInputProps()} />
            </div> : <img src={editedImage as string | undefined} alt={'Your selection'} style={{
                width: width - mr - ml,
                height: height - mt - mb,
                objectFit: 'cover',
                marginTop: mt,
                marginBottom: mb,
                marginLeft: ml,
                marginRight: mr
            }}/>}
        </div>
    );
}

type ImageEditorProps = {
    image: File,
    onSave: (value: File | null) => void,
    closeCropEditor: () => void,
    width: number,
    height: number
}

const ImageEditor = ({image, onSave, closeCropEditor, width, height}: ImageEditorProps) => {
    const cropperRef = useRef<ReactCropperElement>(null);

    const handleSave = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.getCroppedCanvas().toBlob(
                blob => {
                    if (blob && blob.size !== 0) {
                        const file = new File([blob], image.name, {type: 'image/jpeg'})
                        onSave(file);
                        closeCropEditor()
                    } else {
                        onSave(null)
                    }
                },
                'image/jpeg'
            );
        }
    };

    const aspect = width / height

    const rotateImage = (degree: number) => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            if (cropper) {
                const canvasData = cropper.getCanvasData();

                // Rotate the image
                cropper.rotate(degree);

                // Adjust canvas position
                cropper.setCanvasData({
                    width: canvasData.height,
                    height: canvasData.width
                });
            }
        }
    };

    const zoom = (ratio: number) => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            if (cropper) {
                cropper.zoom(ratio)
            }
        }
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ flexShrink: 0, flexGrow: 0 }}>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <IconButton onClick={() => rotateImage(-90)}>
                            <RotateLeft/>
                        </IconButton>
                        <IconButton onClick={() => rotateImage(90)}>
                            <RotateRight/>
                        </IconButton>

                        <span style={{width: 10}}/>

                        <IconButton onClick={() => zoom(0.1)}>
                            <ZoomIn/>
                        </IconButton>
                        <IconButton onClick={() => zoom(-0.1)}>
                            <ZoomOut/>
                        </IconButton>
                    </Box>
                    <Box sx={{ height: '20px' }} />
                </Box>
                <Box sx={{ height: '100%', flexGrow: 1 }}>
                    <Cropper
                        src={URL.createObjectURL(image)}
                        style={{ height: 'calc(100% - 128px)' }}
                        // Cropper.js options
                        background={false}
                        responsive={true}
                        aspectRatio={aspect}
                        guides={true}
                        dragMode={'move'}
                        ref={cropperRef}
                        scalable={false}
                        zoomOnTouch={true}
                        zoomOnWheel={true}
                    />
                </Box>
            </Box>
            <Box sx={{ height: '20px', flexShrink: 0, flexGrow: 0 }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexShrink: 0, flexGrow: 0 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button variant={'contained'} onClick={handleSave}>Confirm</Button>
                    <span style={{ width: 10 }} />
                    <Button onClick={closeCropEditor}>Cancel</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ImagesSelection