import {EditAddressProps} from "./address";
import {useContext, useState} from "react";
import {PostcardWidthContext} from "../../templates/PostcardTemplate";
import EditAddressBox from "./EditAddressBox";
import AddressTextField from "../../../../AddressTextField";
import {Box, useTheme} from "@mui/material";

const EditAddressComponentLarge = ({ address, setAddress }: EditAddressProps) => {
    const [editAddress, setEditAddress] = useState<boolean>(false)
    const onClickEditAddressBox = () => setEditAddress(true)

    const theme = useTheme();

    const postcardWidth = useContext(PostcardWidthContext).width

    const isEmptyAddress = (): boolean => !address.streetAddress && !address.fullName && !address.city && !address.zipCode

    return !editAddress && isEmptyAddress() ? <EditAddressBox
        onClick={onClickEditAddressBox}
        postcardWidth={postcardWidth}
    /> : <Box sx={{
        position: 'absolute',
        width: '180px',
        height: '140px',
        border: '0.5px solid ' + theme.palette.primary.main,
        borderRadius: 2,
        px: 1,
        py: 0.5,
        display: 'flex',
        flexDirection: 'column'
    }}>
        <AddressTextField placeholder={'FULL NAME'} value={address.fullName}
                          onChange={e => setAddress({ ...address, fullName: e.target.value })} small={true} />
        <AddressTextField placeholder={'ADDRESS'} value={address.streetAddress}
                          onChange={e => setAddress({ ...address, streetAddress: e.target.value })} small={true} />
        <AddressTextField placeholder={'CITY, COUNTY'} value={address.city}
                          onChange={e => setAddress({ ...address, city: e.target.value })} small={true} />
        <AddressTextField placeholder={'ZIP CODE'} value={address.zipCode}
                          onChange={e => setAddress({ ...address, zipCode: e.target.value })} small={true} />
    </Box>
}

export default EditAddressComponentLarge