import {getAutocompleteLocations} from "../../../../../api/api";
import {Autocomplete, TextField} from "@mui/material";
import {Dispatch, SetStateAction, useState} from "react";
import {SuggestedLocation} from "../../../../../api/apiUtils";
import {Location, NO_COORDINATE} from "../../../../app/common/postcardAppUtils";

type LocationAutocompleteProps = {
    location: Location
    setLocation:  (value: Location) => void
    setEditLocation: Dispatch<SetStateAction<boolean>> | ((newState: boolean) => boolean)
    isSmall: boolean
}

const LocationAutocomplete = ({ location, setLocation, setEditLocation, isSmall }: LocationAutocompleteProps) => {
    const [suggestedLocations, setSuggestedLocations] = useState<SuggestedLocation[]>([])
    const [apiCallTimeout, setApiCallTimeout] = useState<NodeJS.Timeout | null>(null)

    return <Autocomplete
        sx={{ ml: '2.82%', width: isSmall ? 250 : 220, position: 'absolute', zIndex: 2, '& .MuiInputBase-input': { p: '0px !important', pl: '4px !important' } }}
        onChange={(ignored, newValue) => {
            if (newValue && newValue.displayName) {
                setLocation({
                    location: newValue.displayName,
                    latitude: newValue.latitude,
                    longitude: newValue.longitude
                })
            }
            setEditLocation(false)
        }}
        inputValue={location.location}
        onInputChange={(event, newInputValue) => {
            if (event && event.type === 'change') {
                const _location = newInputValue
                setLocation({
                    location: _location,
                    latitude: NO_COORDINATE,
                    longitude: NO_COORDINATE
                })
                if (apiCallTimeout !== null)
                    clearTimeout(apiCallTimeout)
                setApiCallTimeout(setTimeout(() => {
                    getAutocompleteLocations(_location)
                        .then(result => {
                            if (result) {
                                setSuggestedLocations(result)
                            } else {
                                // TODO notify devs
                            }
                        })
                }, 1000))
            }
        }}
        renderInput={(params) => <TextField {...params} label="Where was this picture taken?" />}
        options={suggestedLocations}
        getOptionLabel={option => option.displayName}
        isOptionEqualToValue={(option, value) => option.latitude === value.latitude && option.longitude === value.longitude}
    />
}

export default LocationAutocomplete