import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {v4 as uuid} from'uuid'
import {
    canGoToGreetingsStep,
    getAlert,
    getEmptyGreetingsPostcard,
    getGreetingsMissingInformationForStep,
    getGreetingsNextButtonDisabled,
    getGreetingsNextStep,
    getGreetingsPreviousStep,
    getGreetingsPromotion,
    getPrice,
    processGreetingsSubmission,
    sendToGreetingsCheckout,
    STEPS
} from "../../common/postcardAppUtils";
import {Address, GreetingsPostcard} from "../../../../utils/postcard";
import {AppDispatch, RootState} from "../../../../utils/store";
import {
    commonAddErrorAlert,
    commonCloseErrorDialog, commonRemoveAlert, commonSetFontIfNotPresent,
    CommonState,
    commonUpdateFont, commonUpdateImage,
    commonUpdateLayout,
    commonUpdateLocation,
    commonUpdateMessage
} from "../../common/utils/commonSlice";
import {PostcardAppInitialState} from "../../postcard-app/utils/PostcardAppInitialState";
import {IDObject} from "../../../../api/apiUtils";

export interface GreetingsState extends CommonState<GreetingsPostcard>{
    addressesFileName: string
    promotion: number
}

const getInitialState = () => {
    return {
        postcard: getEmptyGreetingsPostcard(uuid()),
        step: STEPS.IMAGE,
        alerts: [],
        isNextDisabled: true,
        errorDialog: { open: false, message: '' },
        isLoadingSubmission: false,
        addressesFileName: '',
        promotion: 1.0
    }
}

const _processNextStepRequest = (state: GreetingsState) => {
    if (getGreetingsNextButtonDisabled(state.step, state.isLoadingSubmission, state.postcard)) {
        // Some information is missing, display error dialog
        state.errorDialog.open = true
        state.errorDialog.message = getGreetingsMissingInformationForStep(state.step, state.postcard)
    } else {
        // Go to next step
        state.step = getGreetingsNextStep(state.step)
    }
}

const submitOrderWithPayment = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('greetings/submitOrderWithPayment', async (data, { getState }) => {
    const { greetings, auth } = getState()

    if (auth.user) {
        const creationResult = await processGreetingsSubmission(greetings.postcard)

        if (creationResult) {
            const response = await sendToGreetingsCheckout({
                id: creationResult.id,
                price: getPrice(PostcardAppInitialState.DEFAULT),
                quantity: greetings.postcard.addresses.length
            }, window, greetings.promotion)

            if (response) {
                return
            } else {
                throw Error('Failed to process submission')
            }
        } else {
            throw Error('Failed to submit postcards')
        }
    }
})

const submitOrderWithBankTransfer = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>()('greetings/submitOrderWithBankTransfer', async (data, { getState }): Promise<IDObject | false> => {
    const { greetings, auth } = getState()

    if (auth.user) {
        const creationResult = await processGreetingsSubmission(greetings.postcard)

        if (creationResult) {
            return creationResult
        } else {
            throw new Error("Failed to submit postcards")
        }
    }

    return false
})

const greetingsSlice = createSlice({
    name: 'greetings',
    initialState: getInitialState(),
    reducers: {
        updateImage: commonUpdateImage<GreetingsPostcard>,
        updateLayout: commonUpdateLayout<GreetingsPostcard>,
        updateMessage: commonUpdateMessage<GreetingsPostcard>,
        updateFont: commonUpdateFont<GreetingsPostcard>,
        setFontIfNotPresent: commonSetFontIfNotPresent<GreetingsPostcard>,
        updateLocation: commonUpdateLocation<GreetingsPostcard>,
        updateAddresses: (state, action: PayloadAction<{ addresses: Address[], fileName: string }>) => {
            state.postcard.addresses = action.payload.addresses
            state.addressesFileName = action.payload.fileName
            // Update promotion
            state.promotion = getGreetingsPromotion(action.payload.addresses.length)
        },
        processGoToStepRequest: (state, action: PayloadAction<STEPS>) => {
            const currentStep = state.step
            const requestedStep = action.payload

            if (requestedStep < currentStep) {
                // Go to step
                state.step = requestedStep
            } else if (requestedStep === getGreetingsNextStep(state.step)) {
                _processNextStepRequest(state)
            } else if (requestedStep > currentStep) {
                if (getGreetingsNextButtonDisabled(state.step, state.isLoadingSubmission, state.postcard)) {
                    state.errorDialog.open = true
                    state.errorDialog.message = getGreetingsMissingInformationForStep(state.step, state.postcard)
                } else {
                    if (canGoToGreetingsStep(
                        requestedStep,
                        state.isLoadingSubmission,
                        state.postcard
                    )) {
                        state.step = requestedStep
                    } else {
                        state.errorDialog.open = true
                        state.errorDialog.message = 'Please fill all the missing information before jumping to this step'
                    }
                }
            }
        },
        processNextStepRequest: _processNextStepRequest,
        processPreviousStepRequest: (state) => {
            state.step = getGreetingsPreviousStep(state.step)
        },
        clearState: (state) => {
            const initialState = getInitialState()
            state.isLoadingSubmission = false
            state.postcard = initialState.postcard
            state.step = initialState.step
            state.isNextDisabled = initialState.isNextDisabled
            state.promotion = initialState.promotion
            state.addressesFileName = initialState.addressesFileName
        },
        closeErrorDialog: commonCloseErrorDialog<GreetingsPostcard>,
        addErrorAlert: commonAddErrorAlert<GreetingsPostcard>,
        removeAlert: commonRemoveAlert<GreetingsPostcard>
    },
    extraReducers: builder => {
        builder
            .addCase(submitOrderWithPayment.pending, (state) => {
                state.isLoadingSubmission = true
            })
            .addCase(submitOrderWithPayment.rejected, (state, action) => {
                state.isLoadingSubmission = false
                // TODO: TEST THIS and get type! - decide if we wanna use alert or dialog
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(submitOrderWithPayment.fulfilled, (state) => {
                // Will redirect to checkout
                state.isLoadingSubmission = false
            })
            .addCase(submitOrderWithBankTransfer.pending, (state) => {
                state.isLoadingSubmission = true
            })
            .addCase(submitOrderWithBankTransfer.rejected, (state, action) => {
                state.isLoadingSubmission = false
                // TODO: TEST THIS and get type! - decide if we wanna use alert or dialog
                // @ts-ignore
                state.alerts.push(getAlert(action.error.message, 'error'))
            })
            .addCase(submitOrderWithBankTransfer.fulfilled, (state, action: PayloadAction<false | IDObject>) => {
                // Will redirect to internal success page
                state.isLoadingSubmission = false
            })
    }
})

export const {
    updateImage,
    updateLayout,
    updateMessage,
    updateFont,
    setFontIfNotPresent,
    updateLocation,
    updateAddresses,
    processGoToStepRequest,
    processNextStepRequest,
    processPreviousStepRequest,
    clearState,
    closeErrorDialog,
    addErrorAlert,
    removeAlert
} = greetingsSlice.actions
export { submitOrderWithPayment, submitOrderWithBankTransfer }
export default greetingsSlice.reducer
export const selectGreetings = (state: RootState) => state.greetings