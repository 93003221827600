import '@aws-amplify/ui-react/styles.css';
import React from "react";
import {Box, Button, Card, CardContent, Typography, useMediaQuery, useTheme} from "@mui/material";
import {getPriceFromSettingAndInitialState} from "../common/postcardAppUtils";
import AuthenticatorWrapper from "../common/AuthenticatorWrapper";
import CartPostcardRecap from "../../postcardrecap/cartpostcardrecap/CartPostcardRecap";
import {RootState, useAppDispatch, useAppSelector} from "../../../utils/store";
import {addPostcardToCart, updateEnvelope} from "./utils/postcardSlice";
import {useNavigate} from "react-router-dom";
import {isFulfilled} from "../../../utils/utils";
import Setting from "./setting/Setting";

type CartStepProps = { }

const InnerCartStep = () => {
    const navigator = useNavigate()
    const stateSelector = (state: RootState) => state.app

    const {
        postcard, initialState
    } = useAppSelector(stateSelector)

    const dispatch = useAppDispatch()

    const price = getPriceFromSettingAndInitialState(postcard.settings, initialState.initialState)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))
    const buttonDefaults = theme.components?.MuiButton?.defaultProps?.sx ? theme.components.MuiButton.defaultProps.sx : {}

    return <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 0.5, mb: 2 }}>
            <Typography sx={{ textAlign: 'center' }} variant={'h6'}>Final recap</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: belowMedium ? '100%' : 800, flexShrink: 0, mb: 2 }}>
                <CartPostcardRecap
                    postcard={postcard}
                    key={postcard.id}
                    price={price}
                />
            </Box>

            <Card sx={{ width: belowMedium ? '100%' : 800, mb: 2 }} className={'cart-postcard-recap'}>
                <CardContent sx={{ p: 3 }}>
                    <Setting
                        title={'Use an envelope'}
                        message={<>Your postcard will be shipped inside an envelope</>}
                        price={'+0.99€'}
                        value={postcard.settings.envelope}
                        setValue={() => dispatch(updateEnvelope())}
                        hideLine={true}
                    />
                    <Button onClick={() => {
                        dispatch(addPostcardToCart())
                            .then(result => {
                                if (isFulfilled(result.type))
                                    navigator('/cart')
                            })
                    }} variant={'contained'} sx={{width: '100%', ...buttonDefaults}}>
                        Add to cart | { Math.floor((price) * 100) / 100 }€
                    </Button>
                </CardContent>
            </Card>
        </Box>
    </Box>
}

const CartStep = (props: CartStepProps) => {
    return <AuthenticatorWrapper>
        <InnerCartStep {...props} />
    </AuthenticatorWrapper>
}

export default CartStep