import {LAYOUT} from "../../utils/layout";
import {Image} from "../../utils/postcard";
import {Box} from "@mui/material";

const canDrawImage = (images: Image[], index: number) => images[index]
const getUrl = (image: Image) => image.path

const getSmallImageComponent = (layout: LAYOUT, images: Image[]) => {
    switch (layout) {
        case LAYOUT.SINGLE_IMAGE:
            return <Box sx={{
                width: '100%',
                aspectRatio: 15/10,
                filter: 'drop-shadow(0px 0px 1.26289px rgba(0, 0, 0, 0.25))',
                backgroundColor: 'white',
                position: 'relative'
            }}>
                {canDrawImage(images, 0) &&  <img style={{
                    width: 'calc(100% - 4.32px)',
                    position: 'absolute',
                    top: '2.16px',
                    left: '2.16px',
                    height: 'calc(100% - 4.32px)'
                }} alt={'Postcard'} src={getUrl(images[0])} draggable={false}/>}
            </Box>
        case LAYOUT.TWO_IMAGES_SPLIT_VERTICALLY:
            return <Box sx={{
                width: '100%',
                aspectRatio: 15/10,
                filter: 'drop-shadow(0px 0px 1.26289px rgba(0, 0, 0, 0.25))',
                backgroundColor: 'white',
                position: 'relative'
            }}>
                {canDrawImage(images, 0) && <img style={{ width: 'calc(50% - 3.24px)', height: 'calc(100% - 4.32px)', position: 'absolute', top: '2.16px', left: '2.16px' }}
                                                 alt={'Postcard'} src={getUrl(images[0])} draggable={false} />}
                {canDrawImage(images, 1) && <img style={{ width: 'calc(50% - 3.24px)', height: 'calc(100% - 4.32px)', position: 'absolute', top: '2.16px', left: 'calc(50% + 4.32px)' }}
                                                 alt={'Postcard'} src={getUrl(images[1])} draggable={false} />}
            </Box>
        case LAYOUT.FOUR_IMAGES_EVEN_GRID:
            return <Box sx={{
                width: '100%',
                aspectRatio: 15/10,
                filter: 'drop-shadow(0px 0px 1.26289px rgba(0, 0, 0, 0.25))',
                backgroundColor: 'white',
                position: 'relative'
            }}>
                <Box sx={{ width: 'calc(100% - 4.32px)', height: 'calc(50% - 3.24px)', position: 'absolute', top: '2.16px', left: '2.16px' }}>
                    {canDrawImage(images, 0) && <img style={{ width: 'calc(50% - 1.08px)', height: '100%', position: 'absolute' }}
                                                     alt={'Postcard'} src={getUrl(images[0])} draggable={false} />}
                    {canDrawImage(images, 1) && <img style={{ width: 'calc(50% - 1.08px)', height: '100%', position: 'absolute', right: 0 }}
                                                     alt={'Postcard'} src={getUrl(images[1])} draggable={false} />}
                </Box>
                <Box sx={{ width: 'calc(100% - 4.32px)', height: 'calc(50% - 3.24px)', position: 'absolute', top: 'calc(50% + 1.08px)', left: '2.16px' }}>
                    {canDrawImage(images, 2) && <img style={{ width: 'calc(50% - 1.08px)', height: '100%', position: 'absolute' }}
                                                     alt={'Postcard'} src={getUrl(images[2])} draggable={false} />}
                    {canDrawImage(images, 3) && <img style={{ width: 'calc(50% - 1.08px)', height: '100%', position: 'absolute', right: 0 }}
                                                     alt={'Postcard'} src={getUrl(images[3])} draggable={false} />}
                </Box>
            </Box>
    }
}

export default getSmallImageComponent