import {Address, Font} from "../../../../utils/postcard";
import PostcardTemplate from "./PostcardTemplate";
import QRCodeImage from "../header/QRCodeImage";
import LocationBox from "../header/location/LocationBox";
import MessageBox from "../body/message/MessageBox";
import AddGreetingsAddressesComponent from "../body/address/AddGreetingsAddressesComponent";
import {Location} from "../../../app/common/postcardAppUtils";
import StampImage from "../header/stamps/StampImage";

type GreetingsRecipientsProps = {
    message: string
    font?: Font
    color?: string

    location: Location

    addresses: Address[]
    setAddresses: ({ addresses, fileName }: { addresses: Address[], fileName: string }) => void
    fileName: string
}

const GreetingsRecipientsPostcardBack = (props: GreetingsRecipientsProps) => {
    const {
        message, font, color,
        location,
        addresses, setAddresses, fileName
    } = props

    return <PostcardTemplate
        location={<LocationBox
            location={location}
        />}
        qrcode={<QRCodeImage isGiftQRCode={false} />}
        message={<MessageBox
            font={font}
            color={color}
            message={message}
        />}
        address={<AddGreetingsAddressesComponent
            addresses={addresses}
            setAddresses={setAddresses}
            fileName={fileName}
        />}
        stamp={<StampImage envelope={false} />}
        showStampBox={true}
    />
}

export default GreetingsRecipientsPostcardBack