import {DBFont, DBMessageColor} from "../api/apiUtils";
import {LAYOUT} from "./layout";
import {PostcardAppInitialState} from "../components/app/postcard-app/utils/PostcardAppInitialState";

export type Frame = {
    id: string,
    name: string,
    image: string
}
export type Image = {
    id: string,
    index: number,
    path: string
}

export enum REPLY_BACK_SETTING {
    NONE = "NONE",
    REPLY_PAID = "REPLY_PAID",
    REPLY = "REPLY",
}

export enum CHAIN_SETTING {
    NONE = "NONE",
    CHAIN_PAID = "CHAIN_PAID",
    CHAIN = "CHAIN"
}

export type Font = DBFont & { file: string }
export type MessageColor = DBMessageColor

export type CommonPostcard = {
    id: string,
    images: Image[],
    message: string,
    location: string,
    latitude: number,
    longitude: number,
    frame?: Frame
    layout: LAYOUT
    font?: Font
    color?: MessageColor,
    owner?: string
}

export type Postcard = CommonPostcard & {
    address: Address,
    recipientId?: string,
    settings: PostcardSettings
}

export type GreetingsPostcard = CommonPostcard & {
    addresses: Address[],
}

type DefaultPostcardSettings = {
    id: string,
    replyBackSetting: REPLY_BACK_SETTING.NONE,
    replyAddress: null
    chainSetting: CHAIN_SETTING.NONE,
    postcardSettingsRepliesToId: null
    postcardSettingsReplyId?: string,
    chain: null
    envelope: boolean
    initialState: PostcardAppInitialState
}

type DefaultPostcardSettingsReplyBack = {
    id: string,
    replyBackSetting: REPLY_BACK_SETTING.REPLY_PAID,
    replyAddress: Address,
    chainSetting: CHAIN_SETTING.NONE,
    postcardSettingsRepliesToId: null
    postcardSettingsReplyId?: string,
    chain: null
    envelope: boolean
    initialState: PostcardAppInitialState
}

type DefaultPostcardSettingsChain = {
    id: string,
    replyBackSetting: REPLY_BACK_SETTING.NONE,
    replyAddress: null
    chainSetting: CHAIN_SETTING.CHAIN_PAID,
    postcardSettingsRepliesToId: null
    postcardSettingsReplyId?: string,
    chain: Chain
    envelope: boolean
    initialState: PostcardAppInitialState
}

type ReplyFreePostcardSettings = {
    id: string,
    replyBackSetting: REPLY_BACK_SETTING.REPLY,
    replyAddress: null
    chainSetting: CHAIN_SETTING.NONE,
    postcardSettingsRepliesToId: string,
    postcardSettingsReplyId?: string,
    chain: null
    envelope: boolean
    initialState: PostcardAppInitialState
}

type ReplyPostcardSettings = ReplyFreePostcardSettings

type ChainFreePostcardSettings = {
    id: string,
    replyBackSetting: REPLY_BACK_SETTING.NONE,
    replyAddress: null
    chainSetting: CHAIN_SETTING.CHAIN_PAID | CHAIN_SETTING.CHAIN,
    postcardSettingsRepliesToId: string,
    postcardSettingsReplyId?: string,
    chain: Chain
    envelope: boolean
    initialState: PostcardAppInitialState
}

type ChainToBePaidPostcardSettings = ChainFreePostcardSettings

export type PostcardSettings = DefaultPostcardSettings |
    DefaultPostcardSettingsReplyBack |
    DefaultPostcardSettingsChain |
    ReplyFreePostcardSettings |
    ReplyPostcardSettings |
    ChainFreePostcardSettings |
    ChainToBePaidPostcardSettings

export type Chain = {
    id: string
    name: string
}

export type Address = {
    id: string
    fullName: string
    streetAddress: string
    city: string
    zipCode: string
}