import React from "react";
import {
    Box,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import GreetingsRecipientsPostcardBack from "../../postcardrecap/postcard/templates/GreetingsRecipientsPostcardBack";
import {RootState, useAppDispatch, useAppSelector} from "../../../utils/store";
import {withLocation} from "../common/postcardAppUtils";
import {updateAddresses} from "./utils/greetingsSlice";

const RecipientStep = () => {
    const selectState = (state: RootState) => state.greetings
    const selectStatePostcard = (state: RootState) => state.greetings.postcard

    const {
        addressesFileName
    } = useAppSelector(selectState)

    const {
        message, font, color,
        location, addresses
    } = withLocation(useAppSelector(selectStatePostcard))

    const dispatch = useAppDispatch()

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))

    return <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: belowMedium ? 'center' : 'auto', width: belowMedium ? '100%' : 'auto' }}>
        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}

        <Box sx={{ width: belowMedium ? '100%' : 600 }} >
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ textAlign: 'center' }} variant={'h6'}>Back</Typography>
            </Box>
            <Box sx={{ width: '100%', px: 1 }}>
                <GreetingsRecipientsPostcardBack
                    message={message}
                    font={font}
                    location={location}
                    addresses={addresses}
                    setAddresses={payload => dispatch(updateAddresses(payload))}
                    fileName={addressesFileName}
                />
            </Box>
        </Box>

        {!belowMedium && <Box sx={{p: 2, pt: 0, width: 250}}/>}
    </Box>
}

export default RecipientStep