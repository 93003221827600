import PageWrapper from "../components/PageWrapper";
import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const GreetingsPaymentCancel = () => {
    const navigate = useNavigate()

    return <PageWrapper>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexDirection: 'column', px: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, textAlign: 'center' }}>
                <Typography variant={'h4'}>There was an error processing your order!</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Button variant={'contained'} onClick={() => navigate('/greetings-app')}>
                    Continue the purchase
                </Button>
            </Box>
        </Box>
    </PageWrapper>
}

export default GreetingsPaymentCancel