import {getGreetingsNextStep, getNextStep, getNextStepData, STEPS} from "../postcardAppUtils";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useContext} from "react";
import IsGreetingsContext from "../imagesselection/isGreetingsContext";
import {RootState, useAppDispatch, useAppSelector} from "../../../../utils/store";
import useActions from "../utils/useActions";

const mapStepToLabel = (step: STEPS, isBelowMd: boolean, isGreetings?: boolean) => {
    const address = isGreetings ? 'Addresses' : 'Address'
    const insertTheAddress = isGreetings ? 'Insert the addresses' : 'Insert the address'

    switch (step) {
        case STEPS.IMAGE:
            return isBelowMd ? 'Image' : 'Upload an image'
        case STEPS.MESSAGE_AND_LOCATION:
            return isBelowMd ? 'Message' : 'Write a message'
        case STEPS.RECIPIENT_AND_SETTINGS:
        case STEPS.PERSONAL_ADDRESS:
            return isBelowMd ? address : insertTheAddress
        case STEPS.CART:
            return isBelowMd ? 'Shipment' : 'Ship a real postcard'
    }
}

const mapStepToImage = (step: STEPS) => {
    switch (step) {
        case STEPS.IMAGE:
            return 'image.svg'
        case STEPS.MESSAGE_AND_LOCATION:
            return 'message.svg'
        case STEPS.RECIPIENT_AND_SETTINGS:
        case STEPS.PERSONAL_ADDRESS:
            return 'home.svg'
        case STEPS.CART:
            return 'ship.svg'
    }
}

const LinkItem = ({ step }: { step: STEPS }) => {
    const { isGreetings } = useContext(IsGreetingsContext)

    const stateSelector = (state: RootState) =>
        isGreetings ? state.greetings : state.app

    const state = useAppSelector(stateSelector)

    const {
        currentStep,
        replyBack,
        initialSettings
    } = getNextStepData(state, isGreetings)

    const dispatch = useAppDispatch()
    const {
        processGoToStepRequest
    } = useActions(isGreetings)

    const nextStep = isGreetings ? getGreetingsNextStep(currentStep) : getNextStep(currentStep, initialSettings, replyBack)

    const theme = useTheme()
    const belowMedium = useMediaQuery(theme.breakpoints.down('md'))
    const belowSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const shouldGetRoundedLeft = step === STEPS.IMAGE
        || step === nextStep
        || (step === STEPS.CART && currentStep == STEPS.RECIPIENT_AND_SETTINGS && replyBack && !isGreetings)

    return <Box
        sx={{
            height: 40,
            px: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer'
            },
            '&:hover #underline': {
                backgroundColor: 'white !important'
            },
            backgroundColor: step > currentStep ? '#ACADB2' : theme.palette.primary.main,
            borderTopLeftRadius: shouldGetRoundedLeft ? 20 : 0,
            borderBottomLeftRadius: shouldGetRoundedLeft ? 20 : 0,
            borderTopRightRadius: step === STEPS.CART ? 20 : 0,
            borderBottomRightRadius: step === STEPS.CART ? 20 : 0,
        }}
        key={step.toString()}
        onClick={() => dispatch(processGoToStepRequest(step))}
    >
        {!belowSmall && <img src={'icons/app/imagestep/' + mapStepToImage(step)} alt={''}/>}
        <Box sx={{ml: 1}}>
            {/* @ts-ignore */}
            <Typography variant={'small'} sx={{
                color: 'white',
                pb: 0.5
            }}>{mapStepToLabel(step, belowMedium, isGreetings)}</Typography>
            <Box id={'underline'}
                 sx={{width: '100%', height: '2px', backgroundColor: currentStep === step ? 'white' : 'transparent'}}/>
        </Box>
    </Box>
}

export default LinkItem